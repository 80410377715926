import React, { Component } from 'react';
import './css/Product.css';
import './css/ui/elements/YoutubeEmbed.css';
import helperCart from './HelperCart';
import Footer from './Footer';
import { Header } from './Header';
import ProducPartItem from './ProducPartItem';
import { Table } from "reactstrap";
import YoutubeEmbed from './YoutubeEmbed';
import helperCommon from './HelperCommon';
import helperSession from './HelperSession';
import ImageGallery from 'react-image-gallery';
import './css/image-gallery.css';
import PropTypes from "prop-types";
import Slider from "react-slick";
import ProductRequests from './requests/ProductRequests';


export class Product extends Component {
    static displayName = Product.name;
    static id_product;
    static dataCart;
    static mainOptionSelected;
    static excludingOptionSelected;
    static openPoint = false;

    static contextTypes = {
        router: PropTypes.object
    }

    constructor(props, context) {
        super(props, context);
        let categoryName = "";
        if (props.location.state) {
            categoryName = props.location.state.categoryName
        } else {
            categoryName = "All"
        }

        this.state = {
            nameProduct: "",
            specifications: [],
            listOptions: [],
            description: "",
            imgUrl: "",
            components: [],
            host: '',
            specificationsBody: [],
            customersAlsoViewProducts: [],
            logined: false,
            quantity: 1,
            images: [],
            videos: [],
            medias: [],
            testimonials: [],
            itemHotMobileIndex: -1,
            itemHotMobileImgUrl: "",
            itemHotMobileDescription: "",
            brochures: [],
            idManualBrochures: '',
            idOwnersBrochures: '',
            features: [],
            nameManualBrochures: '',
            nameOwnersBrochures: '',
            total: '0.00',
            photo: "",
            loading: false,
            categoryName: categoryName,
            valitationsBefore: true
        };


        let arrayLink = window.location.href.split("/");
        if (arrayLink) {
            this.id_product = arrayLink[arrayLink.length - 2];
        } else {
            console.log('arrayLink failed (constructor)');
        }

        this.onChangeOption = this.onChangeOption.bind(this);
        this.selectCatalogHotPoint = this.selectCatalogHotPoint.bind(this);
        this.onAddToCart = this.onAddToCart.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.viewProduct = this.viewProduct.bind(this);
        this.redirectToBrochure = this.redirectToBrochure.bind(this);


        this.dataCart = { 'idProduct': -1, 'nameProduct': "", 'listOptions': [], 'quantity': 0, 'price': 0, 'total': 0 };
        this.dataCart.idProduct = this.id_product;
        this.openPoint = false;
        this.mainOptionSelected = false;
        this.excludingOptionSelected = false;
    }


    componentDidMount() {
        this.setState({
            logined: helperSession.getLogined()
        });

        if (helperSession.getLogined()) {
            ProductRequests.GetOptionsByProductWhithPrices(this.id_product, this);
        } else {
            ProductRequests.GetOptionsByProduct(this.id_product, this);
        }
        ProductRequests.getHost(this);
        this.displayLabelViewCart(this.id_product);
    }

    viewProduct(id, nameProduct) {
        let nameProductClean = nameProduct.replace(/\s+/g, '-');
        this.props.history.push({
            pathname: '/products/' + nameProductClean + '/' + id + '/'
        });
        window.location.reload();
        window.scrollTo(0, 0)
    }

    redirectToBrochure(id) {
        if (id !== -1) {
            window.open("data/GetBrochure/" + id + '/');
        }
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            this.onAddToCart(this);
        } else {
            console.log("handleSubmit error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }

    onChangeQuantity(value) {
        this.setState({
            quantity: value
        });

        helperCart.changeQuantityInCart(this.id_product, value);
        this.onChangeOption("", value);
    }

    onChangeOption(event, qty, idOption) {
        var quantity = 0;
        if (qty > 0) {
            quantity = qty;
        } else {
            quantity = this.state.quantity;
        }

        if (event !== "") {
            let stringIndexs = event.target.value;
            let arrayIndexs = stringIndexs.split(",");
            let idOptionValue = arrayIndexs[2];
            this.displayDependensOption(idOptionValue, idOption)
        }


        let selectElement = document.querySelectorAll(".selected-option");
        var i;
        var price = 0;
        var total = 0;
        this.setState({
            total: total.toFixed(2)
        });
        for (i = 0; i < selectElement.length; i++) {
            let element = selectElement[i];
            let stringIndexs = element.value;
            let arrayIndexs = stringIndexs.split(",");
            let display = this.isDisplay(element);

            if (arrayIndexs.length > 0 && arrayIndexs[0] && display) {
                let indexType = arrayIndexs[0];
                let indexTypeGroup = arrayIndexs[1];

                let itemOptionType = this.state.listOptions[indexType];
                let itemOptionValue = itemOptionType.options[indexTypeGroup];


                if (itemOptionValue.priceOptions && itemOptionValue.priceOptions.length > 0) {
                    price = price + itemOptionValue.priceOptions[0].value;
                    total = price * quantity;
                }
                this.setState({
                    total: total.toFixed(2),
                    price: price.toFixed(2),
                    logined: helperSession.getLogined()
                });

            }
        }

        this.checValitationsBeforeActionOnClickButton();
    }

    checValitationsBeforeActionOnClickButton() {
        let checked = document.querySelectorAll('.selected-option :checked');
        let arrSelected = [...checked].map(option => option.value);

        let selected = arrSelected.filter(e => e !== '');
        this.setState({
            valitationsBefore: selected.length > 0 ? false : true
        });
    }

    isDisplay(el) {
        var style = window.getComputedStyle(el);
        return (style.display === 'block')
    }

    hideExtraElementsOptionList(idOptionValue) {
        const listOptions = this.state.listOptions;

        const dependent = "Dependent";
        const independent = "Independent";
        const addon = "Add-on";
        const excluding = "Excluding";
        const alwaysShow = "Always-Show";

        for (var i = 0; i < listOptions.length; i++) {
            let elementListOption = listOptions[i];

            let listSelectedOpt = elementListOption.options;

            for (var y = 0; y < listSelectedOpt.length; y++) {
                let elementOption = listSelectedOpt[y];

                if (elementListOption.isTheMainOne) {
                    elementOption.active = true;

                } else {
                    if (elementListOption.displaySetting.name === addon) {
                        if (this.mainOptionSelected) {
                            elementOption.active = true;
                        } else {
                            elementOption.active = false;
                        }
                    } else if (elementListOption.displaySetting.name === alwaysShow) {
                        elementOption.active = true;
                    } else if (elementListOption.displaySetting.name === independent) {
                        if (elementListOption.isTheMainOne === false) {
                            elementOption.active = true;
                        }
                    } else if (elementListOption.displaySetting.name === dependent) {
                        if (idOptionValue !== elementOption.optionTypeId) {
                            elementOption.active = false;
                        } else {
                            elementOption.active = true;
                        }
                    } else if (elementListOption.displaySetting.name === excluding) {
                        if (this.mainOptionSelected === false) {
                            elementOption.active = true;
                        } else {
                            elementOption.active = false;
                        }
                        elementOption.active = true;
                    } else {
                        elementOption.active = true;
                    }
                }

            }

        }

        this.setState({
            listOptions: listOptions
        });

    }

    displayDependensOption(idOptionValue, idOption) {
        const excluding = "Excluding";

        const listOptions = this.state.listOptions;

        const isTheMainOneSelected = listOptions.filter(e => e.isTheMainOne && e.id === idOption);
        const excludingSelected = listOptions.filter(e => e.displaySetting.name === excluding && e.id === idOption);

        const isTheMainOneExist = listOptions.filter(e => e.isTheMainOne);
        const excludingExist = listOptions.filter(e => e.displaySetting.name === excluding);

        if (isTheMainOneSelected.length > 0 && idOptionValue) {
            this.mainOptionSelected = true;
            if (excludingExist.length > 0) {

                for (var i = 0; i < excludingExist.length; i++) {
                    document.getElementById('selectedOption_' + excludingExist[i].id).options[0].selected = 'selected';
                }

            }
        } else {
            this.mainOptionSelected = false;
        }

        if (excludingSelected.length > 0 && idOptionValue) {
            this.excludingOptionSelected = true;
            if (isTheMainOneExist.length > 0) {
                document.getElementById('selectedOption_' + isTheMainOneExist[0].id).options[0].selected = 'selected';
            }

            for (var y = 0; y < excludingExist.length; y++) {
                let element = excludingExist[y];
                if (element.id !== idOption) {
                    document.getElementById('selectedOption_' + element.id).options[0].selected = 'selected';
                }

            }
        } else {
            this.excludingOptionSelected = false;
        }

        if (isTheMainOneSelected.length > 0 || excludingSelected.length > 0) {
            this.hideExtraElementsOptionList(parseInt(idOptionValue));
        }
    }

    onAddToCart() {
        let selectElement = document.querySelectorAll(".selected-option");
        var listOptions = [];
        var ListRowOptions = [];

        if (!this.state.singleOption) {
            var i;
            for (i = 0; i < selectElement.length; i++) {
                let element = selectElement[i]
                let stringIndexs = element.value;
                let arrayIndexs = stringIndexs.split(",");

                let display = this.isDisplay(element);

                if (arrayIndexs.length > 0 && arrayIndexs[0] && display) {
                    let indexType = arrayIndexs[0];
                    let indexTypeGroup = arrayIndexs[1];

                    let itemOptionType = this.state.listOptions[indexType];
                    let itemOptionValue = itemOptionType.options[indexTypeGroup];

                    if (itemOptionValue.optionType) {
                        ListRowOptions = { typeId: itemOptionType.id, typeName: itemOptionType.name, valueId: itemOptionValue.id, valueName: itemOptionValue.optionType.name };
                        listOptions.push(ListRowOptions);
                        this.dataCart.price = this.state.price;
                        this.dataCart.total = this.state.total;
                    }
                }
            }
        } else {

            let itemOptionType = this.state.singleOption[0];
            let itemOptionValue = this.state.singleOption[0].options[0];
            ListRowOptions = { typeId: itemOptionType.id, typeName: itemOptionType.name, valueId: itemOptionValue.id, valueName: itemOptionValue.optionType.name };

            listOptions.push(ListRowOptions);
            this.dataCart.price = this.state.price;
            this.dataCart.total = this.state.total;
        }


        if (document.getElementById('quantity')) {
            this.dataCart.quantity = document.getElementById('quantity').value;
        }

        if (this.dataCart.idProduct !== -1 && this.dataCart.quantity > 0 && listOptions.length > 0) {
            this.dataCart.listOptions = listOptions;
            if (helperCart.addProductToCart(this.dataCart)) {
                this.displayLabelViewCart(this.id_product);
            } else {
                console.log("onAddToCart error:");
                helperCommon.displaySectionMessage('', true);
            }
        } else {
            console.log("onAddToCart error:");
            helperCommon.displaySectionMessage('', true);
        }
    }


    displayLabelViewCart(id) {
        if (helperCart.findProductCart(id)) {
            let element = document.querySelector(".form-submit-note");
            if (element) {
                element.style.display = "block";
            }
        }
    }

    selectCatalogHotPoint(id) {
        this.openPoint = !this.openPoint;

        let selectorsAll = document.querySelectorAll(".product-part-item");
        var i;
        for (i = 0; i < selectorsAll.length; i++) {

            var element = selectorsAll[i];
            var idElement = parseInt(element.id);

            if (idElement === id && this.openPoint) {
                element.style.display = "block";
            } else {
                element.style.display = "none";
            }
        }
    }

    render() {

        const nameProduct = this.state.nameProduct;
        const listOptions = this.state.listOptions;
        const description = this.state.description;
        const imgUrl = this.state.imgUrl;
        const components = this.state.components;
        const specificationsBody = this.state.specificationsBody;
        const customersAlsoViewProducts = this.state.customersAlsoViewProducts;
        const images = this.state.images;
        const medias = this.state.medias;
        const videos = this.state.videos;
        const shippingLeadTime = this.state.shippingLeadTime;

        const style = this.state.loading ? {} : { visibility: 'hidden' }
        const { loading } = this.state;

        var linkCategoryName = this.state.categoryName.toLowerCase();

        const settings = {
            dots: true,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1
        };

        return (

            <div id="container">

                <Header></Header>

                <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

                <main>

                    <section className="product-main bg-white">
                        <div className="page-title">
                            <div className="page-title-bar bg-charcoal">
                                <img alt="jenkins iron and steel repeating J icon" width="500" height="226" src={require('./img/group_img/jenkins-j-icon.webp')} />
                            </div>
                            <div className="wrapper">
                                <ul className="breadcrumb">
                                    <li><a className="breadcrumb-products-gtm" href="/categories/all/" >Products</a></li>
                                    <li><a className="breadcrumb-category-gtm" href={"/categories/" + linkCategoryName + "/"} >{this.state.categoryName}</a></li>
                                    <li>{nameProduct}</li>
                                </ul>
                                <h1>{nameProduct}</h1>
                            </div>
                        </div>

                        <div className="wrapper">
                            <div className="product-columns small-padding-top medium-padding-bottom">

                                <div className="product-column product-image-form">
                                    <div className="product-featured-image">
                                        <div className='image'>

                                            <div>
                                                {!loading && <div className="spinner-border" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>}
                                                <img src={imgUrl} alt={nameProduct}
                                                    onLoad={() => this.setState({ loading: true })}
                                                    style={style} />
                                            </div>

                                            {components.map((item, index) => {
                                                return <div key={index} id={index} className="hotspot-container" onClick={e => this.selectCatalogHotPoint(index)} >
                                                    <div className="hotspot" style={{
                                                        left: 'calc(' + item.xCoordinate + ' - 16px)',
                                                        top: 'calc(' + item.yCoordinate + ' - 16px)',
                                                    }}>
                                                        <span></span>
                                                        <span></span>
                                                    </div>
                                                    <div id={index} className='hotspot-popup product-part-item'>
                                                        <ProducPartItem urlImg={item.imgUrl} description={item.description} title={item.name} ></ProducPartItem >
                                                    </div>
                                                </div>
                                            })}
                                        </div>
                                    </div>

                                    <div className='product-cart-form'>

                                        {this.state.logined === true ?
                                            <h3 className="price"> {"$" + this.state.total} </h3>
                                            : ""}

                                        {!this.state.singleOption &&
                                            <div className='product-form-options'>
                                                {listOptions.map((itemlistOptions, indexlistOptions) => {

                                                    return <div key={indexlistOptions} className='form-field select-field has-label'><label>{itemlistOptions.name}</label>
                                                        <select id={"selectedOption_" + itemlistOptions.id} className="selected-option" onChange={e => this.onChangeOption(e, 0, itemlistOptions.id)}>

                                                            {itemlistOptions.displaySetting.name !== "Always-Selected" ? <option value="" >{!itemlistOptions.selectionLabel ? "Choose here": itemlistOptions.selectionLabel}</option> : null}

                                                            {itemlistOptions.options.map((item, index) => {
                                                                if (item.active) {
                                                                    return <option key={index} value={indexlistOptions + "," + index + "," + item.optionTypeId} >{item.optionType.name}</option>;
                                                                }
                                                            })}
                                                        </select>
                                                    </div>
                                                })}
                                            </div>
                                        }

                                        <form id="onSubmit" onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                            <div className="form-field-container form-half-fields">
                                                <div className="form-field xs-field">
                                                    <label>Quantity</label>
                                                    <input required className="quantity" tabIndex="0" onKeyDown={helperCommon.validateNumber} value={this.state.quantity} min="1" id="quantity" placeholder="Quantity" type="number" name="Quantity" onChange={e => this.onChangeQuantity(e.target.value)} />
                                                </div>
                                                <div className="form-field form-submit form-submit-right xl-field">
                                                    <input className={this.state.logined ? this.state.valitationsBefore === true ? "" : 'add-to-cart-gtm' : this.state.valitationsBefore === true ? "" : 'add-to-instant-quote-gtm'} type="submit" value={this.state.logined ? 'ADD TO CART' : 'ADD TO INSTANT QUOTE'} />
                                                </div>
                                                <p>Estimated Lead Time {shippingLeadTime} Weeks</p>
                                                <div className="form-submit-note">
                                                    <p>Your item has been added to the cart! &nbsp;
                                                    <a onClick={this.onChangeOption} className="view-cart-gtm" href="cart/request-quote/" >View Cart.</a></p>
                                                </div>
                                                <div id="info-message" className="info-message-product" ></div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className="product-column product-description long-text" dangerouslySetInnerHTML={{ __html: description }}></div>
                            </div>
                        </div>
                    </section>


                    {this.state.features.length > 0 || this.state.specificationsBody.length > 0 ?
                        <section id="product-features-specs" className="medium-padding bg-light">
                            <div className="wrapper">
                                {this.state.features.length > 0 &&
                                    <div className="product-details product-features long-text">
                                        <h2>Features</h2>
                                        <ul>
                                            {this.state.features.map((item, index) => {
                                                return <li key={index}> <div dangerouslySetInnerHTML={{ __html: item.description }} /> </li>
                                            })}
                                        </ul>
                                    </div>
                                }


                                {this.state.specificationsBody.length > 0 &&
                                    <div className="product-details product-specs">
                                        <h2>Specifications</h2>

                                        <Table hover className="spec-table">
                                            <tbody>
                                                {specificationsBody.map((item_row, index) => (
                                                    <tr key={index} className="spec-table-row  ">
                                                        {item_row.map((item_cell, index) => (
                                                            index > 0 ?
                                                                item_row[0] ?
                                                                    <td key={index} className="spec-table-col spec-table-header">{item_cell}</td>
                                                                    : <td key={index} className="spec-table-col spec-table-body">{item_cell}</td>
                                                                : null
                                                        ))}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                }

                                {this.state.idOwnersBrochures || this.state.idManualBrochures ?
                                    <div className="product-details product-downloads">
                                        <div className="buttons-wrapper even-buttons center-buttons xs-padding-top">
                                            {this.state.idOwnersBrochures ?
                                                <div className="product-owners-brochure-gtm" onClick={() => { this.redirectToBrochure(this.state.idOwnersBrochures) }} className="button" >{this.state.nameOwnersBrochures}</div>
                                                : null}
                                            {this.state.idManualBrochures ?
                                                <div className="product-manual-brochure-gtm" onClick={() => { this.redirectToBrochure(this.state.idManualBrochures) }} className="button" >{this.state.nameManualBrochures}</div>
                                                : null}
                                        </div>
                                    </div>
                                    : null}
                            </div>
                        </section>
                        : null}


                    {this.state.testimonials.length > 0 &&
                        <section id="product-testimonials" className="medium-padding bg-charcoal center-align">
                            <div className="wrapper">
                                <h2>What our customers say</h2>
                                <Slider {...settings}>

                                    {this.state.testimonials &&
                                        this.state.testimonials.map((item, index) => (
                                            <div key={index}>
                                                <div className='testimony-container product-testimony-gtm'>
                                                    <div className="testimony" dangerouslySetInnerHTML={{ __html: item.description }} />
                                                    <div className='testimony-by highlight-container'>
                                                        <p className="testimony-author highlight highlight-white">
                                                            {item.name}<br />
                                                            <span className="testimony-author-location">{item.location}</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))
                                    }

                                </Slider>
                            </div>
                        </section>
                    }

                    {medias.length > 0 &&
                        <section id="product-media" className="medium-padding bg-light">
                            <div className="wrapper">
                                <h2 className="center-align">Media</h2>
                                {images.length > 0 &&
                                    <div className='product-column gallery-column product-image-gtm'>
                                        <ImageGallery items={images} />
                                    </div>
                                }

                                {videos.length > 0 &&
                                    <div className='product-column video-column row'>
                                        {videos.map((item, index) => {
                                            return <div key={index} className="video col-xs-6 col-sm-6 col-lg-6 product-video-gtm">
                                                <YoutubeEmbed url={item.url} />
                                            </div>
                                        })}
                                    </div>
                                }
                            </div>
                        </section>
                    }

                    {this.viewProduct.length > 0 &&
                        <section id="similar-products" className="medium-padding bg-white center-align">
                            <div className="wrapper">
                                <h2>Customer also viewed</h2>

                                <div className='product-listings similar-listings'>
                                    {customersAlsoViewProducts.map((item, index) => (
                                        <div key={index} onClick={e => this.viewProduct(item['id'], item.name)} className="product-preview">
                                            <img alt={item.name} width="800" height="800" className="rectangle-298-R9uL4j" src={item.imgUrl} />
                                            <h4 className="product-preview-title product-preview-gtm">{item.name}</h4>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </section>
                    }


                </main>
                <Footer></Footer>
            </div>
        );
    }

}
