import React, { Component } from 'react';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import UserAuthRepositories from './repositories/UserAuthRepositories';
import RegisterRequests from './requests/RegisterRequests';


export class Register extends Component {
    static displayName = Register.name;
    static listgetCountriesAndStates = [];
    static listStatesBilling = [];
    static listStatesShipping = [];

    static idCountryShipping;
    static idCountryBilling;
    static idStateShipping;
    static idStateBilling;

    constructor(props) {
        super(props);
        document.title = "Register";

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeCoutryShipping = this.onChangeCoutryShipping.bind(this);
        this.onChangeProvinceShipping = this.onChangeProvinceShipping.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);
        this.copyBillingAddress = this.copyBillingAddress.bind(this);

        this.state = {
            listgetCountriesAndStates: [], listStatesBilling: [], listStatesShipping: []
        }
    }

    componentDidMount() {
        RegisterRequests.getCountriesAndStates(this);
        helperCommon.hideSectionErrorMessage();
        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();
    }


    sendMessage(message) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message
            }
        });
    }

    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.listStatesBilling = currentCountry.stateViews;
            this.setState({
                listStatesBilling: currentCountry.stateViews
            });
        }
    }

    onChangeCoutryShipping(event) {
        var currentCountryIdPossion = document.getElementById("shipping_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryShipping = currentCountry.id;
            this.listStatesShipping = currentCountry.stateViews;
            this.setState({
                listStatesShipping: currentCountry.stateViews
            });
        }
    }


    onChangeProvinceShipping() {
        var currentStateIdPossion = document.getElementById("shipping_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesShipping[currentStateIdPossion];
            this.idStateShipping = currentState.id;
        }
    }

    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesBilling[currentStateIdPossion];
            this.idStateBilling = currentState.id;
        }
    }

    onClickOpenLogin(event) {
        window.location.replace("/login");
    }

  

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        var password = document.getElementById("password");
        var confirmPassword = document.getElementById("confirm_password");
        if (password.value !== confirmPassword.value) {
            validation = false;
            confirmPassword.value = "";
        }
        e.preventDefault();
        return validation;
    }

    handleSubmit(e) {
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            let json = UserAuthRepositories.createAccountBody(allInputs, this);
            RegisterRequests.reguestCreateAccount(json, this);
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('Please enter all the correct fields', true);
        }
        e.preventDefault();
    }

    copyBillingAddress(e) {

        if (this.listStatesBilling) {
            var billingAdress = document.querySelectorAll(".billing-adress");
            var shippingAdress = document.querySelectorAll(".shipping-adress");

            this.setState({
                listStatesShipping: this.listStatesBilling
            }, () => {
                try {
                    for (var i = 0; i < billingAdress.length; i++) {
                        var currentInput = billingAdress[i];
                        shippingAdress[i].value = currentInput.value;
                    }
                } catch (e) {
                    console.log("copyBillingAddress " + e.message + 'currentInput.id ' + currentInput.id);
                }
            });

            this.idCountryShipping = this.idCountryBilling;
            this.idStateShipping = this.idStateBilling;
            this.listStatesShipping = this.listStatesBilling;
        }
    }

    onValidateEmail(e) {
        if (e.target.value && e.target.id) {
            RegisterRequests.checkValidateEmail(e.target.value, e.target.id);
        }
    }

    render() {
        const listgetCountriesAndStates = this.state.listgetCountriesAndStates;
        const listStatesBilling = this.state.listStatesBilling;
        const listStatesShipping = this.state.listStatesShipping;

        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="create-account-form medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Create Account</h1>

                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                <h4>1. Account Info</h4>

                                <div className="form-field">
                                    <input id="company_name" required placeholder="Company Name*" type="text" ref={(company_name) => this.company_name = company_name} />
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field">
                                    <input id="contact_name" required placeholder="Contact Name*" type="text" ref={(contact_name) => this.contact_name = contact_name} />
                                    <div className="invalid-feedback">
                                        this field is required
                                     </div>
                                </div>

                                <div className="form-field">
                                    <input id="phone" required placeholder="Phone" type="text" ref={(phone) => this.phone = phone} />
                                </div>



                                <div className='form-section-break'></div>



                                <h4>2. Billing Address</h4>

                                <div className="form-field">
                                    <input id="billing_adress_street_address" required className="billing-adress" placeholder="Street Address*" type="text" ref={(billing_adress_street_address) => this.billing_adress_street_address = billing_adress_street_address} />
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field-container form-half-fields">
                                    <div className="form-field">
                                        <input id="billing_adress_city" required className="billing-adress" placeholder="City*" type="text" ref={(billing_adress_city) => this.billing_adress_city = billing_adress_city} />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>

                                    <div className="form-field select-field">
                                        <select id="billing_adress_country" required className="billing-adress" onChange={this.onChangeCoutryBilling} >
                                            <option value=""  >Country*</option>
                                            {listgetCountriesAndStates.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container form-half-fields">
                                    <div className="form-field select-field">
                                        <select id="billing_adress_province" required className="billing-adress" onChange={this.onChangeProvinceBilling}>
                                            <option value=""  >Province/State*</option>
                                            {listStatesBilling.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>

                                    <div className="form-field">
                                        <input id="billing_adress_postal_code" required className="billing-adress" placeholder="ZIP/Postal Code*" type="text" ref={(billing_adress_postal_code) => this.billing_adress_postal_code = billing_adress_postal_code} />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                </div>

                                <div className="buttons-wrapper">
                                    <div onClick={this.copyBillingAddress}>
                                        <div className="button outline-button" >Use as shipping address</div>
                                    </div>
                                </div>



                                <div className='form-section-break'></div>



                                <h4>3. Shipping Address</h4>

                                <div className="form-field">
                                    <input id="shipping_adress_street_address" required className="shipping-adress" placeholder="Street Address*" type="text" ref={(shipping_adress_street_address) => this.shipping_adress_street_address = shipping_adress_street_address} />
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field-container form-half-fields">
                                    <div className="form-field">
                                        <input id="shipping_adress_city" required className="shipping-adress" placeholder="City*" type="text" ref={(shipping_adress_city) => this.shipping_adress_city = shipping_adress_city} />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                    <div className="form-field select-field">
                                        <select id="shipping_adress_country" required className="shipping-adress" onChange={this.onChangeCoutryShipping}>
                                            <option value="">Country*</option>
                                            {listgetCountriesAndStates.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                </div>

                                <div className="form-field-container form-half-fields">
                                    <div className="form-field select-field">
                                        <select id="shipping_adress_province" required className="shipping-adress" onChange={this.onChangeProvinceShipping}>
                                            <option value="">Province/State*</option>

                                            {listStatesShipping.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}

                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>

                                    <div className="form-field">
                                        <input id="shipping_adress_postal_code" required className="shipping-adress" placeholder="ZIP/Postal Code*" type="text" ref={(shipping_adress_postal_code) => this.shipping_adress_postal_code = shipping_adress_postal_code} />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                </div>



                                <div className='form-section-break'></div>



                                <h4>4. Account Login</h4>

                                <div className="form-field">
                                    <input id="email" required autoComplete="off" placeholder="Email*" type="email" onChange={this.onValidateEmail} ref={(email) => this.email = email} />
                                    <div id='feedback_email_contact' className="invalid-feedback">
                                        this field is required
                                   </div>
                                </div>

                                <div className="form-field password-field">
                                    <input id="password" required placeholder="Password*" type="password" ref={(password) => this.password = password} autoComplete = 'off'/>
                                    <i className="bi bi-eye-slash" id="togglePassword"></i>
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field password-field">
                                    <input id="confirm_password" required placeholder="Confirm Password*" type="password" ref={(confirm_password) => this.confirm_password = confirm_password} autoComplete='off'/>
                                    <i className="bi bi-eye-slash" id="toggleRePassword"></i>
                                    <div className="invalid-feedback">
                                        passwords do not match
                                    </div>
                                </div>




                                <div className="form-field form-agreement">
                                    <input type="checkbox" id="privacyPolicy" required />
                                    <div className="form-agreement-text">
                                        <label className="form-check-label" htmlFor="privacyPolicy">
                                            I have read and understand the Jenkins Iron & Steel <a href="/privacy-policy" >  Privacy Policy </a>and agree all terms stated within.
                                        </label>
                                        <div className="invalid-feedback">
                                            You must agree before submitting.
                                         </div>
                                    </div>
                                </div>


                                <div id="info-message"></div>


                                <div className='form-field form-submit btn-quote'>
                                    <input type="submit" value="Create Account" />
                                    <div className="form-alt-options">
                                        <span id="sent-email">Already have an account?</span> &nbsp; 
                                        <span className="link" onClick={this.onClickOpenLogin}  >Login</span>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }
}