

const HelperCookie = {

    getCookie: function (name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    },

    setCookie: function (name, value, hour) {
        var expires = "";
        if (hour) {
            var date = new Date();
            date.setTime(date.getTime() + (hour * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    ,
    setCookieDate: function (name, value, dateExpires) {
        var expires = "";
        if (dateExpires) {
            var date = new Date(dateExpires);
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }
    ,
}

export default HelperCookie;