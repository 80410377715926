import CommonNetwork from '../network/CommonNetwork';
import UserContactInfoNetwork from '../network/UserContactInfoNetwork';

const HomeRequests = {
      async sendMessageEmail(dataJson, context,) {
        try {
            let response = await CommonNetwork.sendMessageEmail(dataJson, context);
            if (response) {
                context.sendMessage('Thank you for reaching out to us. One of our sales representatives will be reaching out to you shortly. For an immediate response call us at ', '1.855.732.3843');
            } else {
                console.log("sendMessageEmail error");
            }

        } catch (e) {
            console.log("sendMessageEmail " + e.message);
        }

    },
    async getCountriesAndStates(context) {
        UserContactInfoNetwork.getCountriesAndStates(context);
    },
}

export default HomeRequests;