
import React from "react";
import ReactModal from 'react-modal';


const myAccountUpdatePassword = ({ context, customStyles }) => (

    <ReactModal
        isOpen={context.state.showModalMyAccountUpdatePassword}

        onRequestClose={context.handleCloseModalMyAccountUpdatePassword}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
    >


        <div className="popup-header-bar">
            <div className="wrapper">
                <h3 className="popup-title">Password Update</h3>

                <div onClick={context.closeModalMyAccountUpdatePassword} className='close-button'>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>


        <div className="popup-content">
            <div className="wrapper">
                <form onSubmit={e => context.handleSubmitUpdatePassword(e)} autoComplete="none" className="needs-validation" noValidate >
                    <div className="form-field-container form-half-fields">
                        <div className="form-field">
                            <label>Email</label>
                            <input id="username" required autoComplete="none" placeholder="User*" onChange={context.onValidateEmail} type="text" ref={(username) => context.username = username} />
                            <div id='feedback_username_contact' className="invalid-feedback" >
                                this field is required
                            </div>
                        </div>

                        <div className="form-field password-field">
                            <label>Old Password</label>
                            <input id="old_password" required placeholder="Old Password*" type="password" ref={(old_password) => context.old_password = old_password} />
                            <i className="bi bi-eye-slash" id="toggleOldPassword" onClick={toggleOldPasswordToText} ></i>
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>
                    </div>

                    <div className="form-field-container form-half-fields">
                        <div className="form-field password-field">
                            <label>New Password</label>
                            <input id="password" required placeholder="Password*" type="password" ref={(password) => context.password = password} />
                            <i className="bi bi-eye-slash" id="toggePassword" onClick={togglePasswordToText} ></i>
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>

                        <div className="form-field password-field">
                            <label>Retype Password</label>
                            <input id="confirm_password" required placeholder="Confirm Password*" type="password" ref={(confirm_password) => context.confirm_password = confirm_password} />
                            <i className="bi bi-eye-slash" id="toggConfirmRePassword" onClick={toggleConfirmPasswordToText} ></i>
                            <div className="invalid-feedback">
                                passwords do not match
                            </div>
                        </div>
                    </div>

                    <div className="form-field form-submit">
                        <input type="submit" value="UPDATE" />
                    </div>
                </form>
            </div>
        </div>

    </ReactModal>
);

function togglePasswordToText() {
    const togglePassword = document.getElementById('toggePassword');
    const password = document.getElementById('password');
    toggleType(togglePassword, password);
}

function toggleOldPasswordToText(e) {
    const togglePassword = document.getElementById('toggleOldPassword');
    const password = document.getElementById('old_password');

    toggleType(togglePassword, password);
}

function toggleConfirmPasswordToText(e){
    const togglePassword = document.getElementById('toggConfirmRePassword');
    const password = document.getElementById('confirm_password');

    toggleType(togglePassword, password);
}

function toggleType(togglePassword, password) {
    if (togglePassword && password) {
        const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
        password.setAttribute('type', type);
        // toggle the eye / eye slash icon
        togglePassword.classList.toggle('bi-eye');
    }
}


export default myAccountUpdatePassword;