import React, { Component } from 'react';
import './css/Page404.css';


export class Page404 extends Component {
    static displayName = Page404.name;

    
    componentDidMount() {

        window.location.replace('/pageNotFound/');
    }

    render() {
        return (
            <div id="container"></div>
        );
    }
}