import React, { Component } from 'react';
import './css/CartRequestQuote.css';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import helperCookie from './HelperCookie';
import LoginRequests from './requests/LoginRequests';
import UserAuthRepositories from './repositories/UserAuthRepositories';


export class Login extends Component {
    static displayName = Login.name;

    constructor(props) {
        super(props);
        this.onLostPassword = this.onLostPassword.bind(this);
        this.onRegister = this.onRegister.bind(this);

        this.handleSubmit = this.handleSubmit.bind(this);
        this.rememberMe = this.rememberMe.bind(this);
    }


    componentDidMount() {
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();
        helperCommon.togglePasswordToText();
    }

    rememberMe() {
        let username = document.getElementById('username').value;
        let password = document.getElementById('password').value;
        let rememberMe = document.getElementById('remember-me');
        if (rememberMe.checked) {
            let encodeLogin = helperCommon.encodedString(username);
            let encodePass = helperCommon.encodedString(password);

            helperCookie.setCookie('login', encodeLogin);
            helperCookie.setCookie('password', encodePass);
        }
    }

    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();
        helperCommon.displayLoadingSection();

        if (this.checkValidation(e)) {
            LoginRequests.login(UserAuthRepositories.createUser(this));
        } else {
            console.log("handleSubmit error:");
            helperCommon.displaySectionMessage('', true);
            helperCommon.hideLoadingSection();
        }
        e.preventDefault();
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    onRegister(event) {
        window.location.replace("/register");
    }
    onLostPassword(event) {
        window.location.replace("/lostPassword");
    }

    render() {

        return (

            <div id="container">
                <Header></Header>

                <main>
                    <section className="login-form medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Login</h1>

                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >

                                <div className="form-field">
                                    <input id="username" required placeholder="Email*" type="email" name="login" ref={(username) => this.username = username} />
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field password-field">
                                    <input id="password" required placeholder="Password*" type="password" name="password" ref={(password) => this.password = password} autoComplete='off'/>
                                    <i className="bi bi-eye-slash" id="togglePassword"></i>
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field form-checkboxes">
                                    <input id="remember-me" type="checkbox" />
                                    <label htmlFor="remember-me">Remember Me</label>
                                </div>


                                <div id="info-message"></div>
                                    

                                <div className='form-field form-submit btn-quote'>
                                    <input type="submit" value="Login" />
                                    <div className="form-alt-options">
                                        <span className="link" onClick={this.onLostPassword}> Forgot Password</span> | 
                                        <span className="link" onClick={this.onRegister}> Register</span>
                                    </div>
                                </div>

                            </form>

                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }

}


