import React, { Component } from 'react';
import './css/AboutUs.css';
import { Header } from './Header';
import Footer from './Footer';

export class AboutUs extends Component {
    static displayName = AboutUs.name;

    constructor(props) {
        super(props);
        this.openProducts = this.openProducts.bind(this);
    }

    openProducts() {
        this.props.history.push({
            pathname: '/categories/all/',
        });
        window.scrollTo(0, 0)
    }

    render() {
        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="hero about-hero"></section>

                    <section className="about-main-content bg-white">
                        <div className="page-title">
                            <div className="page-title-bar bg-charcoal">
                                <img alt="jenkins iron and steel repeating J icon" src={require('./img/group_img/jenkins-j-icon.webp')} />
                            </div>
                            <div className="wrapper">
                                <h1>About Us</h1>
                            </div>
                        </div>

                        <div className="about-primary-content small-padding">
                            <div className="wrapper">
                                <p>What began as a hobby in the family shop has transformed into an extraordinary operation at Jenkins Iron & Steel. With 80+ skilled individuals, 14 robotic welders, 3 laser cutters, and an automated powder-coating booth, we now produce an astounding 125-150 attachments per day. Explore our wide range of 45+ attachment models, designed to cater to diverse project needs.</p>
                                <p>Our family has always believed in hard work and building quality products that stand the test of time, regardless of the abuse they take. We’ve put these qualities into the business model for Jenkins Iron & Steel; everything we do, we do with passion and dedication to putting out the best skid steer attachments on the market.</p>
                            </div>
                        </div>
                    </section>


                    <section className="about-secondary-content small-padding bg-grey">
                        <div className="wrapper">
                            <p>Jenkins Iron & Steel is owned and operated by Marty & Karen Jenkins and their 3 sons, Kurt, Patrick and Eric. Kurt and Patrick went to college to pursue different careers; Patrick went for Engineering, and Kurt obtained a Masters in Clinical Social Work. When it was Eric’s turn, he headed to NDSU, but after 5 days he determined that school wasn’t for him, so he packed up and headed back home and started working with Marty in the shop on the family yard.</p>
                            <p>Being from Minnesota, a number of people were in the market for Snow Bucket attachments for their skid steers. Eric figured he could do that, so he got to work welding hand-cut attachments. When word got out, Eric soon found himself busier than expected and a family friend came in to help him out. </p>
                            <p>Patrick made the decision to head home and help Eric and Marty get the orders out. Using his engineering background, he was able to design new models of attachments including Grapples. Once again the designs were a hit and the orders started rolling in. Kurt ended up leaving his practice and came back to help run sales so the guys could focus on getting the orders out. </p>
                            <p>Marty has been involved in quality control since the beginning and makes sure every design can stand up to any form of abuse you can throw at them. As a true testament to the quality of our attachments, Marty is still using the original Skeleton Grapple, and let’s just say he has put it through the wringer. To this day, you’ll find him working at least 14 hours a day in the shop making sure products are meeting the Jenkins Family standards.</p>
                        </div>
                    </section>


                    <section className="about-tertiary-content bg-charcoal">
                        <div className="about-tertiary-banner-bg"></div>
                        <div className="about-tertiary-banner-filter"></div>
                        <div className="wrapper small-padding">
                            <div className="about-tertiary-right">
                                <p>What started as a 1 hand-painted bucket a day hobby in the family shop, turned into a 125-150 attachment a day operation that now employs 80+ people, has 14 robotic welders, 3 laser cutters running 24 hours a day and an automated powder-coating booth. </p>
                                <p>We have over 45 different models of skid steer attachments to cover a wide range of project needs. We’re always improving our designs and our processes to put out the best products possible. We’re constantly thinking up new ways to serve our customers and invent new time-saving attachments to get the job done better and faster. </p>
                                <p>Our goal has always been to provide stronger, better attachments and a level of customer service that just can’t be matched. We stand behind absolutely everything we build. When we say we’re a family owned business, it means if you have an issue with anything on your Jenkins attachment, you can call our office and speak to a Jenkins who knows the attachments, puts their name on the attachments and wants to take care of the customers that buy a Jenkins attachment.</p>
                            </div>
                        </div>

                        <div className="wrapper thin-wrapper small-padding-bottom">
                            <div className="highlight-container xs-padding-top center-align">
                                <h3 className="highlight highlight-white">The Jenkins family founded Jenkins Iron &amp; Steel to produce the highest quality, most durable attachments on
                                the market.</h3>
                            </div>
                        </div>
                    </section>


                    <section className="half-columns bg-black">
                        <div className="half-column column-image-bg column-left about-columns-bg">
                        </div>
                        <div className="half-column column-text column-right small-padding side-padding">
                            <p>We stand behind absolutely everything we build and you can rest assured that if you do have an issue with anything on your attachment you can call our office and you will speak to a Jenkins who knows the attachments, puts their name on the attachments and takes care of the customers who buy their attachments. That is our promise to our customers. Our attachments are rigorously tested and abused prior to production so you know you are getting the best and most durable attachments possible! We thank you for taking the time to get to know who we are and we guarantee that when you buy a Jenkins attachment you are not only getting the attachment, but also the level of service that only family company who cares dearly about their reputation can provide!</p>
                        </div>
                    </section>


                    <section className="famous-for-banner small-padding bg-charcoal">
                        <div className="split-bg filter-3">
                            <div className="half-bg left-bg"></div>
                            <div className="half-bg right-bg"></div>
                        </div>
                        <div className="wrapper">
                            <h2 className="lowercase center-align">See what we are famous for</h2>
                            <div className="buttons-wrapper big-buttons center-buttons xs-padding-top">
                                <a className="about-us-view-products-gtm" href='/categories/all' className="button button-text-white">View Products</a>
                            </div>
                        </div>
                    </section>               
                </main>

                <Footer></Footer>
            </div>


        );
    }

}
