import React, { Component } from 'react';
import './css/CartRequestQuote.css';

export class CartIsEmpty extends Component {
    static displayName = CartIsEmpty.name;
    static listProductCart;
    static id_user;

    render() {
        return (
            <section className="empty-cart medium-padding bg-light">
                <div className="wrapper thin-wrapper">
                    <h1 className="center-align">Your Cart is Empty</h1>
                        <div className="buttons-wrapper center-buttons xs-padding-top">
                        <a className="empty-cart-view-products-gtm" href='/categories/all' className="button">View Products</a>
                        </div>
                </div>
            </section>

        );
    }

}


