import helperCookie from './HelperCookie';

const helperSession = {

    getToken: function (nameToken) {
        return helperCookie.getCookie(nameToken);
    },

    setToken: function (nameToken, token, date) {
        helperCookie.setCookieDate(nameToken, token, date);
    }
    ,
    removeToken: function (nameToken) {
        helperCookie.removeToken(nameToken);
    }
    ,
    getRequestAuthorizationOptions: function () {
        return {
            headers: { 'Authorization': 'Bearer ' + this.getToken('token') }
        };
    },

    getLogined: function () {
        return this.getToken("token") ? true : false;
    },

    logOut: function () {
        helperCookie.setCookie("token", '', 0);
        helperCookie.setCookie("login", '', 0);
        helperCookie.setCookie("remember_me", '', 0);
        helperCookie.setCookie("password", '', 0);
    },

    cleanCart: function () {
        helperCookie.setCookie("cart", JSON.stringify(''), 0);
    },

    getSessionTimeHours: function () {
        return 120;
    },
}

export default helperSession;