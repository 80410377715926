import UserContactInfoNetwork from '../network/UserContactInfoNetwork';
import helperSession from '../HelperSession';
import UserAccountNetwork from '../network/UserAccountNetwork';
import UserShoppingNetwork from '../network/UserShoppingNetwork';
import helperCart from '../HelperCart';
import UserAuthNetwork from '../network/UserAuthNetwork';
import UserAuthRepositories from '../repositories/UserAuthRepositories';
import UserShioppingRepositories from '../repositories/UserShioppingRepositories';

const CartRequestQuoteCompleteRequests = {

    async getCountriesAndStates(context) {

        UserContactInfoNetwork.getCountriesAndStates(context);
    },

    async reguestCreateAccount(allInputs, context) {

        try {
            let json = UserAuthRepositories.createAccountBodyComplete(allInputs, context);
            let response = await UserAccountNetwork.createAccount(json, context);
            if (response) {
                CartRequestQuoteCompleteRequests.login(allInputs, context);
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log("reguestCreateAccount " + e.message);
        }
        return false;
    },


    async reguestCreateOrder(dataJson, context) {

        try {
            let response = await UserShoppingNetwork.reguestCreateOrder(dataJson, context);
            if (response) {
                helperSession.cleanCart();
                context.sendMessage("Thank You for your order. One of our representatives will reach out shortly to confirm all details and payment information.");

                return true;
            } else {
                return false;
            }


        } catch (e) {
            console.log("reguestCreateQuote " + e.message);
        }
        return false;
    },

    async getOptionsFromQuote(quoteId, customerId, context) {

        try {
            let response = await UserShoppingNetwork.getOptionsFromQuote(quoteId, customerId, context);
       
            let listItems = response.body.quoteOptions;
            var listProducts = [];
            var tempProductId = "";
            var itemOptions = [];

            for (var i = 0; i < listItems.length; i++) {
                let element = listItems[i];          
                itemOptions.push({ typeId: element.optionTypeId, typeName: element.optionGruopName, valueId: element.optionId, valueName: element.optionTypeName });

                if (element.productId.toString() !== tempProductId.toString()) {
                    var itemProduct = { idProduct: "", nameProduct: "", price: '', quantity: '', total: 0, listOptions: itemOptions };

                    itemProduct.idProduct = element.productId;
                    itemProduct.nameProduct = element.productName;
                    itemProduct.price = '' + element.price;
                    itemProduct.quantity = '' + element.quantity;
                    itemProduct.total = itemProduct.price * itemProduct.quantity;

                    listProducts.push(itemProduct);
                    helperCart.addProductToCart(itemProduct)
                } else {
                    itemOptions = [];
                }
            }
            context.setState({
                listProductCart: helperCart.getCart()
            });
            return listProducts;

        } catch (e) {
            console.log("getOptionsFromQuote " + e.message);
        }
        return false;
    },

    async login(allInputs, context) {

        try {
            let json = UserAuthRepositories.createUser(allInputs);
            let response = await UserAuthNetwork.login(json, context);

            helperSession.setToken('token', response.token, response.expiration);
            this.getAddresses(allInputs, context);
            return true;

        } catch (e) {
            console.log("login " + e.message);
        }
        return false;
    },

    async getAddresses(allInputs, context) {

        try {
            let response = await UserContactInfoNetwork.getAddresses(context);

            let shipping = response.body.find(a => a.shipping);
            let billing = response.body.find(a => a.billing);

            context.setState({
                listClientAddressesBilling: response.body,
                listClientAddressesShipping: response.body,
                currentBillingAddress: billing,
                currentShippingAddress: shipping

            });

            let json = UserShioppingRepositories.createOredrBody(allInputs, context);

            CartRequestQuoteCompleteRequests.reguestCreateOrder(json, context);
            return true;

        } catch (e) {
            console.log("getAddresses " + e.message);
        }
        return false;
    },

}

export default CartRequestQuoteCompleteRequests;