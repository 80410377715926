
import { AddressView } from '../models/AddressView';
import { ByDefaultAdderss } from '../models/ByDefaultAdderss';
import { Account } from '../models/Account';
import { Contact } from '../models/Contact';

const UserContactInfoRepositories = {

    createAddressBody(currentInput, numberTypeAddress, idCountryBilling, idStateBilling) {
        try {

            let addressView = new AddressView(
                0,
                idCountryBilling,
                idStateBilling,
                currentInput.adress_city.value,
                currentInput.adress_street_address.value,
                currentInput.adress_street_address.value,
                null,
                currentInput.adress_postal_code.value,
                "Country",
                "State",
                numberTypeAddress === 0 ? true : false,
                numberTypeAddress === 1 ? true : false
            );

            return JSON.stringify(addressView);
        } catch (e) {
            console.log("createAddressBody " + e.message);
        }
        return '';
    },

    createByDefaultAdderssBody(id) {
        try {
            let model = new ByDefaultAdderss(id);
            return JSON.stringify(model);
        } catch (e) {
            console.log("byDefaultAdderss " + e.message);
        }
        return '';
    },

    createAccountBody(currentInput) {
        try {
            let account = new Account(
                currentInput.company_name.value);

            return JSON.stringify(account);
        } catch (e) {
            console.log("setAccountBody " + e.message);
        }
        return '';
    },

    createContactBody(id, contactTypeId, currentInput) {
        try {
            let account = new Contact(id,
                currentInput.company_name.value,
                contactTypeId,
                currentInput.account_phone.value,);

            return JSON.stringify(account);
        } catch (e) {
            console.log("setAccountBody " + e.message);
        }
        return '';
    }


}

export default UserContactInfoRepositories;