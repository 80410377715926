import React from "react";
import './css/CartCheckOut.css';
import ReactModal from 'react-modal';

const AddAdressItem = ({ context, customStyles, listStates, listgetCountriesAndStates, nameTypeAddress, numberTypeAddress }) => (



    <ReactModal
        isOpen={parseInt(numberTypeAddress) === 0 ? context.state.showModalhandleOpenAddAddressBilling : context.state.showModalhandleOpenAddAddressShipping}
        contentLabel="onRequestClose Example"
        onRequestClose={parseInt(numberTypeAddress) === 0 ? context.handleCloseAddAddressBilling : context.handleCloseAddAddressShipping}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
    >

        <div className="popup-header-bar">
            <div className="wrapper">
                <h3 className="popup-title">{"New " + nameTypeAddress}</h3>

                <div onClick={numberTypeAddress === 0 ? context.handleCloseAddAddressBilling : context.handleCloseAddAddressShipping} className='address-popup-close close-button'>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>


        <div className="popup-content">
            <div className="wrapper">
                <form onSubmit={e => context.handleSubmitAddAddress(e, numberTypeAddress)} autoComplete="none" className="needs-validation" noValidate >

                    <div className="form-field">
                        <input id="adress_street_address" required className="billing-adress" onChange={e => context.handleChange(e)} name="street" value={context.state.street} placeholder="Street Address*" type="text" ref={(adress_street_address) => context.adress_street_address = adress_street_address} />
                        <div className="invalid-feedback">
                            this field is required
                        </div>
                    </div>


                    <div className="form-field-container form-half-fields">
                        <div className="form-field">
                            <input id="adress_city" required className="billing-adress" onChange={e => context.handleChange(e)} name="city" value={context.state.city} placeholder="City*" type="text" ref={(adress_city) => context.adress_city = adress_city} />
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>

                        <div className="form-field select-field">
                            <select id="billing_adress_country" required className="billing-adress" value={context.state.CountryId} onChange={context.onChangeCoutryBilling} >
                                <option value="" >Country*</option>

                                {listgetCountriesAndStates &&
                                    listgetCountriesAndStates.map((item, index) => (
                                        <option key={index} value={index} >{item.name}</option>
                                    ))
                                }

                            </select>
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>
                    </div>



                    <div className="form-field-container form-half-fields">
                        <div className="form-field select-field">
                            <select id="billing_adress_province" required className="billing-adress" value={context.state.stateId} onChange={context.onChangeProvinceBilling}>
                                <option value="">Province/State*</option>

                                {listStates &&
                                    listStates.map((item, index) => (
                                        <option key={index} value={index} >{item.name}</option>
                                    ))
                                }
                            </select>
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>

                        <div className="form-field">
                            <input id="adress_postal_code" required className="billing-adress" onChange={e => context.handleChange(e)} name="postCode" value={context.state.postCode} placeholder="ZIP/Postal Code*" type="text" ref={(adress_postal_code) => context.adress_postal_code = adress_postal_code} />
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>
                    </div>


                    <div className="form-field form-submit">
                        <input type="submit" value="CREATE" />
                    </div>
       
                </form>

            </div>
        </div>
    </ReactModal>

);

export default AddAdressItem;
