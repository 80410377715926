
import { PasswordModel } from '../models/PasswordModel';
import { User } from '../models/User';
import { Address } from '../models/Address';
import { AccountBody } from '../models/AccountBody';
import { RegistrAccount } from '../models/RegistrAccount';
import { PasswordResetBody } from '../models/PasswordResetBody';


const UserAuthRepositories = {

    createPasswordBody(currentInput) {
        try {

            let model = new PasswordModel(
                currentInput.old_password.value,
                currentInput.password.value,
            );

            return JSON.stringify(model);
        } catch (e) {
            console.log("createPasswordBody " + e.message);
        }

        return '';
    },

    passwordResetBody(password, email, code) {
        try {

            let model = new PasswordResetBody(
                email,
                code,
                password
            );

            return JSON.stringify(model);
        } catch (e) {
            console.log("passwordResetBody " + e.message);
        }

        return '';
    },

    createUser(currentInput) {
        try {
            let model =  new User(
                currentInput.username.value,
                currentInput.password.value);
            return JSON.stringify(model);
        } catch (e) {
            console.log("createUser " + e.message);
        }
        return '';
    },

    createAccountBody(currentInput, context) {
        try {

            let account = new RegistrAccount(
                currentInput.company_name.value,
                currentInput.contact_name.value,
                currentInput.email.value,
                currentInput.phone.value);

            let addressBilling = new Address(
                0,
                context.idCountryBilling,
                context.idStateBilling,
                currentInput.billing_adress_city.value,
                currentInput.billing_adress_street_address.value,
                currentInput.billing_adress_street_address.value,
                null,
                currentInput.billing_adress_postal_code.value);

            let addressShipping = new Address(
                0,
                context.idCountryShipping,
                context.idStateShipping,
                currentInput.shipping_adress_city.value,
                currentInput.shipping_adress_street_address.value,
                currentInput.shipping_adress_street_address.value,
                null,
                currentInput.shipping_adress_postal_code.value);


            let accountBody = new AccountBody(
                currentInput.email.value,
                currentInput.email.value,
                currentInput.password.value,
                account,
                addressBilling,
                addressShipping);

            return JSON.stringify(accountBody);
        } catch (e) {
            console.log("createAccountBody " + e.message);
        }
        return '';
    },

    createAccountBodyComplete(currentInput, context) {
        try {

            let account = new RegistrAccount(
                '',
                '',
                currentInput.username.value,
                '');

            let addressBilling = new Address(
                0,
                context.idCountryBilling,
                context.idStateBilling,
                currentInput.billing_adress_city.value,
                currentInput.billing_adress_street_address.value,
                currentInput.billing_adress_street_address.value,
                null,
                currentInput.billing_adress_postal_code.value);

            let addressShipping = new Address(
                0,
                context.idCountryShipping,
                context.idStateShipping,
                currentInput.shipping_adress_city.value,
                currentInput.shipping_adress_street_address.value,
                currentInput.shipping_adress_street_address.value,
                null,
                currentInput.shipping_adress_postal_code.value);


            let accountBody = new AccountBody(
                currentInput.username.value,
                currentInput.username.value,
                currentInput.password.value,
                account,
                addressBilling,
                addressShipping,
                context.quoteId,
                context.customerId,

            );

            return JSON.stringify(accountBody);
        } catch (e) {
            console.log("createAccountBody " + e.message);
        }
        return '';
    }
}

export default UserAuthRepositories;