export class QuoteBody {
    constructor(username, email, password, account, address, description, quoteOptions, billingEmail, po) {
        this.username = username;
        this.email = email;
        this.password = password;
        this.account = account;
        this.address = address;
        this.description = description;
        this.quoteOptions = quoteOptions;
        this.billingEmail = billingEmail;
        this.po = po;
    }
}