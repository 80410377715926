import React, { Component } from 'react';
import './css/CartRequestQuote.css';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import ResetPasswordRequests from './requests/ResetPasswordRequests';
import UserAuthRepositories from './repositories/UserAuthRepositories';


export class ResetPassword extends Component {
    static displayName = ResetPassword.name;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {

            var url = new URL(window.location.href);
            var code = url.searchParams.get("code");
            var email = url.searchParams.get("email");
            let json = UserAuthRepositories.passwordResetBody(this.password.value, email, code);
            ResetPasswordRequests.resetPasswordByLink(json, this) ;

        } else {
            console.log("handleSubmit error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }


    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        var password = document.getElementById("password");
        var confirmPassword = document.getElementById("confirm_password");
        if (password.value !== confirmPassword.value) {
            validation = false;
            confirmPassword.value = "";
        }
        e.preventDefault();

        return validation;
    }

    componentDidMount() {
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();
        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();
    }

    render() {

        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="login-form medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Reset Password</h1>

                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >

                                <div className="form-field password-field">
                                    <input id="password" required placeholder="new Password*" type="password" name="login" ref={(password) => this.password = password} />
                                    <i className="bi bi-eye-slash" id="togglePassword"></i>
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>

                                <div className="form-field password-field">
                                    <input id="confirm_password" required placeholder="Re-Password*" type="password" name="password" ref={(confirm_password) => this.confirm_password = confirm_password} />
                                    <i className="bi bi-eye-slash" id="toggleRePassword"></i>
                                    <div className="invalid-feedback">
                                        passwords do not match
                                     </div>
                                </div>


                                <div id="info-message"></div>


                                <div className='form-field form-submit btn-quote'>
                                    <input type="submit" value="Reset Password" />
                                </div>


                                <div className="center-align">
                                    <div id='show-loading' className="spinner-grow text-red" role="status">
                                        <span className="sr-only"></span>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }

}


