import UserContactInfoNetwork from '../network/UserContactInfoNetwork';
import UserAccountNetwork from '../network/UserAccountNetwork';
import CommonNetwork from '../network/CommonNetwork';

const RegisterRequests = {

    async getCountriesAndStates(context) {
        UserContactInfoNetwork.getCountriesAndStates(context);
    },

    async reguestCreateAccount(dataJson, context) {

        try {
            let response = await UserAccountNetwork.createAccount(dataJson, context);
            if (response) {
                context.sendMessage("Thank you for registering. Please check your email to verify your account.");
                return true;
            } else {
                return false;
            }
     
        } catch (e) {
            console.log("login " + e.message);
        }
        return false;
    },

    async checkValidateEmail(email, idEmail) {
        CommonNetwork.validateEmail(email, idEmail);
    },

}

export default RegisterRequests;