import helperCommon from '../HelperCommon';
import { ItemQuoteOptions } from '../models/ItemQuoteOptions';
import { OrderBody } from '../models/OrderBody';
import { RegistrAccount } from '../models/RegistrAccount';
import { Address } from '../models/Address';
import { QuoteBody } from '../models/QuoteBody';

const UserShioppingRepositories = {

   
        createOredrBody(currentInput, context) {
        try {

            let listProductCart = context.state.listProductCart;
            var APIOrderOptions = [];

            for (let item of listProductCart) {
                let listOptions = item.listOptions ;
                for (let itemOption of listOptions) {
                    let itemQuoteOptions = new ItemQuoteOptions(itemOption.valueId, item.quantity)
                    APIOrderOptions.push(itemQuoteOptions)
                }
            }

            console.log(currentInput.add_comment.value);

            let billingAddressId = context.state.currentBillingAddress.id;
            let shippingAddressId = context.state.currentShippingAddress.id;
            let description = currentInput.add_comment.value;
            let billingEmail = currentInput.order_email_address.value;
            let po = currentInput.order_no.value;

            let quoteBody = new OrderBody(
                APIOrderOptions, billingAddressId, shippingAddressId, description, billingEmail, po);

           return JSON.stringify(quoteBody);


        } catch (e) {
            console.log("createOredrBody " + e.message);
            helperCommon.displaySectionMessage('', true);
            helperCommon.hideLoadingSection();
        }

        return '';
    },

    createQuoteBody(currentInput, context) {
        try {

            let account = new RegistrAccount(
                currentInput.contact_name.value,
                currentInput.contact_name.value,
                currentInput.email.value,
                currentInput.phone.value);

            let address = new Address(
                0,
                context.idCountryBilling,
                context.idStateBilling,
                '',
                '',
                '',
                null,
                '');

           let listProductCart = context.state.listProductCart;
            var quoteOptions = [];

            for (let item of listProductCart) {
                let listOptions = item.listOptions;
                for (let itemOption of listOptions) {
                    let itemQuoteOptions = new ItemQuoteOptions(itemOption.valueId, item.quantity)
                    quoteOptions.push(itemQuoteOptions)
                }

            }

            console.log(currentInput);

            let quoteBody = new QuoteBody(
                currentInput.email.value,
                currentInput.email.value,
                '',
                account,
                address,
                currentInput.description.value,
                quoteOptions,
            );

           return JSON.stringify(quoteBody);

        } catch (e) {
            console.log("setAccountBody " + e.message);
            helperCommon.displaySectionMessage('', true);
            helperCommon.hideLoadingSection();
}
      return '';
    }

}

export default UserShioppingRepositories;