import React, { Component } from 'react';
import './css/Brochure.css';
import BrochureRequests from './requests/BrochureRequests';

export class Brochure extends Component {
    static displayName = Brochure.name;
    static countImages = 0;

    constructor(props, context) {
        super(props, context);

        this.state = {
            products: [],
        };
        this.countImages = 0;
    }

    handleImageLoaded() {
        this.countImages++;
        let products = this.state.products;
        if (products.length  === this.countImages) {
            window.print();
        }
 
    }

    componentDidMount() {
        BrochureRequests.getHost(this);
    }

    render() {
        const products = this.state.products;
        const host = this.state.host;

        return (
            <div id="print-page-container">
                <div id="print-cover">
                    <div className="stack-top">
                        <div className="print-cover-header">
                            <h1></h1>
                            <ul>
                                <li></li>
                                <li></li>
                                <li></li>
                            </ul>
                        </div>

                        <div className="print-cover-body">
                            <img src={require('./img/group_img/brochure-cover-graphic.webp')} alt="brochure cover" />
                        </div>
                    </div>
                </div>

                <div id="print-product-pages">
                    <div className="print-header">
                        <div className="print-header-container">
                            <div className="print-page-header-logo">
                                <img src={require('./img/group_img/brochure-jenkins-logo.webp')} alt="Jenkins Iron and Steel" />
                            </div>
                            <div className="print-page-header-text">
                                <h2>Jenkins Iron and Steel</h2>
                                <p>American made, commercial grade skid steer attachments</p>
                                <img src={require('./img/group_img/brochure-header-tire-tracks.webp')} alt="tire tracks" />
                            </div>
                        </div>
                    </div>

                    <div className="print-products">
                        {products.map((product, index) => (
                            <div className="print-product-container">
                                <div className="product-column-print print-product-image">
                                    <img src={host + "/" + product.imgUrl} alt=""
                                        onLoad={this.handleImageLoaded.bind(this)}
                                    />
                                </div>

                                <div className="product-column-print print-product-content">
                                    <h3>{product.name}</h3>
                                    <ul className="features">
                                        {product.features.map((feature, index) => (
                                            <li><i className="fas fa-angle-double-right"></i> <div dangerouslySetInnerHTML={{ __html: feature.description }} /></li>
                                        ))}
                                    </ul>
                                </div>

                                <div className="product-column-print print-product-options">
                                    <table>
                                        {product.optionGroups.map((optionGroup, index) => (
                                            <>
                                                {optionGroup.options.map((option) => (
                                                    <tr>
                                                        <td>{option.optionType.name}</td>
                                                        <td className="prices-format"><sup>$</sup><strong>{option.priceOptions.length > 0 ? option.priceOptions[0].value : 0.00}</strong></td>
                                                    </tr>
                                                ))}
                                            </>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        ))}
                    </div>

                    <div className="print-footer">
                        <div className="print-separator">DESIGNED BY FARMERS FOR FARMERS</div>
                        <div className="print-footer-info">
                            <div className="print-distribution">
                                DISTRIBUTED BY
                                <img src={require('./img/group_img/brochure-thunderstruck-logo.webp')} alt="Thunderstruck Ag Equipment" />
                            </div>
                            <div>info@thunderstrucksales.com</div>
                            <div>thunderstruckag.com</div>
                            <div>1 855 752-5525</div>
                            <div className="print-footer-graphic">
                                <img src={require('./img/group_img/brochure-footer-tire-tracks.webp')} alt="tire tracks" />
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        );
    }
}