
import './css/Home.css';
import React, { Component } from 'react';
import helperCart from './HelperCart';
import helperSession from './HelperSession';

export class CartTotal extends Component {
    static displayName = CartTotal.name;

    constructor(props) {
        super(props);
        this.state = {
            listProductCart: [], total: 0, listTaxes: []
        };

        this.state.listProductCart = helperCart.getCart();
        this.componentDidMount = this.componentDidMount.bind(this);
    }

    componentDidMount() {
        let logined = helperSession.getLogined();
        if (logined) {
            this.GetTaxesByClientGroup();
            helperCart.calculeteTotal();
        }
    }

    async GetTaxesByClientGroup() {
        const response = await fetch(`data/GetTaxesByClientGroup`, helperSession.getRequestAuthorizationOptions());
        response
            .json()
            .then((response) => {
                this.setState({
                    listTaxes: response
                });
                helperCart.calculeteTotal();
            })
            .catch((error) => {
                console.debug(error);
            });
    }

    render() {
        const listTaxes = this.state.listTaxes;
        return (


            <div className='cart-totals'>

                <div className='cart-totals-row cart-subtotal'>
                    <span className="total-column">Subtotal</span>
                    <span id="subtotal_cart" className="total-column">0.00</span>
                </div>

                {listTaxes.length > 0 &&
                    listTaxes.map((item, index) => (
                        <div className='cart-totals-row cart-tax'>
                            <span className="total-column">{item.taxType.name + "(" + item.value + "%)"}</span>
                            <div className="total-column">
                                <span id="tax_amount">  </span>
                                <span id="hide_tax_value" >{item.value}</span>
                            </div>
                        </div>

                    ))
                }

                <div className='cart-totals-row cart-grand-total'>
                    <span className="total-column">Total</span>
                    <span id="total_cart" className="total-column">0.00</span>
                </div>

            </div>


        );
    }
}