import React, { Component } from 'react';
import './css/CartCheckOut.css';
import './css/CartRequestQuote.css';
import helperCart from './HelperCart';
import CartItem from './CartItem';
import { CartTotal } from './CartTotal';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import helperSession from './HelperSession';
import AddressList from './AddressList';
import AddAdressItem from './AddAdressItem';
import cartCheckOutRequests from './requests/CartCheckOutRequests';
import userContactInfoRepositories from './repositories/UserContactInfoRepositories';
import myAccountRequests from './requests/MyAccountRequests';
import UserShioppingRepositories from './repositories/UserShioppingRepositories';



export class CartCheckOut extends Component {
    static displayName = CartCheckOut.name;

    static listgetCountriesAndStates = [];
    static listStates = [];
    static listStatesShipping = [];

    static idCountryShipping;
    static idCountryBilling;
    static idStateShipping;
    static idStateBilling;

    static description;

    constructor(props) {
        super(props);
        document.title = "Cart Check Out";

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeCoutryShipping = this.onChangeCoutryShipping.bind(this);
        this.onChangeProvinceShipping = this.onChangeProvinceShipping.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);

        this.handleOpenAddAddressBilling = this.handleOpenAddAddressBilling.bind(this);
        this.handleOpenAddAddressShipping = this.handleOpenAddAddressShipping.bind(this);

        this.handleSubmitAddAddress = this.handleSubmitAddAddress.bind(this);
        this.handleCloseAddAddressBilling = this.handleCloseAddAddressBilling.bind(this);
        this.handleCloseAddAddressShipping = this.handleCloseAddAddressShipping.bind(this);
        this.onUseThisAddress = this.onUseThisAddress.bind(this);

        this.state = {
            listProductCart: [],
            logined: true,
            listgetCountriesAndStates: [],
            listStates: [],
            listStatesShipping: [],
            listClientAddressesBilling: [],
            listClientAddressesShipping: [],
            billingStateId: "",
            currentBillingAddress: [],
            currentShippingAddress: [],
        };

        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            showModalSelectAddresses: false,
            showModalSelectAddressesShipping: false,
        };

        this.handleOpenModalSelectAddressesShipping = this.handleOpenModalSelectAddressesShipping.bind(this);
        this.handleCloseModalSelectAddressesShipping = this.handleCloseModalSelectAddressesShipping.bind(this);

        this.handleOpenModalSelectAddressesBilling = this.handleOpenModalSelectAddressesBilling.bind(this);
        this.handleCloseModalSelectAddresses = this.handleCloseModalSelectAddresses.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

    }

    componentDidMount() {
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();
        cartCheckOutRequests.getCountriesAndStates(this);
        cartCheckOutRequests.getAddresses(this);
        this.setState({
            logined: helperSession.getLogined(),
            listProductCart: helperCart.getCart()

        });
    }

    sendMessage(message) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message
            }
        });
    }

    handleOpenModalSelectAddressesShipping() {
        this.setState({ showModalSelectAddressesShipping: true });
    }

    handleCloseModalSelectAddressesShipping() {
        this.setState({ showModalSelectAddressesShipping: false });
    }

    handleOpenModalSelectAddressesBilling() {
        this.setState({ showModalSelectAddresses: true });
    }

    handleCloseModalSelectAddresses() {
        this.setState({ showModalSelectAddresses: false });
    }


    handleOpenAddAddressBilling() {

        this.setState({ showModalhandleOpenAddAddressBilling: true });
    }

    handleCloseAddAddressBilling() {
        this.setState({ showModalhandleOpenAddAddressBilling: false });
    }

    handleOpenAddAddressShipping() {
        this.setState({ showModalhandleOpenAddAddressShipping: true });
    }

    handleCloseAddAddressShipping() {
        this.setState({ showModalhandleOpenAddAddressShipping: false });
    }



    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.listStates = currentCountry.stateViews;
            this.setState({
                listStates: currentCountry.stateViews
            });
        }
    }

    onChangeCoutryShipping(event) {
        var currentCountryIdPossion = document.getElementById("shipping_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryShipping = currentCountry.id;
            this.listStatesShipping = currentCountry.stateViews;
            this.setState({
                listStatesShipping: currentCountry.stateViews
            });
        }
    }


    onChangeProvinceShipping() {
        var currentStateIdPossion = document.getElementById("shipping_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesShipping[currentStateIdPossion];
            this.idStateShipping = currentState.id;
        }
    }

    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStates[currentStateIdPossion];
            this.idStateBilling = currentState.id;
        }
    }

    onUseThisAddress(index, type) {
        if (parseInt(type) === 0) {
            let selectAddress = this.state.listClientAddressesBilling[index];
            this.setState({
                currentBillingAddress: selectAddress,
            });
            this.handleCloseModalSelectAddresses();
        } else if (parseInt(type) === 1) {
            let selectAddress = this.state.listClientAddressesShipping[index];
            this.setState({
                currentShippingAddress: selectAddress,
            });
            this.handleCloseModalSelectAddressesShipping();
        }
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    handleSubmit(e) {
        console.log("handleSubmit"+e.name);
        e.stopPropagation();
        let allInputs = e.target;
        helperCommon.displayLoadingSection();
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            let json = UserShioppingRepositories.createOredrBody(allInputs, this);
            cartCheckOutRequests.reguestCreateOrder(json, this);
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
      
    }

    handleSubmitAddAddress(e, numberTypeAddress) {
        console.log("handleSubmitAddAddress " + e.name);
        e.stopPropagation();
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {

            let json = userContactInfoRepositories.createAddressBody(allInputs, numberTypeAddress, this.idCountryBilling, this.idStateBilling);
            myAccountRequests.createAddress(json, this);
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
    
    }


    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            [evt.target.name]: value
        });
    }

    render() {
        const listProductCart = this.state.listProductCart;
        const listClientAddressesBilling = this.state.listClientAddressesBilling;
        const listClientAddressesShipping = this.state.listClientAddressesShipping;


        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                width: '50%',
                height: '50%',
                transform: 'translate(-50%, -50%)',
            },
        }

        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="cart-checkout medium-padding bg-light">
                        <div className="wrapper thin-wrapper">
                            <h1 className="center-align">Checkout</h1>
                            <div className="body-message small-padding-bottom">
                                <p>Thank you for placing your order with Jenkins Iron & Steel. Below is your order as well as shipping and billing address.</p>
                            </div>


                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                <div className="container-body">

                                    <h3 className="subheading">Addresses</h3>
                                    <div className='address-blocks info-client'>
                                        <div className="address-block">
                                            <h4>Billing Address</h4>
                                            <div className="account-address">
                                                <p>
                                                    {this.state.currentBillingAddress && this.state.currentBillingAddress.street}<br />
                                                    {this.state.currentBillingAddress && this.state.currentBillingAddress.city + ", " + this.state.currentBillingAddress.stateName}<br />
                                                    {this.state.currentBillingAddress && this.state.currentBillingAddress.countryName + ", " + this.state.currentBillingAddress.postCode}
                                                </p>
                                            </div>
                                            <div className="address-button">
                                                <div onClick={this.handleOpenModalSelectAddressesBilling}>Change Address</div>
                                                <AddressList context={this} listClientAddresses={listClientAddressesBilling} customStyles={customStyles} nameTypeAddress='billing addresses' numberTypeAddress={0}></AddressList>
                                                <AddAdressItem nameTypeAddress='billing address' listStates={this.state.listStates} context={this} customStyles={customStyles} listgetCountriesAndStates={this.state.listgetCountriesAndStates} numberTypeAddress={0} ></AddAdressItem>
                                            </div>
                                        </div>

                                        <div className="address-block">
                                            <h4>Shipping Address</h4>
                                            <div className="account-address">
                                                <p>
                                                    {this.state.currentShippingAddress && this.state.currentShippingAddress.street}<br />
                                                    {this.state.currentShippingAddress && this.state.currentShippingAddress.city + ", " + this.state.currentShippingAddress.stateName}<br />
                                                    {this.state.currentShippingAddress && this.state.currentShippingAddress.countryName + ", " + this.state.currentShippingAddress.postCode}
                                                </p>
                                            </div>
                                            <div className="address-button">
                                                <div onClick={this.handleOpenModalSelectAddressesShipping}>Change Address</div>
                                                <AddressList context={this} listClientAddresses={listClientAddressesShipping} customStyles={customStyles} nameTypeAddress='shipping addresses' numberTypeAddress={1}></AddressList>
                                                <AddAdressItem nameTypeAddress='shipping address' listStates={this.state.listStates} context={this} customStyles={customStyles} listgetCountriesAndStates={this.state.listgetCountriesAndStates} numberTypeAddress={1} ></AddAdressItem>
                                            </div>
                                        </div>
                                    </div>


                                    <h3 className="subheading">Order Information</h3>
                                    <div className="form-field-container form-half-fields">
                                        <div className="form-field">
                                            <label>AP/Billing Email Address</label>
                                            <input id='order_email_address' placeholder="AP/Billing Email Address" type="text" ref={(order_email_address) => this.order_email_address = order_email_address} />
                                        </div>
                                        <div className="form-field">
                                            <label>PO No.</label>
                                            <input id='order_no' placeholder="PO No." type="text" ref={(order_no) => this.order_no = order_no} />
                                        </div>
                                    </div>



                                    <h3 className="subheading">Order Summary</h3>
                                    {listProductCart && listProductCart.length > 0 &&
                                        listProductCart.map((item, index) => (
                                            <CartItem key={index} idProduct={item['idProduct']} nameProduct={item['nameProduct']} listOptions={item['listOptions']} quantity={item['quantity']} price={this.state.logined === true ? "$" + item['price'] : ""} total={this.state.logined === true ? "$" + item['total'] : ""}  >

                                            </CartItem>
                                        ))
                                    }


                                    <div className="cart-totals-columns">
                                        <div className="cart-totals-column">
                                            <div className="cart-note-block">
                                                <div className="cart-note-section cart-lead-time">
                                                    <h4>Current lead time on attachments<span>1 Week & 1 Day</span></h4>
                                                </div>

                                                <div className="cart-note-section cart-note">
                                                    <p>Your order will remain in your cart until you ﬁnalize or make changes to it. Once you finalize your order, you will receive confirmation from our sales staff. Full invoices will be available in Your Account shortly after initializing.</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cart-totals-column">
                                            <CartTotal> </CartTotal>
                                        </div>
                                    </div>

                                    <h3 className="subheading">Additional Comments</h3>

                                    <div className="form-field">
                                        <textarea id='add_comment' cols="40" rows="5" placeholder="Add comment..." type="text" name="Add comment" ref={(add_comment) => this.add_comment = add_comment} ></textarea>
                                    </div>

        
                                    <div className="form-disclaimer" htmlFor="privacyPolicy">
                                        By placing this order, I confirm that all information above is accurate, and I am responsible for any incorrect information
                                    </div>
         

                                    <div className='cart-checkout xs-padding-top'>
                                        <div className="form-field form-submit">
                                            <input type="submit" value="PLACE ORDER" />
                                        </div>

                                        <div className="center-align">
                                            <div id='show-loading' className="spinner-grow text-red" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>

                                        <span id="info-message" className="" > </span>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }

}


