
import UserAuthNetwork from '../network/UserAuthNetwork';
import helperCommon from '../HelperCommon';

const ResetPasswordRequests = {

    async resetPasswordByLink(json, context) {

        try {
            let response = await UserAuthNetwork.resetPasswordByLink(json, context);
            if (response.status === 'Success') {
                window.location.replace("/login");
            } else {
                console.log('Request failed (resetPassword)', response.message);
                helperCommon.displaySectionMessage(response.message, true);
            }
  
            return true;

        } catch (e) {
            console.log("login " + e.message);
        }
        return false;
    },

}

export default ResetPasswordRequests;