import React, { Component } from 'react';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import ConfirmUserRequests from './requests/ConfirmUserRequests';

export class ConfirmUser extends Component {
    static displayName = ConfirmUser.name;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.redirectToAccountComfirmed = this.redirectToAccountComfirmed.bind(this);
    }

    componentDidMount() {
        document.title = "Confirm User";

        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();

        var url = new URL(window.location.href);
        var code = url.searchParams.get("code");
        if (!code) {
            this.redirectToAccountComfirmed();
        }
    }

    redirectToAccountComfirmed() {
        this.props.history.push({
            pathname: '/account-comfirmed',
        });
    }

    handleSubmit(e) {
        ConfirmUserRequests.confirmUser(this);
    }

    render() {

        return (

            <div id="container">
                <Header></Header>
                <main>
                    <section className="account-confirmation medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Confirm Account</h1>


                            <div className="login-group">
                                <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                    <div id="info-message"></div>

                                    <div className="center-align">
                                        <div id='show-loading' className="spinner-grow text-red" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>

                                    <div className="form-field form-submit btn-quote">
                                        <input type="submit" value="CONFIRM ACCOUNT" />
                                    </div>
                                </form>
                            </div>


                        </div>
                    </section>
                </main>
                <Footer></Footer>
            </div>
        );
    }

}


