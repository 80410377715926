import React from "react";
import PropTypes from "prop-types";
import './css/CartRequestQuote.css';
import helperCart from './HelperCart';
import helperCommon from './HelperCommon';

const CartItem = ({ idProduct, nameProduct, listOptions, quantity, price, total }) => (
    <div id={"idProduct_" + idProduct} className="cart-item">
        <div onClick={(event) => removeItem(event, idProduct)} className='close-button remove-cart-item'>
            <span></span>
            <span></span>
        </div>

        <div className="cart-item-details">
            <div className='cart-item-column cart-item-info'>
                <h3 className="cart-item-title">{nameProduct}</h3>

                {listOptions.length > 0 &&
                    listOptions.map((item, index) => (

                        <div key={index} className='cart-item-row cart-item-selection'>
                            <span>{item.typeName}</span>
                            <span>{item.valueName}</span>
                        </div>

                    ))
                }
            </div>

            <div className='cart-item-column cart-item-totals'>
                <div className='cart-item-row cart-item-total'>
                    <span>Quantity</span>
                    <span className="form-field">
                        <input required min="1" tabIndex="0" onKeyDown={helperCommon.validateNumber}  id="quantityCart" onChange={(event) => onChangeQuantity(event, idProduct, price)} placeholder="Quantity" type="number" name="Quantity" defaultValue={quantity} />
                    </span>
                </div>
                <div className='cart-item-row cart-item-total'>
                    <span>Price each</span>
                    <span>{price}</span>
                </div>
                <div className='cart-item-row cart-item-total'>
                    <span className="total-nJyOdY">Total</span>
                    <span id={"total_" + idProduct} className="total">{total}</span>
                </div>
            </div>
        </div>
    </div>
);


CartItem.propTypes = {
    nameProduct: PropTypes.string.isRequired,
    quantity: PropTypes.string.isRequired,
    price: PropTypes.string.isRequired,
};


function onChangeQuantity(event, idProduct, price) {

    if (idProduct) {
        var quantity = event.target.value;
        if (price) {
            var elementTotal = document.getElementById("total_" + idProduct);
            price = price.replace("$", "");
            var sumTotalItem = quantity * price;
            elementTotal.innerHTML = "$" + (sumTotalItem).toFixed(2);

            helperCart.changeQuantityInCart(idProduct, quantity);
        } else {
            helperCart.changeQuantityInCart(idProduct, quantity);
        }
    } else {

        console.log('Something is wrong! (onChangeQuantity)');
    }
}


function removeItem(event, idProduct) {
    if (helperCart.removeProductFromCart(idProduct)) {
        var elementProduct = document.getElementById("idProduct_" + idProduct);
        elementProduct.style.display = "none";
        var elementTotal = document.getElementById("total_" + idProduct);
        elementTotal.classList.remove("total");
    } else {
        alert("Something is wrong! (removeItem)");
    }
    helperCart.calculeteTotal();
    helperCart.redirectToEmptyCart();
}

export default CartItem;
