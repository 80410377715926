import helperCookie from './HelperCookie';
import helperSession from './HelperSession';

const helperCart = {

    

    onlyUnique: function(value, index, self) {
        return self.indexOf(value) === index;
    },

    addProductToCart: function (data) {
        var flag = false;

        var listItemsCart = [];
        var listProductCart = this.getCart();
        var i = 0, y = 0, x = 0;

        if (listProductCart) {

            if (data) {
                if (listProductCart.length > 0) {

                    var flagNewProduct = true;
                    let dataIdProductCurrent = data.idProduct;
                    var cartFoundProduct = "";

                    for (y = 0; y < listProductCart.length; y++) {
                        let cartIdProductCurrentCart = listProductCart[y].idProduct;

                        if (dataIdProductCurrent === cartIdProductCurrentCart) {
                            flagNewProduct = false;
                            cartFoundProduct = listProductCart[y];
                            flag = true;
                            break;
                        }
                    }

                    if (flagNewProduct) {
                        listProductCart.push(data);
                        helperCookie.setCookie('cart', JSON.stringify(listProductCart), helperSession.getSessionTimeHours());
                        flag = true;
                    } else {
                        if (cartFoundProduct) {

                            var flagNewOption = true;

                            for (x = 0; x < data.listOptions.length; x++) {
                                let dataItemList = data.listOptions[x];

                                var cartFoundOption = "";
                                for (i = 0; i < cartFoundProduct.listOptions.length; i++) {
                                    let cartItemList = cartFoundProduct.listOptions[i];

                                    if (dataItemList.typeId === cartItemList.typeId) {
                                        flagNewOption = false;
                                        cartFoundOption = cartItemList;
                                        flag = true;
                                        break;
                                    } else {

                                    }
                                }

                                if (flagNewOption) {
                                    cartFoundProduct.listOptions.push(dataItemList)
                                } else {

                                    if (dataItemList.valueId !== cartFoundOption.valueId) {
                                        cartFoundProduct.listOptions = [];
                                        cartFoundProduct.listOptions.push(dataItemList)
                                        flagNewOption = true;
                                    }
                                }
                            }
                        }

                        if (flagNewOption) {
                            helperCookie.setCookie('cart', JSON.stringify(listProductCart), helperSession.getSessionTimeHours());
                            flag = true;
                        }
                    }

                } else {
                    listItemsCart.push(data);
                    helperCookie.setCookie('cart', JSON.stringify(listItemsCart), helperSession.getSessionTimeHours());
                    flag = true;
                }
            }
        } else {
            listItemsCart.push(data);
            helperCookie.setCookie('cart', JSON.stringify(listItemsCart), helperSession.getSessionTimeHours());
            flag = true;
        }
        this.calculeteTotal();
        return flag;
    }
    ,

    removeProductFromCart: function (id) {
        if (id) {
            var flag = false;
            var listProductCart = this.getCart();
            var i;
            for (i = 0; i < listProductCart.length; i++) {
                var idProduct = listProductCart[i].idProduct;

                if (idProduct === id) {
                    listProductCart.splice(i, 1);
                    flag = true;
                }
            }

            if (flag) {
                helperCookie.setCookie('cart', JSON.stringify(listProductCart), helperSession.getSessionTimeHours());
                this.calculeteTotal();
                return true;
            } else {
                return false;
            }

        }
        return false;
    }
    ,

    changeQuantityInCart: function (id, quantity) {
        if (id && quantity) {
            var listProductCart = this.getCart();
            if (listProductCart) {
                var i;
                for (i = 0; i < listProductCart.length; i++) {
                    var idProduct = listProductCart[i].idProduct;
                    if (idProduct === id) {
                        listProductCart[i].quantity = quantity;
                        var total = listProductCart[i].price * listProductCart[i].quantity;
                        listProductCart[i].total = total.toFixed(2);
                    }
                }
                helperCookie.setCookie('cart', JSON.stringify(listProductCart), helperSession.getSessionTimeHours());
                this.calculeteTotal();
            } else {
                return false;
            }

        }
        return false;
    }
    ,

    getCart: function () {
        var listProductCartJSON = helperCookie.getCookie('cart');
        return JSON.parse(listProductCartJSON);
    },

    calculeteTotal: function () {
        var totalCart = 0;
        var divs_total = document.querySelectorAll(".total");
        [].forEach.call(divs_total, function (e) {
            var total = e.innerHTML.replace("$", "");
            totalCart = Number(totalCart) + Number(total);
        });

        var elementSubTotalCart = document.getElementById("subtotal_cart");
        var elmsTaxAmaount = document.querySelectorAll("[id='tax_amount']");
        var elementTotalCart = document.getElementById("total_cart");
        var elmsTaxValue = document.querySelectorAll("[id='hide_tax_value']");


        if (elementSubTotalCart && elmsTaxAmaount && elementTotalCart && elmsTaxValue) {
            var totalAmount = 0;
            elementSubTotalCart.innerHTML = "$" + Number(totalCart).toFixed(2);
            for (var i = 0; i < elmsTaxAmaount.length; i++) {
                var currentTaxValue = elmsTaxValue[i].innerHTML;
                var amount = totalCart / 100 * currentTaxValue;
                totalAmount = totalAmount + amount;
                elmsTaxAmaount[i].innerHTML = "$" + amount.toFixed(2);
            }
            totalAmount = totalAmount + totalCart;
            elementTotalCart.innerHTML = "$" + totalAmount.toFixed(2);
        }
        this.calculeteQuantityCart();

    },

    redirectToEmptyCart: function (quantityCart) {
        var listProductCart = this.getCart();
        if (listProductCart.length === 0) {
            window.location.replace("cart/request-quote/");
        }
    },

    calculeteQuantityCart: function () {
        var listProductCart = this.getCart();
        var totalQuantity = 0;

        if (listProductCart) {
            var elementCartCounts = document.querySelectorAll("[id='idCartCounts']");

            var i;
            for (i = 0; i < listProductCart.length; i++) {
                totalQuantity = Number(totalQuantity) + Number(listProductCart[i].quantity);
            }

            for (i = 0; i < elementCartCounts.length; i++) {
                elementCartCounts[i].innerHTML = totalQuantity === 0 ? "" : totalQuantity;
                if (totalQuantity) {
                    elementCartCounts[i].style.display = "block";
                } else {
                    elementCartCounts[i].style.display = "none";
                }

            }
            totalQuantity = listProductCart.length;
        }
        return totalQuantity;

    },

    findProductCart: function (id) {
        var listProductCart = this.getCart();
        var flag = false;
        if (listProductCart) {
            var i;
            for (i = 0; i < listProductCart.length; i++) {
                var idProduct = listProductCart[i].idProduct;
                if (idProduct === id) {
                    flag = true;
                    break;
                }
            }

        } else {
            flag = false;
        }
        return flag;
    },


}

export default helperCart;