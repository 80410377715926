

const HelperCommon = {

    validateNumber(evt) {

        if (evt.keyCode !== 8) {
            var theEvent = evt || window.event;
            var key = theEvent.keyCode || theEvent.which;
            key = String.fromCharCode(key);
            var regex = /[0-9]|\./;
            if (!regex.test(key)) {
                theEvent.returnValue = false;

                if (theEvent.preventDefault)
                    theEvent.preventDefault();
            }
        }
    },

    hideSectionErrorMessage() {
        var element = document.getElementById("info-message");
        if (element) {
            element.style.display = "none";
        }
    },

    displaySectionMessage(message, error) {
        var element = document.getElementById("info-message");
        if (element) {
            element.style.display = "block";

            if (message) {
                if (error) {
                    element.classList.add('error-message');
                    element.classList.remove('info-message');
                } else {
                    element.classList.add('info-message');
                    element.classList.remove('error-message');
                }
                element.innerHTML = message;
            } else {
                element.classList.add('error-message');
                element.classList.remove('info-message');
                element.innerHTML = 'something is wrong!';
            }
        }
    },

    displayLoadingSection() {
        var element = document.getElementById("show-loading");
        if (element) {
            element.style.display = "block";
        }
    },

    hideLoadingSection() {
        var element = document.getElementById("show-loading");
        if (element) {
            element.style.display = "none";
        }
    }
    ,
    encodedString: function (string) {
        return btoa(string)
    },
    dencodedString: function (string) {
        return atob(string)
    },

    doInvalid(element) {
        if (element) {
            element.classList.add('invalid')
        }
    },
    doValid(element) {
        if (element) {
            element.classList.remove('invalid')
        }
    },

    togglePasswordToText(){
        const togglePassword = document.querySelector('#togglePassword');
        const password = document.querySelector('#password');

        if (togglePassword) {
            togglePassword.addEventListener('click', function (e) {
                // toggle the type attribute
                const type = password.getAttribute('type') === 'password' ? 'text' : 'password';
                password.setAttribute('type', type);
                // toggle the eye / eye slash icon
                this.classList.toggle('bi-eye');
            });
        }
 

    },
    toggleRePasswordToText() {
        const togglePassword = document.querySelector('#toggleRePassword');
        const password = document.querySelector('#confirm_password');

        if (togglePassword) {
        togglePassword.addEventListener('click', function (e) {
            // toggle the type attribute
            const type = password.getAttribute('type') === 'confirm_password' ? 'text' : 'confirm_password';
            password.setAttribute('type', type);
            // toggle the eye / eye slash icon
            this.classList.toggle('bi-eye');
        });
        }
    }
}

export default HelperCommon;