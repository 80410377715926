import React from "react";
import './css/CartRequestQuote.css';

const ProducPartItem = ({ description, urlImg, title }) => (

    <div>
        <div className='close-button'  >
            <span></span>
            <span></span>
        </div>
        <img alt="jenkins" src={urlImg} className="hotspot-image" />
        <div className="hotspot-description">
            <h4>{title}</h4>
            <p>{description}</p>
        </div>
    </div>

);


export default ProducPartItem;
