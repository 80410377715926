
import React from "react";
import ReactModal from 'react-modal';

const myAccountUpdate = ({ context, customStyles }) => (

    <ReactModal
        isOpen={context.state.showModalMyAccountUpdate}
        contentLabel="onRequestClose Example"
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
    >


        <div className="popup-header-bar">
            <div className="wrapper">
                <h3 className="popup-title">Update Account</h3>

                <div onClick={context.closeModalMyAccountUpdate} className='close-button'>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>

        <div className="popup-content">
            <div className="wrapper">
                <form onSubmit={e => context.handleSubmitMyAccountUpdate(e)} autoComplete="none" className="needs-validation" noValidate >
                    <div className="form-field">
                        <label>Business Name</label>
                        <input id="company_name" required value={context.state.company_name} name="company_name" onChange={e => context.handleChange(e)}  placeholder="Company Name*" type="text" ref={(company_name) => context.company_name = company_name} />
                        <div className="invalid-feedback">
                            this field is required
                        </div>
                    </div>

                    <div className="form-field-container form-half-fields">
                        <div className="form-field">
                            <label>Contact Name</label>
                            <input id="contact_name" required value={context.state.contactName} onChange={e => context.handleChange(e)} name="contactName" placeholder="Contact Name*" type="text" ref={(contact_name) => context.contact_name = contact_name} />
                            <div className="invalid-feedback">
                                this field is required
                            </div>
                        </div>
                        <div className="form-field">
                            <label>Phone</label>
                            <input id="account_phone" required value={context.state.accountPhone} onChange={e => context.handleChange(e)}  name= "accountPhone" placeholder="Phone" type="text" ref={(account_phone) => context.account_phone = account_phone} />
                        </div>
                    </div>

                    <div className="form-field form-submit">
                        <input type="submit" value="UPDATE" />
                    </div>
                </form>
            </div>
        </div>

    </ReactModal>

);

export default myAccountUpdate;