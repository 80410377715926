import ProductsNetwork from '../network/ProductsNetwork';
import CommonNetwork from '../network/CommonNetwork';

const ProductRequests = {

    async GetProduct(id_product, context) {
        try {
            let response = await ProductsNetwork.GetProduct(id_product, context);
            if (!response.body) {
                window.location.replace("/product-no-longer/");
                return;
            }
            context.dataCart.nameProduct = response.body.name;

            var specificationsBody = [];
            var images = [];
            var videos = [];
            var metaDescription = "";
            var canonical = "";

            let specifications = JSON.parse(response.body.specifications);

            if (specifications) {
                for (let i = 0; i < specifications.length; i++) {
                    specificationsBody.push(specifications[i]);
                }
            }

            if (response.body.medias) {
                for (let i = 0; i < response.body.medias.length; i++) {
                    let item = response.body.medias[i];
                    if (item.type === 0) {
                        images.push({
                            original: '/uploads/products/' + item.name,
                            thumbnail: '/uploads/products/' + item.name,
                            originalAlt: item.alt,
                            thumbnailAlt: item.alt,
                        });
                    } else if (item.type === 1) {
                        videos.push(item);
                    }
                }
            }

            if (!response.body.canonical) {
                canonical = window.location.href;
            } else {
                canonical = response.body.canonical;
            }

            if (!response.body.metaDescription) {
                metaDescription = response.body.description;
            } else {
                metaDescription = response.body.metaDescription;
            }

            context.setState({
                nameProduct: response.body.name,
                description: response.body.description,
                imgUrl: response.body.imgUrl,
                components: response.body.components,
                medias: response.body.medias,
                specificationsBody: specificationsBody,
                images: images,
                videos: videos,
                testimonials: response.body.testimonials,
                brochures: response.body.brochures,
                features: response.body.features,
                metaDescription: metaDescription,
                canonical: canonical,
                title: "" + response.body.name + " | Jenkins",
                keywords: response.body.keywords.map(k => k.name).join(","),
                shippingLeadTime: response.body.shippingLeadTime

            });

            for (var i = 0; i < response.body.brochures.length; i++) {
                var item = response.body.brochures[i];
                if (i === 0) {
                    context.setState({
                        idManualBrochures: item.id,
                        nameManualBrochures: item.name
                    });
                }
                if (i === 1) {
                    context.setState({
                        idOwnersBrochures: item.id,
                        nameOwnersBrochures: item.name
                    });
                }
            }
            return true;

        } catch (e) {
            console.log("GetProduct " + e.message);
        }
        return false;
    },

    async GetRandomProducts(context) {
        try {
            let response = await ProductsNetwork.GetRandomProducts(context);
            context.setState({
                customersAlsoViewProducts: response.body,
            });
            return true;

        } catch (e) {
            console.log("GetRandomProducts " + e.message);
        }
        return false;
    },

    async GetOptionsByProduct(id_product, context) {
        try {
            let response = await ProductsNetwork.GetOptionsByProduct(id_product, context);
            const listOptions = response.body;
            const total = 0;
            var singleOption = "";
            if (listOptions.length === 1 && listOptions[0].options.length === 1) {
                singleOption = listOptions;
            }
            context.setState({
                listOptions: listOptions,
                total: total.toFixed(2),
                singleOption: singleOption,
                valitationsBefore: singleOption ? false : true
            });
            context.hideExtraElementsOptionList("");
            return true;

        } catch (e) {
            console.log("GetOptionsByProduct " + e.message);
        }
        return false;
    },



    async GetOptionsByProductWhithPrices(id_product, context) {
        try {
            let response = await ProductsNetwork.GetOptionsByProductWhithPrices(id_product, context);
            const listOptions = response.body;
            const total = 0;
            var arrOptions = listOptions[0].options;
            var singleOption = "";

            if (listOptions.length === 1 && listOptions[0].options.length === 1) {
                singleOption = listOptions;
            }

            arrOptions.sort(function (a, b) {
                return (a.position === null) - (b.position === null) || +(a.position > b.position) || -(a.position < b.position);
            });

            context.setState({
                listOptions: listOptions,
                total: total.toFixed(2),
                singleOption: singleOption
            });
            return true;

        } catch (e) {
            console.log("GetOptionsByProductWhithPrices " + e.message);
        }
        return false;
    },


    async getHost(context) {
        try {
            let response = await CommonNetwork.getHost(context);
            context.setState({
                host: response.body
            });
            this.GetProduct(context.id_product, context);
            this.GetRandomProducts(context);
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    },

    async GetMetaDataByProduct(id_product, context) {
        try {
            let response = await ProductsNetwork.GetMetaDataByProduct(id_product, context);
            if (response.body) {
                context.setState({
                    title: response.body.title,
                    metaDescription: response.body.metaDescription,
                    canonical: response.body.canonical,
                });
            }
            return true;

        } catch (e) {
            console.log("GetMetaDataByProduct " + e.message);
        }
        return false;
    },


}


export default ProductRequests;