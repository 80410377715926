export class MassageContactForm {
    constructor(id, name, email, phone, description, countryId, countryName, stateId, stateName) {
        this.id = id;
        this.name = name;
        this.description = description;
        this.email = email;
        this.phone = phone;
        this.countryId = countryId;
        this.countryName = countryName;
        this.stateId = stateId;
        this.stateName = stateName;
    }
}