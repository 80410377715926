import helperCommon from '../HelperCommon';
import baseNetwork from '../network/BaseNetwork';

const CommonNetwork = {

    async sendMessageEmail(dataJson, context, text, phone) {
        let dataHaders = {
            'Content-Type': 'application/json'
        };

        let method = 'post';
        let datalink = `/ContactForms/Create`;
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

    },

    async getHost(context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };

        let method = 'get';
        let datalink = `data/GetHost`;
        let dataBody = "";

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;
    },

    async getMetaData(context, pathname) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Content-Lenght':'0'

        };

        let method = 'get';
        let datalink = `data/GetMetaData?name=${pathname}`;
        let dataBody = "";

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;
    },

    checkEmail(email, context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };

        let method = 'GET';
        let datalink = `data/ValidateEmail/?email=${email}`;
        let dataBody = "";

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;
    },

    async validateEmail(email, idEmail) {
        await fetch(`data/ValidateEmail/?email=${email}`, {
            method: 'GET',

        })
            .then(function (response) {
                if (response.status !== 200) {
                    helperCommon.displaySectionMessage(response.statusText, true);
                }
                return response.json();
            })
            .then(function (result) {

                if (result.status === 'Success') {
                    if (!result.body.isAvailable) {
                        var feedback_email_contact = document.getElementById("feedback_email_contact");
                        var feedback_username_contact = document.getElementById("feedback_username_contact");
                        if (idEmail === 'email') {
                            feedback_email_contact.style.display = "block";
                            feedback_email_contact.innerHTML = result.message;
                        } else if (idEmail === 'username') {
                            feedback_username_contact.style.display = "block";
                            feedback_username_contact.innerHTML = result.message;
                        }

                    } else {
                        if (idEmail === 'email') {
                            feedback_email_contact.style.display = "none";
                        } else if (idEmail === 'username') {
                            feedback_username_contact.style.display = "none";
                        }
                    }

                } else if (result.status === 'Error') {
                    console.log('Request failed', result.message);
                    helperCommon.displaySectionMessage(result.message, true);
                }
                else {
                    console.log('Request failed', JSON.stringify(result.errors));
                    helperCommon.displaySectionMessage('', true);
                }

            })
            .catch(function (error) {
                console.log('Request failed', JSON.stringify(error));
                helperCommon.displaySectionMessage('', true);
            });
    },
    async PageNotFound(context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };

        let method = 'post';
        let datalink = `data/PageNotFound`;
        let dataBody = "";

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;
    },

    
}

export default CommonNetwork;