import React from "react";
import './css/CartCheckOut.css';
import ReactModal from 'react-modal';
import AddressItem from './AddressItem';

const AddressList = ({ context, listClientAddresses, customStyles, nameTypeAddress, numberTypeAddress }) => (

    <ReactModal
        isOpen={numberTypeAddress === 0 ? context.state.showModalSelectAddresses : context.state.showModalSelectAddressesShipping}
        contentLabel="onRequestClose Example"
        onRequestClose={numberTypeAddress === 0 ? context.handleCloseModalSelectAddresses : context.handleCloseModalSelectAddressesShipping}
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        style={customStyles}
    >


        <div className="popup-header-bar">
            <div className="wrapper">
                <h3 className="popup-title">{"List of " + nameTypeAddress}</h3>

                <div onClick={numberTypeAddress === 0 ? context.handleCloseModalSelectAddresses : context.handleCloseModalSelectAddressesShipping} className='address-popup-close close-button'>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </div>


        <div className="popup-content">
            <div className="wrapper">
                <div className="address-popup-container">
                    <div className='address-listings'>

                        {listClientAddresses && listClientAddresses.length > 0 &&
                            listClientAddresses.map((item, index) => (
                                <div key={index} className='address-listing'>
                                    <AddressItem key={index} cityAndState={item['city'] + ', ' + item['stateName']}
                                        countryAndPost={item['countryName'] + ', ' + item['postCode']}
                                        address={item['street']} context={context} index={index} type={numberTypeAddress}>

                                    </AddressItem>
                                </div>
                            ))
                        }

                    </div>

                    <div className="buttons-wrapper center-buttons xs-padding-top">
                        <div onClick={numberTypeAddress === 0 ? context.handleOpenAddAddressBilling : context.handleOpenAddAddressShipping} className="button">
                            Add New Address
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </ReactModal>

);

export default AddressList;
