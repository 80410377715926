import UserContactInfoNetwork from '../network/UserContactInfoNetwork';
import UserAccountNetwork from '../network/UserAccountNetwork';
import UserAuthNetwork from '../network/UserAuthNetwork';
import UserShoppingNetwork from '../network/UserShoppingNetwork';
import helperSession from '../HelperSession';
import CommonNetwork from '../network/CommonNetwork';


const MyAccountRequests = {

    async getCountriesAndStates(context) {

        UserContactInfoNetwork.getCountriesAndStates(context);
    },

    async login(dataJson, context) {

        UserContactInfoNetwork.getCountriesAndStates(context);
        try {
            let response = await UserAuthNetwork.login(dataJson, context);
            helperSession.setToken('token', response.token, response.expiration);
            return true;

        } catch (e) {
            console.log("login " + e.message);
        }
        return false;
    },

    async getAddresses(context) {

        try {
            let response = await UserContactInfoNetwork.getAddresses(context);

            let shipping = response.body.find(a => a.shipping);
            let billing = response.body.find(a => a.billing);

            context.setState({
                listClientAddressesBilling: response.body,
                listClientAddressesShipping: response.body,
                currentBillingAddress: billing,
                currentShippingAddress: shipping

            });
            return true;

        } catch (e) {
            console.log("getAddresses " + e.message);
        }
        return false;
    },

    async createAddress(dataJson, context) {

        try {
            let response = await UserContactInfoNetwork.createAddress(dataJson, context);
            if (response.body) {
                this.getAddresses(context);
                context.handleCloseAddAddressBilling();
                context.handleCloseAddAddressShipping();
            } else {
                console.log("GetContact error");
            }
        } catch (e) {
            console.log("createAddress " + e.message);
        }
    },

    async updateAccount(dataJson, context) {
        try {
             await UserAccountNetwork.updateAccount(dataJson, context);
            return true;

        } catch (e) {
            console.log("updateAccount " + e.message);
        }
        return false;
    },

    async getClientAccount(context) {
        return UserAccountNetwork.getClientAccount(context);
    },

    async setBillingAddress(dataJson, context) {
        try {
            await UserAccountNetwork.setBillingAddress(dataJson, context);
            return true;
        } catch (e) {
            console.log("setBillingAddress " + e.message);
        }
        return false;
    },

    async setShippingAddress(idAddress, context) {
        try {
            await UserAccountNetwork.setShippingAddress(idAddress, context);
            return true;
        } catch (e) {
            console.log("setShippingAddress " + e.message);
        }
        return false;
    },

    async getClient(context) {
        try {
            let response = await UserAccountNetwork.getClient(context);

            context.setState({
                responseDataUser: response.body,
                company_name: response.body.businessName,
            });
            return true;
        } catch (e) {
            console.log("GetContact " + e.message);
        }

        return false;
    },

    async getContact(context) {

        try {
            let response = await UserContactInfoNetwork.getContact(context);
            let userEmail = response.body.filter(e => e.contactTypeId === 3)[0];
            let userPhone = response.body.filter(e => e.contactTypeId === 2)[0];
            context.setState({
                userEmail: userEmail.value,
                userName: userEmail.name,
                accountPhone: userPhone.value,
                contactName: userPhone.name,
                idPhone: userPhone.id,
                phoneContactTypeId: userPhone.contactTypeId,
     
            });
        } catch (e) {
            console.log("GetContact " + e.message);
        }
    },

    async getHystoryOrders(context) {
        try {
            let response = await UserShoppingNetwork.getHystoryOrders(context);
            context.setState({
                responseDataOrders: response.body.orders,
            });
        } catch (e) {
            console.log("GetContact " + e.message);
        }
    },

    async changePassword(dataJson, context) {
        try {
            await UserAuthNetwork.changePassword(dataJson, context);
            context.closeModalMyAccountUpdatePassword();

        } catch (e) {
            console.log("ChangePassword " + e.message);
        }
    },

    async updateContact(dataJson, context) {
        try {
            await UserContactInfoNetwork.updateContact(dataJson, context);
            context.closeModalMyAccountUpdate();
            return true;

        } catch (e) {
            console.log("updateContact " + e.message);
        }
        return false;
    },
    async checkValidateEmail(email, idEmail) {
        CommonNetwork.validateEmail(email, idEmail);
    },


}

export default MyAccountRequests;