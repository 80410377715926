import helperSession from '../HelperSession';
import baseNetwork from '../network/BaseNetwork';

const UserShoppingNetworkNetwork = {

    reguestCreateOrder(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        }
        let method = 'post';
        let datalink = '/data/SetOrder';
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },

    async reguestCreateQuote(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        }
        let method = 'post';
        let datalink = '/data/SetQuote';
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    getHystoryOrders(context) {
        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        }
        let method = 'post';
        let datalink = 'api/Authenticate/GetOrders';
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },

    getOptionsFromQuote(quoteId, customerId, context) {
        let dataHaders = {
            'Content-Type': 'application/json',
        }
        let method = 'post';
        let datalink = `data/getOptionsFromQuote?quoteId=${quoteId}&customerId=${customerId }`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },
}

export default UserShoppingNetworkNetwork; 