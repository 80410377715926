import React, { Component } from 'react';
import { Route } from 'react-router';
import { Layout } from './components/Layout';
import { Home } from './components/Home';
import { AboutUs } from './components/AboutUs';
import { Product } from './components/Product';
import { ContactUs } from './components/ContactUs';
import { ProductList } from './components/ProductList';
import { CartRequestQuote } from './components/CartRequestQuote';
import { CartCheckOut } from './components/CartCheckOut';
import { Login } from './components/Login';
import { Register } from './components/Register';
import { LostPassword } from './components/LostPassword';
import { ResetPassword } from './components/ResetPassword';
import { ConfirmUser } from './components/ConfirmUser';
import { ThankYou } from './components/ThankYou';
import { CartIsEmpty } from './components/CartIsEmpty';
import { MyAccount } from './components/MyAccount';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import { AccountComfirmed } from './components/AccountComfirmed';
import { Brochure } from './components/Brochure';
import { CartRequestQuoteComplete } from './components/CartRequestQuoteComplete';
import { Page404 } from './components/404';
import { Redirect, Switch } from 'react-router-dom';
import './custom.css'
import TagManager from 'react-gtm-module'
import { BrochureByClient } from './components/BrochureByClient';
import { ProductNoLonger } from './components/ProductNoLonger';



export default class App extends Component {
    static displayName = App.name;
    //GTM-NZQ54F7 PRODUCTION
    //GTM-5T3R6CS QA
    componentDidMount() {
        const tagManagerArgs = {
            gtmId: 'GTM-NZQ54F7'
        }
        TagManager.initialize(tagManagerArgs);
    }

    render() {
        TagManager.dataLayer({
            dataLayer: {
                event: 'gtm.historyChange',
            },
        });
        return (
            <Layout>

                <Switch>
                    <Redirect exact path="/products/root-grapple" to="/products/stump-bucket-grapple/35/" />
                    <Redirect exact path="/product-category/skid-steer-attachments" to="/categories/all/" />
                    <Redirect exact path="/products" to="/categories/all/" />
                    <Redirect exact path="/products/4-1-bucket" to="/products/4-in-1-bucket/14/" />
                    <Redirect exact path="/products/4-in-1-bucket-with-teeth" to="/products/4-in-1-bucket/14/" />
                    <Redirect exact path="/products/backhoe" to="/products/backhoe/20/" />
                    <Redirect exact path="/products/bale-spears" to="/products/bale-spear/19/" />
                    <Redirect exact path="/products/brush-grapple" to="/products/brush-grapple/3/" />
                    <Redirect exact path="/products/super-duty-brush-grapple" to="/products/brush-grapple-super-duty-split/4/" />
                    <Redirect exact path="/products/brush-mower" to="/products/brush-mower/30/" />
                    <Redirect exact path="/products/super-duty-mower" to="/products/brush-mower-super-duty-direct-drive/31/" />
                    <Redirect exact path="/products/concrete-hopper" to="/products/concrete-hopper/38/" />
                    <Redirect exact path="/products/debris-grapple" to="/products/debris-grapple/2/" />
                    <Redirect exact path="/products/dirt-bucket-teeth" to="/products/low-profile-dirt-bucket/12/" />
                    <Redirect exact path="/products/dozer-blades" to="/products/dozer-blade/27/" />
                    <Redirect exact path="/products/earth-auger" to="/products/earth-auger/6/" />
                    <Redirect exact path="/products/landscape-bucket" to="/products/landscape-bucket/10/" />
                    <Redirect exact path="/products/extendable-boom" to="/products/extendable-boom/40/" />
                    <Redirect exact path="/products/high-capacity-grapples" to="/products/high-capacity-grapple/13/" />
                    <Redirect exact path="/products/high-dump-bucket" to="/products/high-dump-bucket/16/" />
                    <Redirect exact path="/products/hydraulic-angle-snowblade" to="/products/snow-blade-hydraulic-angle/28/" />
                    <Redirect exact path="/products/hydraulic-sliding-pallet-fork" to="/products/hydraulic-sliding-pallet-forks/18/" />
                    <Redirect exact path="/products/ice-scraper" to="/products/ice-scraper/41/" />
                    <Redirect exact path="/products/land-leveler" to="/products/land-leveller/24/" />
                    <Redirect exact path="/products/heavy-duty-low-profile-material-buckets" to="/products/low-profile-dirt-bucket/12/" />
                    <Redirect exact path="/products/grapple-bucket" to="/products/low-profile-grapple-bucket/44/" />
                    <Redirect exact path="/products/pallet-forks" to="/products/pallet-forks/17/" />
                    <Redirect exact path="/products/post-tree-puller" to="/products/post-and-tree-puller/33/" />
                    <Redirect exact path="/products/quick-attach-plates" to="/products/quick-attach-plates/43/" />
                    <Redirect exact path="/products/receiver-hitch" to="/products/receiver-hitch/42/" />
                    <Redirect exact path="/products/skeleton-rock-bucket" to="/products/rock-bucket/11/" />
                    <Redirect exact path="/products/small-rock-bucket-grapple" to="/products/skeleton-grapple/1/" />
                    <Redirect exact path="/products/skeleton-rock-and-brush-grapple" to="/products/skeleton-grapple/1/" />
                    <Redirect exact path="/products/snow-buckets" to="/products/snow-and-materials-bucket/7/" />
                    <Redirect exact path="/products/deluxe-snow-pusher" to="/products/snow-pusher-deluxe/9/" />
                    <Redirect exact path="/products/snow-pushers" to="/products/snow-pusher/8/" />
                    <Redirect exact path="/products/landscape-rake" to="/products/soil-conditioner/25/" />
                    <Redirect exact path="/products/stump-bucket" to="/products/stump-bucket/34/" />
                    <Redirect exact path="/products/stump-bucket-grapple" to="/products/stump-bucket-grapple/35/" />
                    <Redirect exact path="/products/stump-grinder" to="/products/stump-grinder/36/" />
                    <Redirect exact path="/products/sweeper" to="/products/sweeper-broom-hydraulic-angle/22/" />
                    <Redirect exact path="/products/pick-up-broom" to="/products/sweeper-pick-up-broom/23/" />
                    <Redirect exact path="/products/tiller" to="/products/tiller/26/" />
                    <Redirect exact path="/products/tine-grapple" to="/products/tine-grapple/5/" />
                    <Redirect exact path="/products/trencher" to="/products/trencher/21/" />
                    <Redirect exact path="/products/wood-splitter" to="/products/wood-splitter/37/" />
                    <Redirect exact path="/products/winged-hydraulic-snow-blade" to="/products/winged-snow-blade-hydraulic-angle/29/" />
                    <Redirect exact path="/feedback" to="/" />
                    <Redirect exact path="/feedback?category=1252" to="/products/brush-grapple/3/" />
                    <Redirect exact path="/feedback?category=7547" to="/products/brush-mower-super-duty-direct-drive/31/" />
                    <Redirect exact path="/feedback?category=1231" to="/products/debris-grapple/2/" />
                    <Redirect exact path="/feedback?category=1258" to="/products/brush-mower/30/" />
                    <Redirect exact path="/feedback?category=1237" to="/products/rock-bucket/11/" />
                    <Redirect exact path="/feedback?category=1233" to="/products/skeleton-grapple/1/" />
                    <Redirect exact path="/feedback?category=1267" to="/products/stump-bucket-grapple/35/" />
                    <Redirect exact path="/feedback?category=1861" to="/products/extendable-boom/40/" />
                    <Redirect exact path="/feedback?category=1248" to="/products/post-and-tree-puller/33/" />
                    <Redirect exact path="/feedback?category=1243" to="/products/hydraulic-sliding-pallet-forks/18/" />
                    <Redirect exact path="/feedback?category=1428" to="/products/snow-blade-hydraulic-angle/28/" />
                    <Redirect exact path="/feedback?category=1223" to="/products/bale-spear/19/" />
                    <Redirect exact path="/feedback?category=993" to="/products-list/all/" />
                    <Redirect exact path="/feedback?category=989" to="/products/soil-conditioner/25/" />
                    <Redirect exact path="/feedback?category=990" to="/products/wood-splitter/37/" />
                    <Redirect exact path="/feedback?category=1599" to="/products/4-in-1-bucket/14/" />
                    <Redirect exact path="/feedback?category=1238" to="/products/low-profile-dirt-bucket/12/" />
                    <Redirect exact path="/feedback?category=1270" to="/products/backhoe/20/" />
                    <Redirect exact path="/feedback?category=1236" to="/products/snow-and-materials-bucket/7/" />
                    <Redirect exact path="/feedback?category=1186" to="/products/low-profile-grapple-bucket/44/" />
                    <Redirect exact path="/feedback?category=1246" to="/products/high-capacity-grapple/13/" />
                    <Redirect exact path="/winter-attachments" to="/categories/snow/" />
                    <Redirect exact path="/BrushGrapple.php" to="/products/brush-grapple/3/" />
                    <Redirect exact path="/category/feedback" to="/" />
                    <Redirect exact path="/category/feedback/extendable-boom" to="/products/extendable-boom/40/" />
                    <Redirect exact path="/product-category/summer-attachments" to="/categories/all/" />
                    <Redirect exact path="/products/electromagnet" to="/categories/all/" />
                    <Redirect exact path="/SkeletonGrapple.php" to="/products/skeleton-grapple/1/" />
                    <Redirect exact path="/Root_Grapple_Attachment.php" to="/products/stump-bucket-grapple/35/" />
                    <Redirect exact path="/ReceiverHitch.php" to="/products/receiver-hitch/42/" />
                    <Redirect exact path="/feedback/butch-backhoe-testimonial" to="/products/backhoe/20/" />
                    <Redirect exact path="/Upcoming_Attachments.html" to="/categories/all/" />
                    <Redirect exact path="/LowProfileDirtBucket.php" to="/products/low-profile-dirt-bucket/12/" />
                    <Redirect exact path="/feedback/stump-bucket-grapple-2" to="/products/stump-bucket-grapple/35/" />
                    <Redirect exact path="/feedback/skeleton-rock-bucket-testimonial" to="/products/rock-bucket/11/" />
                    <Redirect exact path="/feedback/tree-shear-2" to="/categories/all/" />
                    <Redirect exact path="/product-category/winter-attachments" to="/categories/snow/" />
                    <Redirect exact path="/products/tree-shear" to="/categories/all/" />
                    <Redirect exact path="/feedback/skeleton-grapple-terry-busching" to="/products/skeleton-grapple/1/" />
                    <Redirect exact path="/scripts/siteUtil.js?539531438" to="/" />
                    <Redirect exact path="/feedback/snow-material-bucket-testimonial" to="/products/snow-and-materials-bucket/7/" />
                    <Redirect exact path="/feedback/replacement-grapple-hydraulic-cylinder" to="/categories/all/" />
                    <Redirect exact path="/feedback/high-volume-snow-material-bucket-testimonial" to="/products/snow-and-materials-bucket/7/" />
                    <Redirect exact path="/products/posttree-puller" to="/products/post-and-tree-puller/33/" />
                    <Redirect exact path="/feedback/dennis-olson-hd-low-profile-material-bucket" to="/products/low-profile-dirt-bucket/12/" />
                    <Redirect exact path="/winter-attachments/4-in-1-bucket" to="/products/4-in-1-bucket/14/" />
                    <Redirect exact path="/products/6-way-dozer-blades" to="/products/dozer-blade/27/" />
                    <Redirect exact path="/products/stump-grinder-replacement-teeth" to="/products/stump-grinder/36/" />
                    <Redirect exact path="/products/high-volume-snow-and-material-buckets" to="/products/snow-and-materials-bucket/7/" />

                    <Route exact path='/' component={Home} />
                    <Route path='/brochure/' component={Brochure} />
                    <Route path='/brochureclient/' component={BrochureByClient} />
                    <Route path='/about-us/' component={AboutUs} />
                    <Route path='/products/' component={Product} />
                    <Route path='/contact/' component={ContactUs} />
                    <Route path='/categories/' component={ProductList} />
                    <Route path='/cart/request-quote/' component={CartRequestQuote} />
                    <Route path='/cart/request-quote-complete/' component={CartRequestQuoteComplete} />
                    <Route path='/cart/check-out/' component={CartCheckOut} />
                    <Route path='/cart/thank-you/' component={ThankYou} />
                    <Route path='/cart/empty/' component={CartIsEmpty} />
                    <Route path='/login/' component={Login} />
                    <Route path='/register/' component={Register} />
                    <Route path='/lostPassword/' component={LostPassword} />
                    <Route path='/my-account/' component={MyAccount} />
                    <Route path='/ResetPassword/' component={ResetPassword} />
                    <Route path='/ConfirmUser/' component={ConfirmUser} />
                    <Route path='/privacy-policy/' component={PrivacyPolicy} />
                    <Route path='/account-comfirmed/' component={AccountComfirmed} />
                    <Route path='/product-no-longer/' component={ProductNoLonger} />
                    
                    <Route exact path='/404/' component={Page404} />
                    <Redirect to="/404/" />
                </Switch>
            </Layout>
        );
    }
}