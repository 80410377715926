import helperSession from '../HelperSession';
import helperCommon from '../HelperCommon';
import baseNetwork from '../network/BaseNetwork';

const UserAuthNetwork = {

    login(dataJson) {

        return fetch('api/Authenticate/login', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },
            body: dataJson
        })
            .then(function (response) {
                if (response.status !== 200) {
                    helperCommon.displaySectionMessage(response.statusText, true);
                }
                return response.json();
            })
            .then(function (result) {
                if (result) {
                    if (result.token) {
                        helperCommon.hideLoadingSection();
                    } else {
                        helperCommon.displaySectionMessage(result.message, true);
                        helperCommon.hideLoadingSection();
                        console.log('Request failed (login)', result.message);
                    }
                    return result;
                }
            })
            .catch(function (error) {
                console.log('Request failed (login)', error);
                helperCommon.hideLoadingSection();
                helperCommon.displaySectionMessage("", true);
            });

    },

    async confirmUser(context) {
        var url = new URL(window.location.href);
        var code = url.searchParams.get("code");
        var userId = url.searchParams.get("userId");

        await fetch(`api/Authenticate/RegisterConfirmation?code=${code}&userId=${userId}`, {
            method: 'post',
            headers: {
                'Content-Type': 'application/json'
            },

        })
            .then(function (response) {
                console.log('Response failed (confirmUser)', response);
                if (response.status !== 200) {
                    helperCommon.displaySectionMessage(response.statusText, true);
                }
                return response.json();
            })
            .then(function (result) {
                if (result.status === 'Success') {
                    context.redirectToAccountComfirmed();
                } else {
                    helperCommon.displaySectionMessage(result.status, true);
                    console.log('Request failed (confirmUser)', result.message);
                }

            })
            .catch(function (error) {
                console.log('Request failed (confirmUser)', error);
                helperCommon.displaySectionMessage(error, true);
            });

    },

    async resetPassord(email, context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'post';
        let datalink = `api/Authenticate/reset?email=${email}`;
        let dataBody = "";

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    changePassword(json, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = 'api/Authenticate/changePassword';
        let dataBody = json;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async resetPasswordByLink(json, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = `api/Authenticate/ResetConfirmation`;
        let dataBody = json
        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    }


}

export default UserAuthNetwork;