import React, { Component } from 'react';
import { Header } from './Header';
import Footer from './Footer';


export class ProductNoLonger extends Component {
    static isMobile = false;

    constructor(props, context) {
        super(props, context);

        this.isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
    }

    render() {

        const twitter = 'https://twitter.com/JenkinsIron';
        const youtube = 'https://www.youtube.com/channel/UCs16D1Lw49IuCRwJrJdQLvQ';
        const instagram = 'https://www.instagram.com/jenkinsironandsteel/';
        const facebook = 'https://www.facebook.com/JenkinsIronandSteel';

        return (
            <div id="container">
                <Header></Header>
                <main>
                    <section className="thank-you medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Product No Longer Exists</h1>

                            <div className="center-align text-lg">
                                <p>One or more product in quote Is no longer available, please contact a sales rep for more info</p>
                            </div>


                            <div className="thank-you-social large-padding">
                                <div className="highlight-container center-align">
                                    <h3 className="highlight highlight-red uppercase">See what we're up to on social media!</h3>
                                </div>
                                <ul>
                                    <li>
                                        <a href={twitter} target="_blank" rel="noopener noreferrer">
                                            <svg className="twitter" x="0px" y="0px" viewBox="0 0 30 30" width="30px" height="30px" preserveAspectRatio="xMidYMid meet">
									            <path d="M25.8,9.5c1.1-0.8,2-1.8,2.8-2.9c-1,0.4-2.1,0.7-3.2,0.9c1.1-0.7,2-1.8,2.4-3.1c-1.1,0.6-2.3,1.1-3.5,1.3 c-1-1.1-2.5-1.8-4.1-1.8c-3.6,0-6.2,3.3-5.4,6.8C10.2,10.6,6.1,8.4,3.3,5c-1.5,2.5-0.8,5.8,1.7,7.4c-0.9,0-1.8-0.3-2.5-0.7 c-0.1,2.6,1.8,5,4.5,5.5c-0.8,0.2-1.6,0.3-2.5,0.1c0.7,2.2,2.8,3.8,5.2,3.9c-2.3,1.8-5.3,2.6-8.2,2.3C3.9,25.1,6.8,26,10,26 C20.3,26,26.1,17.3,25.8,9.5z" />
								            </svg>
							            </a>
						            </li>
                                    <li>
                                        <a href={instagram} target="_blank" rel="noopener noreferrer">
                                            <svg className="instagram" x="0px" y="0px" viewBox="0 0 30 30" width="30px" height="30px" preserveAspectRatio="xMidYMid meet">
									            <g>
                                            <path d="M15,5.2c3.2,0,3.6,0,4.8,0.1c1.2,0.1,1.8,0.2,2.2,0.4c0.6,0.2,1,0.5,1.4,0.9c0.4,0.4,0.7,0.8,0.9,1.4 c0.2,0.4,0.4,1.1,0.4,2.2c0.1,1.3,0.1,1.6,0.1,4.8s0,3.6-0.1,4.8c-0.1,1.2-0.2,1.8-0.4,2.2c-0.2,0.6-0.5,1-0.9,1.4 c-0.4,0.4-0.8,0.7-1.4,0.9c-0.4,0.2-1.1,0.4-2.2,0.4c-1.3,0.1-1.6,0.1-4.8,0.1s-3.6,0-4.8-0.1c-1.2-0.1-1.8-0.2-2.2-0.4 c-0.6-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.8-0.9-1.4c-0.2-0.4-0.4-1.1-0.4-2.2c-0.1-1.3-0.1-1.6-0.1-4.8s0-3.6,0.1-4.8 C5.3,9,5.5,8.3,5.6,7.9c0.2-0.6,0.5-1,0.9-1.4C7,6.1,7.4,5.9,7.9,5.6C8.3,5.5,9,5.3,10.2,5.2C11.4,5.2,11.8,5.2,15,5.2 M15,3 c-3.3,0-3.7,0-4.9,0.1C8.8,3.1,7.9,3.3,7.1,3.6C6.4,3.9,5.7,4.3,5,5C4.3,5.7,3.9,6.4,3.6,7.1c-0.3,0.8-0.5,1.6-0.6,2.9 C3,11.3,3,11.7,3,15c0,3.3,0,3.7,0.1,4.9c0.1,1.3,0.3,2.1,0.6,2.9C3.9,23.6,4.3,24.3,5,25c0.7,0.7,1.3,1.1,2.1,1.4 c0.8,0.3,1.6,0.5,2.9,0.6C11.3,27,11.7,27,15,27s3.7,0,4.9-0.1c1.3-0.1,2.1-0.3,2.9-0.6c0.8-0.3,1.5-0.7,2.1-1.4 c0.7-0.7,1.1-1.3,1.4-2.1c0.3-0.8,0.5-1.6,0.6-2.9C27,18.7,27,18.3,27,15s0-3.7-0.1-4.9c-0.1-1.3-0.3-2.1-0.6-2.9C26.1,6.4,25.7,5.7,25,5c-0.7-0.7-1.3-1.1-2.1-1.4c-0.8-0.3-1.6-0.5-2.9-0.6C18.7,3,18.3,3,15,3L15,3z" />
                                            <path d="M15,8.8c-3.4,0-6.2,2.8-6.2,6.2s2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2S18.4,8.8,15,8.8z M15,19c-2.2,0-4-1.8-4-4 c0-2.2,1.8-4,4-4c2.2,0,4,1.8,4,4C19,17.2,17.2,19,15,19z" />
                                            <circle cx="21.4" cy="8.6" r="1.4" />
                                        </g>
								            </svg>
							            </a>
						            </li>
                                    <li>
                                        <a href={facebook} target="_blank" rel="noopener noreferrer">
                                            <svg className="facebook" x="0px" y="0px" viewBox="0 0 30 30" width="30px" height="30px" preserveAspectRatio="xMidYMid meet">
								                <path d="M27.1,15.1C27.1,8.4,21.7,3,15,3S2.9,8.4,2.9,15.1c0,6,4.4,11,10.2,11.9v-8.4H10v-3.5h3.1v-2.7c0-3,1.8-4.7,4.6-4.7 C19,7.7,20.4,8,20.4,8v3h-1.5c-1.5,0-2,0.9-2,1.9v2.3h3.3l-0.5,3.5h-2.8V27C22.7,26.1,27.1,21.1,27.1,15.1z" />
								            </svg>
							            </a>
						            </li>
						            <li>
                                        <a href={youtube} target="_blank" rel="noopener noreferrer">
                                            <svg className="youtube" x="0px" y="0px" viewBox="0 0 30 30" width="30px" height="30px" preserveAspectRatio="xMidYMid meet">
									            <path d="M28.6,8.1c-0.3-1.2-1.3-2.2-2.5-2.5C23.9,5,15,5,15,5S6.1,5,3.9,5.6C2.7,5.9,1.7,6.9,1.4,8.1C0.8,10.4,0.8,15,0.8,15 s0,4.6,0.6,6.9c0.3,1.2,1.3,2.2,2.5,2.5C6.1,25,15,25,15,25s8.9,0,11.1-0.6c1.2-0.3,2.2-1.3,2.5-2.5c0.6-2.2,0.6-6.9,0.6-6.9 S29.2,10.4,28.6,8.1z M12.1,19.2v-8.4l7.4,4.2L12.1,19.2z" />
								            </svg>
							            </a>
						            </li>
                                </ul>
                            </div>

                        </div>
                    </section>
                </main>
                <Footer></Footer>
            </div>


        );
    }
}