import ProductsNetwork from '../network/ProductsNetwork';
import CommonNetwork from '../network/CommonNetwork';
import UserAccountNetwork from '../network/UserAccountNetwork';

const BrochureRequests = {
    async getProductsForBrochure(context) {
        try {
            let response = await ProductsNetwork.getProductsForBrochure(context);

            context.setState({
                products: response.body,
            }, () => {
                //window.print()
            })

        } catch (e) {
            console.log("getProductsForBrochure " + e.message);
        }

    },
    async getHost(context) {
        try {
            let response = await CommonNetwork.getHost(context);
            context.setState({
                host: response.body
            });
            this.getProductsForBrochure(context);
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    },

    async GetProductsByClientGroupForBrochure(idClient, context) {
        try {
            let response = await UserAccountNetwork.GetProductsByClientGroupForBrochure(idClient, context);

            context.setState({
                products: response.body,
            }, () => {
                //window.print()
            })
            return true;

        } catch (e) {
            console.log("GetProductsByClientGroupForBrochure " + e.message);
        }
        return false;
    },

    async getHostByClientGroupForBrochure(idClient, context) {
        try {
            let response = await CommonNetwork.getHost(context);
            context.setState({
                host: response.body
            });
            this.GetProductsByClientGroupForBrochure(idClient, context);
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    },
}

export default BrochureRequests;