import CommonNetwork from '../network/CommonNetwork';
import ProductsNetwork from '../network/ProductsNetwork';


const ProductListRequests = {
    async getProductsForBrochure(context) {
        try {
            let response = await ProductsNetwork.getProductsForBrochure(context);

            context.setState({
                products: response.body,
            }, () => {
                   context.openBrochure()
            })

        } catch (e) {
            console.log("getProductsForBrochure " + e.message);
        }
    },

    async getHost(context) {
        try {
            let response = await CommonNetwork.getHost(context);
            context.setState({
                host: response.body
            });
            this.getListCatalogsData(context);
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    },

    async getListCatalogsData(context) {

        try {
            let response = await ProductsNetwork.getListCatalogsData(context);

            const listCatalogsTitle = response.body;
            context.setState({
                listCatalogsTitle: listCatalogsTitle
            });

            let arrayLink = window.location.href.split("/");
            if (arrayLink) {
                let nameGroup = arrayLink[arrayLink.length - 2];

                let itemTitleGroup = listCatalogsTitle.filter(e => e.name === nameGroup.toUpperCase())

                if (itemTitleGroup.length) {
                    context.selectCatalog(itemTitleGroup[0].id, itemTitleGroup[0].name)
                } else {
                    context.selectCatalog('-1', 'All')
                }
            } else {
                console.log('getListCatalogsData');
            }
            return true;
        } catch (e) {
            console.log("getListCatalogsData " + e.message);
        }
        return false;
    },

    async getAllProducts(context) {

        try {
            let response = await ProductsNetwork.getAllProducts(context);

            const listProductsTitle = response.body;
            context.setState({
                listProductsTitle: listProductsTitle
            });
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    },

    async getListProductsData(idCatalog, context) {
        try {
            let response = await ProductsNetwork.getListProductsData(idCatalog, context);

            const listProductsTitle = response.body;
            context.setState({
                listProductsTitle: listProductsTitle
            });
            return true;

        } catch (e) {
            console.log("getHost " + e.message);
        }
        return false;
    }
}

export default ProductListRequests;