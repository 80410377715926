export class AddressView {
    constructor(id, countryId, stateId, city, street, street2, unit, postCode, CountryName, StateName, Billing, Shipping) {
        this.id = id;
        this.countryId = countryId;
        this.CountryName = CountryName;
        this.stateId = stateId;
        this.StateName = StateName;
        this.city = city;
        this.street = street;
        this.street2 = street2;
        this.unit = unit;
        this.postCode = postCode;
        this.Billing = Billing;
        this.Shipping = Shipping;
    }
}