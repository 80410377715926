import React from "react";
import './css/CartCheckOut.css';

const AddressItem = ({ address, cityAndState, countryAndPost, type, context, index }) => (
    <div className="item-address">
        <ul>
            <li>{address}</li>
            <li>{cityAndState}</li>
            <li>{countryAndPost}</li>
        </ul>
        <div className='use-this-address viewOrder' onClick={e => context.onUseThisAddress(index, type)} >Use this address</div>
    </div>

);

export default AddressItem;
