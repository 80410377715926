import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ProductImg = ({ url, alt }) => (
    <div className="card-img-center " >
        <LazyLoadImage
            alt={alt}
            width="800"
            height="800"
            src={url} // use normal <img> attributes as props
          />
    </div>
);

export default ProductImg;