import React, { Component } from 'react';
import './css/CartRequestQuote.css';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import LostPasswordRequests from './requests/LostPasswordRequests';

export class LostPassword extends Component {
    static displayName = LostPassword.name;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            sentMessage: false
        };
    }

    componentDidMount() {
        this.hideSectionSentEmail();
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            LostPasswordRequests.resetPassord(this.username.value, this);
        } else {
            console.log("handleSubmit error:");
            helperCommon.displaySectionMessage("Please enter a correct Email", true);
        }
        e.preventDefault();
    }

    hideSectionSentEmail() {
        var element = document.getElementById("sent-email");
        if (element) {
            element.style.display = "none";
        }
    }

    displaySectionSentEmail() {
        var element = document.getElementById("sent-email");
        if (element) {
            element.style.display = "block";
        }
    }



    render() {
        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="login-form medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Forgot Password?</h1>

                            {!this.state.sentMessage ?
                                <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >

                                    <div id="reset-password" className="form-field">
                                        <input id="username" required placeholder="Enter Account Email*" type="email" ref={(username) => this.username = username} />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>


                                    <div id="info-message"></div>
                                    

                                    <div className='form-field form-submit btn-quote'>
                                        <input type="submit" value="Reset Password" />
                                    </div>

                                    <div className="center-align">
                                        <div id='show-loading' className="spinner-grow text-red" role="status">
                                            <span className="sr-only"></span>
                                        </div>
                                    </div>

                                </form>
                                :
                                <div id="sent-email" className='success-message text-center'>
                                    An email to reset your password has been sent to your inbox
                                </div>
                            }
                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }

}


