import helperSession from '../HelperSession';
import baseNetwork from '../network/BaseNetwork';


const ProductsNetwork = {

    async getListCatalogsData(context) {
        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'get';
        let datalink = `data/GetCategoriesTypeTitle`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },

    async getAllProducts(context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'get';
        let datalink = `data/GetAllProducts`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async getListProductsData(idCatalog, context) {

        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'get';
        let datalink = `data/GetProductsByCategory/${idCatalog}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },


    async GetProduct(id_product, context) {
        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'get';
        let datalink = `data/GetProduct/${id_product}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async GetRandomProducts(context) {
        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'get';
        let datalink = `data/GetRandomProducts/`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async GetOptionsByProduct(id_product, context) {
        let dataHaders = {
            'Content-Type': 'application/json'
        };
        let method = 'get';
        let datalink = `data/GetOptionsByProduct?productId=${id_product}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async GetOptionsByProductWhithPrices(id_product, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'get';
        let datalink = `data/GetOptionsByProductWithPrices?productId=${id_product}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    getProductsForBrochure(context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = 'data/GetProductsForBrochure';
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    
    async GetMetaDataByProduct(id_product, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
        };
        let method = 'get';
        let datalink = `data/GetMetaDataByProduct?productId=${id_product}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    
}

export default ProductsNetwork;