import React, { Component } from 'react';
import './css/ProductList.css';
import Footer from './Footer';
import { Header } from './Header';
import ProductImg from './ProductImg';
import ProductListRequests from './requests/ProductListRequests';
import helperSession from './HelperSession';
import HeaderRequests from './requests/HeaderRequests';
import DocumentMeta from 'react-document-meta';


export class ProductList extends Component {
    static displayName = ProductList.name;

    constructor(props) {
        super(props);
        document.title = "Products";
        this.state = { listCatalogsTitle: [], listProductsTitle: [], active_group: 'deactivate_group', nameGroup: 'All', host: '' };
        this.viewProduct = this.viewProduct.bind(this);
        this.selectCatalog = this.selectCatalog.bind(this);
    }

    componentDidMount() {
        ProductListRequests.getHost(this);
        let logined = helperSession.getLogined();
        this.setState({
            logined: logined
        });
    }

    viewProduct(id, nameProduct, categoryName) {
        let nameProductClean = nameProduct.replace(/\s+/g, '-');
        this.props.history.push({
            pathname: '/products/' + nameProductClean + '/' + id + '/',
            state: {
               categoryName: categoryName,
            }
        });
        window.scrollTo(0, 0)
    }

    selectCatalog(id, nameGroup) {
        var selectorsAll = document.querySelectorAll(".active-underline-group");
        var i;
        let idString = id.toString();
        for (i = 0; i < selectorsAll.length; i++) {
            selectorsAll[i].className = "deactivate_group";
        }
        var selectorsAllDeact = document.querySelectorAll(".deactivate_group");

        for (i = 0; i < selectorsAllDeact.length; i++) {
            var idElement = selectorsAllDeact[i].id;
            if (idElement === idString) {
                selectorsAllDeact[i].className = "active-underline-group";
            }
        }
       this.setState({
            nameGroup: nameGroup
        });
        if (idString === "-1") {
           ProductListRequests.getAllProducts(this);
        } else {
            ProductListRequests.getListProductsData(idString, this);
        }

        let nameGroupClean = nameGroup.replace(' ', '-').toLowerCase()
        let pathname = '/categories/' + nameGroupClean + '/';

        this.props.history.push({
            pathname: pathname
        });

        HeaderRequests.getMetaData(this, pathname);
  
    }

    render() {
        const listCatalogsTitle = this.state.listCatalogsTitle;
        const listProductsTitle = this.state.listProductsTitle;
        const nameGroup = this.state.nameGroup;

        const meta = {
            title: this.state.title,
            description: this.state.metaDescription,
            canonical: this.state.canonical,
            meta: {
                charset: 'utf-8',
                name: {
                    keywords: this.state.keywords
                }
            }
        };

        return (
            <div id="container">
                <Header></Header>
                {<DocumentMeta {...meta}>

                </DocumentMeta>}
                <main>

                    <section className="hero product-list-hero small-padding">
                        <div className="wrapper">
                            <div className="hero-box small-padding side-padding bg-black filter-9">
                                <h2 className="center-align no-margin-top">Attachments</h2>
                                <p>At Jenkins Iron & Steel, we make commercial-grade, heavy-duty attachments to get any job done better and faster. We proudly put our name on everything we build and stand behind our workmanship. Everything we produce is put through gruelling tests before ever making it into production. Being from Minnesota, we understand how important it is to have your equipment work through extreme weather conditions and we build some of the toughest equipment you’ll find on the market.</p>
                                <p>All Jenkins hydraulic attachments come with hoses, cylinders and flat face couplers so you can get hooked up and start working right away.</p>
                            </div>
                        </div>
                    </section>


                    <section className="products bg-white">
                        <div className='product-filters bg-grey'>
                            <div className="wrapper center-align">
                                <div className="product-category">
                                    <span id={'-1'} className="active-underline-group" onClick={() => { this.selectCatalog('-1', 'All') }} > All</span>
                                </div>

                                {listCatalogsTitle.map((itemCatalog, index) => (
                                    <div key={index} className="product-category">
                                        <span id={itemCatalog['id']} className="deactivate_group product-category-item-gtm" onClick={() => { this.selectCatalog(itemCatalog['id'], itemCatalog['name']) }}>{itemCatalog['name']}</span>
                                    </div>
                                ))}
                            </div>
                        </div>

                        <div className="page-title">
                            <div className="page-title-bar bg-charcoal">
                                <img alt="jenkins iron and steel repeating J icon" width="500" height="226" src={require('./img/group_img/jenkins-j-icon.webp')} />
                            </div>
                            <div className="wrapper">
                                <ul className="breadcrumb">
                                    <li><a href="/categories/all" >Products</a></li>
                                    <li>{nameGroup}</li>
                                </ul>
                                <h1>{nameGroup}</h1>
                            </div>
                        </div>


                        <div className='product-list-container'>
                            <div className="product-list-side-image">
                                <div className="side-image-container"></div>
                            </div>

                            <div className="product-listings small-padding side-padding">
                                {listProductsTitle.map((itemProduct, index) => (

                                    <div key={index} onClick={e => this.viewProduct(itemProduct['id'], itemProduct.name, itemProduct.categoryName == null ? "ALL": itemProduct.categoryName )} className="product-preview" >
                                        <div className="product-preview-bg"></div>
                                        <ProductImg alt={itemProduct.name} url={itemProduct.imgUrl} > </ProductImg>
                                        <h4 className="product-preview-title">{itemProduct.name}</h4>
                                    </div>

                                ))}
                            </div>
                        </div>

                    </section>



                    <section className="product-list-footer bg-charcoal small-padding center-align">
                        <div className="bg-charcoal filter filter-8"></div>
                        <div className="wrapper xthin-wrapper">
                            <p>Most Jenkins Iron attachments can be modified to fit a variety of tractor loaders with varying mounting styles such as John Deere, Westendorf or any global/euro model. Please call with your tractor loader model for price and availability.</p>

                            <div className={this.state.logined ? "buttons-wrapper center-buttons" : "display-none"}>
                                <a href="/brochure/" target="_blank" className="button">View our Product Catalog</a>
                            </div>
                        </div>
                    </section>


                </main>
                <Footer></Footer>
            </div>
        );
    }

}
