import UserAuthNetwork from '../network/UserAuthNetwork';
import helperCommon from '../HelperCommon';
import helperCookie from '../HelperCookie';

const LostPasswordRequests = {

   async resetPassord(email, context) {

        try {
            let response = await UserAuthNetwork.resetPassord(email, context);
            if (response) {
                let username = document.getElementById('username').value;
                let rememberMe = helperCookie.getCookie('remember_me');

                if (rememberMe) {
                    let encodeUsername = helperCommon.encodedString(username);
                    helperCookie.setCookie('login', encodeUsername);
                }

                context.setState({
                    sentMessage: true
                });
                return true;
            } else {
                return false;
            }

        } catch (e) {
            console.log("login " + e.message);
        }
        return false;
    },
}

export default LostPasswordRequests;