import React, { Component } from 'react';
import Footer from './Footer';
import { Header } from './Header';

export class AccountComfirmed extends Component {
    static displayName = AccountComfirmed.name;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleSubmit(e) {
        this.props.history.push({
            pathname: '/login',
        });
    }

    render() {

        return (
            <div id="container">
                <Header></Header>
                <main>
                    <section className="account-confirmed medium-padding bg-white">
                        <div className="wrapper xthin-wrapper">
                            <h1 className="center-align">Account Confirmed</h1>


                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                <div className='text-center'>
                                    <span id="sent-email" className='text-center'>
                                        <p>Your account was successfully created!</p>
                                        <p>Please login to continue.</p>
                                    </span>
                                </div>

                                <div id="info-message"></div>

                                <div className="form-field form-submit btn-quote">
                                    <input type="submit" value="LOGIN" />
                                </div>
                            </form>

                        </div>
                    </section>
                </main>
                <Footer></Footer>
            </div>
        );
    }

}


