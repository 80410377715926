import helperSession from '../HelperSession';
import helperCommon from '../HelperCommon';
import baseNetwork from '../network/BaseNetwork';

const UserContactInfoNetworkWork = {

     getAddresses(context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = 'api/Authenticate/GetAddresses';
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async getCountriesAndStates(context) {

        const response = await fetch('data/GetCountriesAndStates');
        response
            .json()
            .then((response) => {
                if (response.status === 'Success') {
                    context.setState({
                        listgetCountriesAndStates: response.body
                    });
                    context.listgetCountriesAndStates = response.body;
                } else {
                    console.log('Request (getCountriesAndStates) failed', response.message);
                    helperCommon.displaySectionMessage('', true);
                    helperCommon.hideLoadingSection();
                }
            })
            .catch((error) => {
                console.log('Request (getCountriesAndStates) failed', error);
                helperCommon.displaySectionMessage('', true);
                helperCommon.hideLoadingSection();
            });
    },

     createAddress(dataJson, context) {

         let dataHaders = {
             'Content-Type': 'application/json',
             'Authorization': 'Bearer ' + helperSession.getToken('token')
         };
        let method = 'post';
        let datalink = 'api/Authenticate/CreateAddress';
        let dataBody = dataJson;

         let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },

     getContact(context) {
        let dataHaders = {
            'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + helperSession.getToken('token')
        }
        let method = 'post';
        let datalink = 'api/Authenticate/GetContact';
        let dataBody = '';

         let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;

    },

    async updateContact(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };

        let method = 'post';
        let datalink = `/api/Authenticate/updateContact`;
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

    },

}
export default UserContactInfoNetworkWork;