import React, { Component } from 'react';
import './css/CartCheckOut.css';
import './css/CartRequestQuote.css';
import helperCart from './HelperCart';
import CartItem from './CartItem';
import { CartTotal } from './CartTotal';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import cartCheckOutRequests from './requests/CartCheckOutRequests';
import CartRequestQuoteCompleteRequests from './requests/CartRequestQuoteCompleteRequests';


export class CartRequestQuoteComplete extends Component {
    static displayName = CartRequestQuoteComplete.name;

    static listgetCountriesAndStates = [];
    static listStatesBilling = [];
    static listStatesShipping = [];

    static idCountryShipping;
    static idCountryBilling;
    static idStateShipping;
    static idStateBilling;
    static quoteId;
    static customerId;


    constructor(props) {
        super(props);
        document.title = "Cart Quote";

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeCoutryShipping = this.onChangeCoutryShipping.bind(this);
        this.onChangeProvinceShipping = this.onChangeProvinceShipping.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);

        this.state = {
            listProductCart: [],
            logined: true,
            listgetCountriesAndStates: [],
            listStatesBilling: [],
            listStatesShipping: [],
            billingStateId: "",
            currentBillingAddress: [],
            currentShippingAddress: [],
            responseQuote: [],
        };

        this.state.listProductCart = helperCart.getCart();
        this.handleSubmit = this.handleSubmit.bind(this);

        this.state = {
            showModalSelectAddresses: false,
            showModalSelectAddressesShipping: false,
        };

        this.sendMessage = this.sendMessage.bind(this);

    }

    componentDidMount() {
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();
        cartCheckOutRequests.getCountriesAndStates(this);


        let url = new URL(window.location.href);
        this.quoteId = url.searchParams.get("QuoteId");
        this.customerId = url.searchParams.get("CustomerId");
        let email = url.searchParams.get("Email");

        this.setState({
            email: email
        });
        CartRequestQuoteCompleteRequests.getOptionsFromQuote(this.quoteId, this.customerId, this);
        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();
    
    }

    componentDidUpdate() {
        helperCart.calculeteTotal();
    }

    sendMessage(message) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message
            }
        });
    }

    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.listStatesBilling = currentCountry.stateViews;
            this.setState({
                listStatesBilling: currentCountry.stateViews
            });
        }
    }

    onChangeCoutryShipping(event) {
        var currentCountryIdPossion = document.getElementById("shipping_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryShipping = currentCountry.id;
            this.listStatesShipping = currentCountry.stateViews;
            this.setState({
                listStatesShipping: currentCountry.stateViews
            });
        }
    }


    onChangeProvinceShipping() {
        var currentStateIdPossion = document.getElementById("shipping_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesShipping[currentStateIdPossion];
            this.idStateShipping = currentState.id;
        }
    }

    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesBilling[currentStateIdPossion];
            this.idStateBilling = currentState.id;
        }
    }


    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        var password = document.getElementById("password");
        var confirmPassword = document.getElementById("confirm_password");
        if (password.value !== confirmPassword.value) {
            validation = false;
            confirmPassword.value = "";
        }
        e.preventDefault();
        return validation;
    }

    handleSubmit(e) {
        let allInputs = e.target;
        helperCommon.displayLoadingSection();
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            if (CartRequestQuoteCompleteRequests.reguestCreateAccount(allInputs, this)) {

       
            } else {
                console.log("checkValidation error:");
                helperCommon.displaySectionMessage('', true);
            }


        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }


    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            [evt.target.name]: value
        });
    }

    render() {
        const listProductCart = this.state.listProductCart;

        const listgetCountriesAndStates = this.state.listgetCountriesAndStates;
        const listStatesBilling = this.state.listStatesBilling;
        const listStatesShipping = this.state.listStatesShipping;

        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="cart-checkout medium-padding bg-light">
                        <div className="wrapper thin-wrapper">
                            <h1 className="center-align">Order Confirmation</h1>
                            <div className="body-message small-padding-bottom">
                                <p>Thank you for placing your order with Jenkins Iron & Steel. Below is your order as well as shipping and billing address.</p>
                            </div>


                            <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                <div className="container-body">

                                    <h3 className="subheading">Addresses</h3>
                    
                                    <div className='address-blocks info-client'>
                                        <div className='address-block'>
                                            <h4>Billing Address</h4>
                                            <div className="account-address">
                                                <div className="form-field">
                                                    <label>Address</label>
                                                    <input required id='billing_adress_street_address' className="check-out-info-client billing-adress" placeholder="Address*" type="text" ref={(billing_adress_street_address) => this.billing_adress_street_address = billing_adress_street_address} />
                                                </div>

                                                <div className='form-field-container form-half-fields'>
                                                    <div className="form-field">
                                                        <label>City</label>
                                                        <input required id='billing_adress_city' className="check-out-info-client billing-adress" placeholder="City*" type="text" ref={(billing_adress_city) => this.billing_adress_city = billing_adress_city} />
                                                    </div>
                                                    <div className="form-field">
                                                        <label>Postal Code</label>
                                                        <input required id='billing_adress_postal_code' className="check-out-info-client billing-adress" placeholder="Postal Code*" type="text" ref={(billing_adress_postal_code) => this.billing_adress_postal_code = billing_adress_postal_code} />
                                                    </div>
                                                </div>

                                                <div className='form-field-container form-half-fields'>
                                                    <div className="form-field select-field has-label">
                                                        <label>Country</label>
                                                        <select id="billing_adress_country" required className="billing-adress" onChange={this.onChangeCoutryBilling} >
                                                            <option value="" >Country*</option>
                                                            {listgetCountriesAndStates && listgetCountriesAndStates.map((item, index) => (
                                                                <option key={index} value={index} >{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>

                                                    <div className="form-field select-field has-label">
                                                        <label>Province/State</label>
                                                        <select id="billing_adress_province" required className="billing-adress" onChange={this.onChangeProvinceBilling}>
                                                            <option value="">Province/State*</option>
                                                            {listStatesBilling && listStatesBilling.map((item, index) => (
                                                                <option key={index} value={index} >{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className='address-block'>
                                            <h4>Shipping Address</h4>
                                            <div className="account-address">
                                                <div className="form-field">
                                                    <label>Address</label>
                                                    <input required id='shipping_adress_street_address' className="check-out-info-client shipping-adress" placeholder="Address*" type="text" ref={(shipping_adress_street_address) => this.shipping_adress_street_address = shipping_adress_street_address} />
                                                </div>

                                                <div className='form-field-container form-half-fields'>
                                                    <div className="form-field">
                                                        <a>City</a>
                                                        <input required id='shipping_adress_city' className="check-out-info-client shipping-adress" placeholder="City*" type="text" ref={(shipping_adress_city) => this.shipping_adress_city = shipping_adress_city} />
                                                    </div>
                                                    <div className="form-field">
                                                        <a>Postal Code</a>
                                                        <input required id='shipping_adress_postal_code' className="check-out-info-client shipping-adress" placeholder="Postal Code*" type="text" ref={(shipping_adress_postal_code) => this.shipping_adress_postal_code = shipping_adress_postal_code} />
                                                    </div>
                                                </div>

                                                <div className='form-field-container form-half-fields'>
                                                    <div className="form-field select-field has-label">
                                                        <label>Country</label>
                                                        <select id="shipping_adress_country" required className="shipping-adress" onChange={this.onChangeCoutryShipping}>
                                                            <option value="">Country*</option>
                                                            {listgetCountriesAndStates && listgetCountriesAndStates.map((item, index) => (
                                                                <option key={index} value={index} >{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-field select-field has-label">
                                                        <label>Province/State</label>
                                                        <select id="shipping_adress_province" required className="shipping-adress" onChange={this.onChangeProvinceShipping}>
                                                            <option value="">Province/State*</option>
                                                            {listStatesShipping && listStatesShipping.map((item, index) => (
                                                                <option key={index} value={index} >{item.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>



                                    <h3 className="subheading">Account Login</h3>

                                    <div className="form-field">
                                        <label>Email</label>
                                        <input id='username' value={this.state.email} className="check-out-info-client" placeholder="Email" type="email" ref={(username) => this.username = username} disabled />
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>

                                    <div className="form-field-container form-half-fields">
                                        <div className="form-field password-field">
                                            <label>Password</label>
                                            <input id='password' required className="check-out-info-client" placeholder="Password" type="password" ref={(password) => this.order_no = password} />
                                            <i class="bi bi-eye-slash" id="togglePassword"></i>
                                            <div className="invalid-feedback">
                                                this field is required
                                            </div>
                                        </div>

                                        <div className="form-field password-field">
                                            <label>Re-Password</label>
                                            <input id='confirm_password' required className="check-out-info-client" placeholder="Re-Password" type="password" ref={(confirm_password) => this.order_no = confirm_password} />
                                            <i class="bi bi-eye-slash" id="toggleRePassword"></i>
                                            <div className="invalid-feedback">
                                                passwords do not match
                                            </div>
                                        </div>
                                    </div>



                                    <h3 className="subheading">Order Information</h3>

                                    <div className="form-field-container form-half-fields">
                                        <div className="form-field">
                                            <label>AP/Billing Email Address</label>
                                            <input id='order_email_address' className="check-out-info-client" placeholder="AP/Billing Email Address" type="text" ref={(order_email_address) => this.order_email_address = order_email_address} />
                                        </div>
                                        <div className="form-field">
                                            <label>PO No.</label>
                                            <input id='order_no' className="check-out-info-client" placeholder="PO No." type="text" ref={(order_no) => this.order_no = order_no} />
                                        </div>
                                    </div>



                                    <h3 className="subheading">Order Summary</h3>

                                    {listProductCart && listProductCart.length > 0 &&
                                        listProductCart.map((item, index) => (
                                            <CartItem key={index} idProduct={item['idProduct']} nameProduct={item['nameProduct']} listOptions={item['listOptions']} quantity={item['quantity']} price={item['price']} total={item['total']}  >

                                            </CartItem>
                                        ))
                                    }



                                    <div className="cart-totals-columns">
                                        <div className="cart-totals-column">
                                            <div className="cart-note-block">
                                                <div className="cart-note-section cart-lead-time">
                                                    <h4>Current lead time on attachments<span>1 Week & 1 Day</span></h4>
                                                </div>

                                                <div className="cart-note-section cart-note">
                                                    <p>Your order will remain in your cart until you ﬁnalize or make changes to it. Once you finalize your order, you will receive confirmation from our sales staff. Full invoices will be available in Your Account shortly after initializing.</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cart-totals-column">
                                            <CartTotal> </CartTotal>
                                        </div>
                                    </div>

                     

                                    <h3 className="subheading">Additional Comments</h3>

                                    <div className="form-field">
                                        <textarea id='add_comment' cols="40" rows="5" placeholder="Add comment..." type="text" name="Add comment" ref={(add_comment) => this.add_comment = add_comment} ></textarea>
                                    </div>

                                    <div className="form-disclaimer" >
                                        By placing this order, I confirm that all information above is accurate, and I am responsible for any incorrect information
                                    </div>


                                    <div className='btn-quote cart-checkout xs-padding-top'>
                                        <div className="form-field form-submit">
                                            <input type="submit" value="PLACE ORDER" />
                                        </div>

                                        <div className="center-align">
                                            <div id='show-loading' className="spinner-grow text-red" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>

                                        <span id="info-message" className="" > </span>
                                    </div>

                                </div>
                            </form>

                        </div>
                    </section>
                </main>

                <Footer></Footer>
            </div>
        );
    }
}