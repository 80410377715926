import UserContactInfoNetwork from '../network/UserContactInfoNetwork';
import UserShoppingNetwork from '../network/UserShoppingNetwork';
import helperSession from '../HelperSession';


const CartCheckOutRequests = {

    async getAddresses(context) {

        try {
            let response = await UserContactInfoNetwork.getAddresses(context);

            let shipping = response.body.find(a => a.shipping);
            let billing = response.body.find(a => a.billing);

            context.setState({
                listClientAddressesBilling: response.body,
                listClientAddressesShipping: response.body,
                currentBillingAddress: billing,
                currentShippingAddress: shipping
            });
            return true;

        } catch (e) {
            console.log("getAddresses " + e.message);
        }
        return false;
    },

     getCountriesAndStates(context) {
        UserContactInfoNetwork.getCountriesAndStates(context);
    },

    createAddress(dataJson, context) {
        UserContactInfoNetwork.createAddress(dataJson, context);
    },

    async reguestCreateOrder(dataJson, context) {

        try {
            let response = await UserShoppingNetwork.reguestCreateOrder(dataJson, context);
            if (response) {
                helperSession.cleanCart();
                context.sendMessage("Thank You for your order. One of our representatives will reach out shortly to confirm all details and payment information.");
                return true;
            } else {
                return false;
            }
        } catch (e) {
            console.log("reguestCreateQuote " + e.message);
        }
        return false;
    }
}

export default CartCheckOutRequests;