import React, { Component } from 'react';
import './css/CartRequestQuote.css';
import helperCart from './HelperCart';
import CartItem from './CartItem';
import Footer from './Footer';
import { Header } from './Header';
import { CartTotal } from './CartTotal';
import { CartIsEmpty } from './CartIsEmpty';
import helperCommon from './HelperCommon';
import helperSession from './HelperSession';
import CartRequestQuoteRequests from './requests/CartRequestQuoteRequests';


export class CartRequestQuote extends Component {
    static displayName = CartRequestQuote.name;
    static listProductCart;
    static listgetCountriesAndStates = [];
    static listStatesBilling = [];
    static idCountryBilling;
    static idStateBilling;

    constructor(props) {
        super(props);
        document.title = "Cart Quote";

        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);
        this.sendMessage = this.sendMessage.bind(this);

        this.state = {
            listProductCart: [], total: 0, listTaxes: [], logined: false, listgetCountriesAndStates: [], listStatesBilling: [], valitationsBefore: true
        };
        this.state.listProductCart = helperCart.getCart();
    }

    componentDidMount() {
        CartRequestQuoteRequests.getCountriesAndStates(this);
        helperCommon.hideSectionErrorMessage();
        this.setState({
            logined: helperSession.getLogined()
        });
        helperCommon.hideLoadingSection();

        document.querySelectorAll("[required]").forEach(item => {
            item.addEventListener('change', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
            item.addEventListener('keyup', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
        })
    }

    checValitationsBeforeActionOnClickButton() {
        let checked = document.querySelectorAll("[required]");
        let arrSelected = [...checked].map(option => option.value);

        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        let checkMail = regex.test(arrSelected[2]);

        let selected = arrSelected.filter(e => e !== '');
        this.setState({
            valitationsBefore: selected.length === checked.length && checkMail ? false : true
        });
    }

    sendMessage(message) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message
            }
        });
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    openCheckOut() {
        window.location.replace("/cart/check-out");
    }

    handleSubmit(e) {
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();
        helperCommon.displayLoadingSection();

        if (this.checkValidation(e)) {
            if (this.state.logined) {
                this.openCheckOut();
            } else {
                const email = document.getElementById("email").value;
                if (email) {
                    CartRequestQuoteRequests.checkValidateEmail(email, allInputs, this);
                }
            }

        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage("Please enter all the correct fields", true);
            helperCommon.hideLoadingSection();
        }
        e.preventDefault();
    }

    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing-adress-country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.listStatesBilling = currentCountry.stateViews;
            this.setState({
                listStatesBilling: currentCountry.stateViews
            });
        }
    }

    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing-adress-province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesBilling[currentStateIdPossion];
            this.idStateBilling = currentState.id;
        }
    }

    render() {
        const listProductCart = this.state.listProductCart;
        const listgetCountriesAndStates = this.state.listgetCountriesAndStates;
        const listStatesBilling = this.state.listStatesBilling;


        if (listProductCart && listProductCart.length > 0) {
            return (

                <div id="container">
                    <Header></Header>

                    <main className="bg-light">

                        <section className="your-cart medium-padding">
                            <div className="wrapper thin-wrapper">
                                <h1 className="center-align">Your Cart</h1>


                                <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >

                                    {listProductCart &&
                                        listProductCart.map((item, index) => (
                                            <CartItem key={index} idProduct={item['idProduct']} nameProduct={item['nameProduct']} listOptions={item['listOptions']} quantity={item['quantity']} price={this.state.logined ? "$" + item['price'] : ""} total={this.state.logined ? "$" + item['total'] : ""}  >

                                            </CartItem>
                                        ))
                                    }

                                    {!this.state.logined ?

                                        <div className="cart-customer-info info-client">
                                            <div className="form-field col-info-client">
                                                <input id="contact_name" required placeholder="Name*" type="text" ref={(contact_name) => this.contact_name = contact_name} autoComplete="none"/>
                                                <div className="invalid-feedback">
                                                    this field is required
                                                </div>
                                            </div>

                                            <div className='form-field-container form-half-fields'>
                                                <div className="form-field col-info-client">
                                                    <input id="email" required placeholder="Email*" type="email" ref={(email) => this.email = email} autoComplete="none"/>
                                                    <div className="invalid-feedback">
                                                        this field is required
                                                    </div>
                                                </div>

                                                <div className="form-field col-info-client">
                                                    <input id="phone" placeholder="Phone" type="text" ref={(phone) => this.phone = phone} autoComplete="none"/>
                                                    <div className="invalid-feedback">
                                                        this field is required
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='form-field-container form-half-fields'>
                                                <div className="form-field select-field">
                                                    <select id="billing-adress-country" required className="billing-adress" onChange={this.onChangeCoutryBilling} autoComplete="none">
                                                        <option value="" >Country*</option>
                                                        {listgetCountriesAndStates.map((item, index) => (
                                                            <option key={index} value={index} >{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        this field is required
                                                    </div>
                                                </div>

                                                <div className="form-field select-field">
                                                    <select id="billing-adress-province" required className="billing-adress" onChange={this.onChangeProvinceBilling} autoComplete="none">
                                                        <option value="">Province/State*</option>
                                                        {listStatesBilling.map((item, index) => (
                                                            <option key={index} value={index} >{item.name}</option>
                                                        ))}
                                                    </select>
                                                    <div className="invalid-feedback">
                                                        this field is required
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="form-field col-info-client">
                                                <textarea  id="description" placeholder="Additional Comments" type="text"></textarea>

                                            </div>
                                        </div>

                                    : null}


                                    <div className="cart-totals-columns">
                                        <div className="cart-totals-column">
                                            <div className="cart-note-block">
                                                <div className="cart-note-section cart-lead-time">
                                                    <h4>Current lead time on attachments<span>Call for our current lead times</span></h4>
                                                </div>

                                                <div className="cart-note-section cart-note">
                                                    <p>Lead times are estimated and are a current average. Some products may ship out earlier while others could take longer as lead times are tied to complexity of the product as well as current stock. For more accurate lead times, please reach out to our sales team at sales@jenkinsiron.com or 1-855-732-3843</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="cart-totals-column">
                                            <CartTotal> </CartTotal>

                                            <div className='cart-checkout xs-padding-top'>
                                                <div className="invalid-feedback">
                                                    You must agree before submitting.
                                                </div>

                                                <div className="form-field form-submit form-submit-right">
                                                    <input type="submit" className={this.state.logined ? 'cart-check-out-gtm' : this.state.valitationsBefore === true ? "" : 'cart-request-quote-gtm'} value={this.state.logined ? 'REQUEST A QUOTE' : 'CHECK OUT'} />
                                                </div>

                                                <div className="center-align">
                                                    <div id='show-loading' className="spinner-grow text-red" role="status">
                                                        <span className="sr-only"></span>
                                                    </div>
                                                </div>

                                                <span id="info-message" className="" > </span>

                                            </div>
                                        </div>
                                    </div>

                                </form>
                            </div>
                        </section>
                    </main>

                    <Footer></Footer>
                </div>
            );
        } else {
            return (
                <div id="container">
                    <Header></Header>

                    <main>
                        <CartIsEmpty>

                        </CartIsEmpty>
                    </main>

                    <Footer></Footer>
                </div>
            );
        }

    }

}