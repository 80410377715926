
import helperCommon from '../HelperCommon';

const BaseNetwork = {

     baseFetch(dataBody, datalink, dataMetod, dataHaders, context) {

        var header = '';
        if (dataBody) {
             header = {
                method: dataMetod,
                headers: dataHaders,
                body: dataBody
            }
        } else {
             header = {
                method: dataMetod,
                headers: dataHaders
            }
        }

        return fetch(datalink, header)

            .then(function (response) {
                if (response.status !== 200) {
                    helperCommon.displaySectionMessage(response.statusText, true);
                    if (response.status === 401) {
                        window.location.replace("/login");
                    }
                }
                return response.json();
            })
            .then(function (result) {
                if (result) {
                    if (result.status === 'Success') {
                        helperCommon.hideLoadingSection();
                    } else if (result.status === 'Error') {
                        console.log('Request failed (' + datalink + ')', result.message);
                        helperCommon.displaySectionMessage(result.message, true);
                    }
                    else {
                        console.log('Request failed (' + datalink + ')', JSON.stringify(result.errors));
                        helperCommon.displaySectionMessage('', true);
                    }

                } else {
                    console.log('Request failed (' + datalink + ')', result);
                    helperCommon.displaySectionMessage('', true);
                }
                helperCommon.hideLoadingSection();
                return result;

            })
            .catch(function (error) {
                console.log('Request failed (' + datalink + ')', JSON.stringify(error));
                helperCommon.displaySectionMessage('', true);
                helperCommon.hideLoadingSection();
            });
   
    },
}
export default BaseNetwork;
