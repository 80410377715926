import helperSession from '../HelperSession';
import helperCommon from '../HelperCommon';
import baseNetwork from '../network/BaseNetwork';

const UserAccountNetwork = {


    async createAccount(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };

        let method = 'post';
        let datalink = `api/Authenticate/register`;
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

    },
    async updateAccount(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };

        let method = 'post';
        let datalink = `/api/Authenticate/UpdateClient`; 
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

    },


    async getClientAccount(context) {
        await fetch('/data/GetClientAccount', {
            method: 'post',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': 'Bearer ' + helperSession.getToken('token')
            },
        })
            .then(function (response) {
                if (response.status !== 200) {
                    helperCommon.displaySectionMessage(response.statusText, true);
                    if (response.status === 401) {
                        window.location.replace("/login");
                    }
                }
                return response.json();
            })
            .then(function (result) {
                if (result) {
                    if (result.status === 'Success') {
                        var positionBillingCountry = context.getPositionCoutry(result.body.billing.countryId);
                        var positionBillingState = context.getPositionStateBilling(result.body.billing.stateId, positionBillingCountry);

                        var positionShippingCountry = context.getPositionCoutry(result.body.shipping.countryId);
                        var positionShippingState = context.getPositionStateShipping(result.body.shipping.stateId, positionShippingCountry);

                        context.idCountryBilling = result.body.billing.countryId;
                        context.idStateBilling = result.body.billing.stateId;

                        context.idCountryShipping = result.body.shipping.countryId;
                        context.idStateShipping = result.body.shipping.stateId;


                        context.setState({
                            companyName: result.body.account.companyName,
                            contactName: result.body.account.contactName,
                            accountEmail: result.body.account.email,
                            accountPhone: result.body.account.phone,
                            apiOrders: result.body.account.apiOrders,
                            dataOrders: result.body.account.apiOrders,

                            billingStreet: result.body.billing.street,
                            billingCity: result.body.billing.city,
                            billingStateId: positionBillingState,
                            billingCountryId: positionBillingCountry,
                            billingPostCode: result.body.billing.postCode,

                            shippingStreet: result.body.shipping.street,
                            shippingCity: result.body.shipping.city,
                            shippingStateId: positionShippingState,
                            shippingCountryId: positionShippingCountry,
                            shippingPostCode: result.body.shipping.postCode,

                            username: result.body.username,

                        });

                    } else if (result.status === 'Error') {
                        console.log('Request failed (getClientAccount)', result.message);
                        helperCommon.displaySectionMessage(result.message, true);
                    }
                    else {
                        console.log('Request failed (getClientAccount)', JSON.stringify(result.errors));
                        helperCommon.displaySectionMessage('', true);
                    }

                } else {
                    console.log('Request failed (getClientAccount)', result);
                    helperCommon.displaySectionMessage('', true);
                }

            })
            .catch(function (error) {
                console.log('Request failed (getClientAccount)', JSON.stringify(error));
                helperCommon.displaySectionMessage('', true);
            });
    },

    async setBillingAddress(dataJson, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = `/api/Authenticate/SetBillingAddress`;
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

        
    },

    async setShippingAddress(dataJson, context) {
        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = `/api/Authenticate/SetShippingAddress`;
        let dataBody = dataJson;

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);
        return response;

    },

     getClient(context) {
        let dataHaders = {
            'Content-Type': 'application/json',
            'Authorization': 'Bearer ' + helperSession.getToken('token')
        };
        let method = 'post';
        let datalink = 'api/Authenticate/GetClient';
        let dataBody = '';

         let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },

    async GetProductsByClientGroupForBrochure(id_client, context) {

        let dataHaders = {
            'Content-Type': 'application/json',
        };
        let method = 'post';
        let datalink = `data/GetProductsByClientGroupForBrochure?clientGroupId=${id_client}`;
        let dataBody = '';

        let response = baseNetwork.baseFetch(dataBody, datalink, method, dataHaders, context);

        return response;
    },
    
}

export default UserAccountNetwork;