import UserAuthNetwork from '../network/UserAuthNetwork';
import helperSession from '../HelperSession';
import helperCommon from '../HelperCommon';
import helperCookie from '../HelperCookie';

const LoginRequests = {
        async login(dataJson, context) {

            try {
                let response = await UserAuthNetwork.login(dataJson, context);

                if (response.token) {
                    helperSession.setToken('token', response.token, response.expiration);

                    let username = document.getElementById('username').value;
                    let password = document.getElementById('password').value;
                    let rememberMe = document.getElementById('remember-me');
                    if (rememberMe.checked) {
                        let encodeLogin = helperCommon.encodedString(username);
                        let encodePass = helperCommon.encodedString(password);

                        helperCookie.setCookie('login', encodeLogin, helperSession.getSessionTimeHours());
                        helperCookie.setCookie('password', encodePass, helperSession.getSessionTimeHours());
                        helperCookie.setCookie('remember_me', rememberMe.checked, helperSession.getSessionTimeHours());
                    }
                    window.location.replace("/");

                } else {
                    console.log('Request failed (resetPassword)', response.message);
                    helperCommon.displaySectionMessage(response.message, true);
                }
            } catch (e) {
                console.log("login " + e.message);
            }

    },
}

export default LoginRequests;