 export class Address {
    constructor(id, countryId, stateId, city, street, street2,unit, postCode) {
        this.id = id;
        this.countryId = countryId;
        this.stateId = stateId;
        this.city = city;
        this.street = street;
        this.street2 = street2;
        this.unit = unit;
        this.postCode = postCode;
    }
}