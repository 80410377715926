import CommonNetwork from '../network/CommonNetwork';
import helperCommon from '../HelperCommon';

const HeaderRequests = {
    async getMetaData(context, pathname) {
        try {
            let response = await CommonNetwork.getMetaData(context, pathname);
            if (response.body) {
                context.setState({
                    title: response.body.title,
                    metaDescription: response.body.description,
                    canonical: response.body.canonical,
                    keywords: response.body.keywords,
                });
            } else {
                helperCommon.hideSectionErrorMessage();
                let arrayLink = window.location.href.split("/");
                if (arrayLink) {
                    let lastWordUrl = arrayLink[arrayLink.length - 2];
                    let url = window.location.href;
                    context.setState({
                        title: lastWordUrl,
                        metaDescription: lastWordUrl,
                        canonical: url,
                        keywords: lastWordUrl,
                    });
                } else {
                    console.log('arrayLink failed');
                }
            }
            return true;

        } catch (e) {
            console.log("getMetaData " + e.message);
        }
        return false;
    },

    getMetaDataByProductSyng(context, id_product) {

        return fetch(`data/GetMetaDataByProduct?productId=${id_product}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }
        })
            .then(response => response.json())
            .then(data => {
                if (data.body) {
                    context.setState({
                        title: data.body.metaTitle,
                        metaDescription: data.body.metaDescription,
                        canonical: data.body.canonical,
                    });
                }
            });
    }
}

export default HeaderRequests;