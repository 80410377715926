import React, { Component } from 'react';
import './css/Home.css';
import Footer from './Footer';
import { Header } from './Header';
import helperCommon from './HelperCommon';
import { MassageContactForm } from './models/MassageContactForm';
import HomeRequests from './requests/HomeRequests';

export class Home extends Component {
    static displayName = Home.name;

    static idCountryBilling;
    static nameCountryBilling;
    static idStateBilling;
    static nameStateBilling;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);

        this.state = {
            listgetCountriesAndStates: [],
            listStatesBilling: [],
            valitationsBefore: true
        }
    }

    sendMessage(message, phone) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message,
                phone: phone,
            }
        });
    }

    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            this.createMessage(this);
        } else {
            console.log("handleSubmit error:");
        }
        e.preventDefault();
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }


    createMessage(currentInput) {
        try {
            let massageContactForm = new MassageContactForm(0,
                currentInput.name.value,
                currentInput.email.value,
                currentInput.phone.value,
                currentInput.message.value,
                this.idCountryBilling,
                this.nameCountryBilling,
                this.idStateBilling,
                this.nameStateBilling
            );

            let json = JSON.stringify(massageContactForm);
            HomeRequests.sendMessageEmail(json, this);
        } catch (e) {
            console.log("createUser " + e.message);
        }
    }

    componentDidMount() {
        HomeRequests.getCountriesAndStates(this);
        helperCommon.hideSectionErrorMessage();
        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();


        document.querySelectorAll("[required]").forEach(item => {
            item.addEventListener('change', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
            item.addEventListener('keyup', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
        })
    }

    checValitationsBeforeActionOnClickButton() {
        let checked = document.querySelectorAll("[required]");
        let arrSelected = [...checked].map(option => option.value);

        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        let checkMail = regex.test(arrSelected[2]);

        let selected = arrSelected.filter(e => e !== '');
        this.setState({
            valitationsBefore: selected.length === checked.length && checkMail ? false : true
        });
    }

    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.nameCountryBilling = currentCountry.name;
            this.idCountryBilling = currentCountry.id;
            this.listStatesBilling = currentCountry.stateViews;
            this.setState({
                listStatesBilling: currentCountry.stateViews
            });
        }
    }
    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesBilling[currentStateIdPossion];
            this.nameStateBilling = currentState.name;
            this.idStateBilling = currentState.id;
        }
    }

    render() {
        const listgetCountriesAndStates = this.state.listgetCountriesAndStates;
        const listStatesBilling = this.state.listStatesBilling;

        return (
            <div id="container">
                <Header></Header>
                <main>
                    <input type="hidden" id="anPageName" name="page" value="homepage-tablet" />


                    <section className="home-hero medium-padding ">
                        <div className="home-hero-content-block">
                            <h1>American Made, Commercial Grade Skid Steer Attachments.</h1>
                            <p>Jenkins Iron & Steel is a family owned and operated skid steer attachment manufacturer. We build the most durable and affordable attachments while delivering professional, knowledgeable, and exceptional customer service that only a family-run manufacturer can provide.</p>
                            <div className="highlight-container">
                                <h2 className="highlight highlight-red">We believe in our products and stand behind our work 100%.</h2>
                            </div>
                        </div>
                    </section>


                    <section className="half-columns bg-black">
                        <div className="half-column column-image-bg column-left home-who-we-are-bg ">
                        </div>
                        <div className="half-column column-text column-right small-padding side-padding">
                            <h2>Who We Are</h2>
                            <p>Jenkins Iron & Steel is a small family owned and operated manufacturer of a variety of implement attachments located in Long Prairie, MN. The Jenkins family founded the company with one goal in mind; to put out the highest quality, most durable attachments on the market with prices that everyone can afford. This goal is achieved by utilizing state-of-the-art manufacturing techniques, top quality American steel and exceptional quality control through the oversight of every attachment, not only in the design phase, but also in every aspect of production. Simply put, if it has the Jenkins name on it, you can count on it to work, day in and day out, through whatever you can throw at it.</p>
                            <div className="buttons-wrapper title-buttons xs-padding-top">
                                <a href='/about-us' className="button button-bg-white button-text-black learn-about-jenkins-gtm">Learn More About Jenkins Iron &amp; Steel</a>
                            </div>
                        </div>
                    </section>


                    <section className="half-columns bg-grey">
                        <div className="half-column column-image-bg column-right home-attachments-bg ">
                        </div>
                        <div className="half-column column-content column-left small-padding side-padding">
                            <h2>Attachments</h2>
                            <p>We stand behind everything we build. If you do have an issue with anything on your attachment you can call our office and speak to a Jenkins who knows the attachments, puts their name on the attachments and takes care of the customers who buy their attachments.</p>
                            <p>Our attachments are rigorously tested and abused prior to production so you know you are getting the best and most durable attachments possible! We guarantee that when you buy a Jenkins attachment you are not only getting the attachment, but also a level of service that only a family company, who cares deeply about their reputation, can provide!</p>
                            <div className="buttons-wrapper title-buttons xs-padding-top">
                                <a href='/categories/all' className="button button-bg-black button-text-white browse-attachments-gtm">Browse Our Attachments</a>
                            </div>
                        </div>
                    </section>


                    <section className="why-choose-us medium-padding bg-white">
                        <div className="wrapper">
                            <h2 className="center-align">Why Choose Us</h2>
                            <div className="home-choice-blocks">
                                <div className="home-why-block">
                                    <img alt="jenkins" width="301" height="300" src={require('./img/group_img/icon-heart.webp')} />
                                    <div className="home-why-text">
                                        <div className="highlight-container">
                                            <h3 className="highlight">Our Passion</h3>
                                        </div>
                                        <p>We strive to build the best attachments with USA made components. We build everything in house and to our stringent standards, to ensure you are getting the most quality attachment at the best possible price.</p>
                                    </div>
                                </div>

                                <div className="home-why-block">
                                    <img alt="jenkins" width="301" height="300" className="path-554-xRzIwx" src={require('./img/group_img/icon-hand.webp')} />
                                    <div className="home-why-text">
                                        <div className="highlight-container">
                                            <h3 className="highlight">Our Values</h3>
                                        </div>
                                        <p>When we say we are a family manufacturer we mean it. Our company was founded by Marty Jenkins, his wife Karen, and their three sons Kurt, Patrick and Eric. We all live within a few miles of each other and work side by side every day. We have grown our company with our family values and strive to produce the best American made attachments available. We take pride in everything that we build and you can rest assured you are getting the best possible attachment for your hard earned money.</p>
                                    </div>
                                </div>

                                <div className="home-why-block">
                                    <img alt="jenkins" width="301" height="300" className="path-559-z2B8HY" src={require('./img/group_img/icon-gear.webp')} />
                                    <div className="home-why-text">
                                        <div className="highlight-container">
                                            <h3 className="highlight">Our Mission</h3>
                                        </div>
                                        <p>Our mission is simple; to provide the highest quality, most durable attachments on the market for prices you can afford, all backed by a name you can depend on.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>


                    <section className="half-columns bg-black">
                        <div className="half-column column-right column-content small-padding side-padding">
                            <form onSubmit={this.handleSubmit} className="needs-validation" noValidate >
                                <h2 className="center-align">Get In Touch</h2>
                                <div className="form-field">
                                    <input required placeholder="Name" ref={(name) => this.name = name} type="text" autoComplete="none"/>
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>
                                <div className="form-field-container form-half-fields">
                                    <div className="form-field">
                                        <input required placeholder="Phone" type="text" ref={(phone) => this.phone = phone} autoComplete="none"/>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                    <div className="form-field">
                                        <input required placeholder="Email" type="email" ref={(email) => this.email = email} autoComplete="none"/>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                </div>
                                <div className="form-field-container form-half-fields">
                                    <div className="form-field select-field">
                                        <select id="billing_adress_country" required className="billing-adress" onChange={this.onChangeCoutryBilling} autoComplete="none" >
                                            <option value=""  >Country*</option>
                                            {listgetCountriesAndStates.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>
                                    <div className="form-field select-field">
                                        <select id="billing_adress_province" required className="billing-adress" onChange={this.onChangeProvinceBilling} autoComplete="none">
                                            <option value=""  >Province/State*</option>
                                            {listStatesBilling.map((item, index) => (
                                                <option key={index} value={index} >{item.name}</option>
                                            ))}
                                        </select>
                                        <div className="invalid-feedback">
                                            this field is required
                                        </div>
                                    </div>

                                </div>
                                <div className="form-field">
                                    <textarea required placeholder="Send us a message..." type="text" ref={(message) => this.message = message}></textarea>
                                    <div className="invalid-feedback">
                                        this field is required
                                    </div>
                                </div>
                                <div id="info-message"></div>
                                <div className="form-field form-submit form-submit-right">
                                    <input type="submit" className={this.state.valitationsBefore === true ? "" : "get-in-touch-send-gtm"} value="Send Away" />
                                </div>
                            </form>
                        </div>
                        <div className="half-column column-left column-image-bg home-map-bg">
                            <div className="home-map-container">
                                <iframe className="snazzy-map map-gtm" src="https://snazzymaps.com/embed/342191" title="map" ></iframe>
                            </div>
                        </div>
                    </section>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}
