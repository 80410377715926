 export class AccountBody {
     constructor(username, email, password, account, billing, shipping, quoteId, customerId) {
        this.username = username;
        this.email = email;
        this.password = password;
        this.account = account;
        this.billing = billing;
        this.shipping = shipping;
        this.quoteId = quoteId;
        this.customerId = customerId;
    }
}