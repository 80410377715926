import UserContactInfoNetwork from '../network/UserContactInfoNetwork';
import UserShoppingNetwork from '../network/UserShoppingNetwork';
import helperSession from '../HelperSession';
import UserShioppingRepositories from '../repositories/UserShioppingRepositories';


const CartRequestQuoteRequests = {

    getCountriesAndStates(context) {
        UserContactInfoNetwork.getCountriesAndStates(context);
    },

    async reguestCreateQuote(dataJson, context) {

        try {
            let response = await UserShoppingNetwork.reguestCreateQuote(dataJson, context);
            if (response) {
                helperSession.cleanCart();
                context.sendMessage("Thank you for your quote. It has been placed, and you will receive a confirmation email shortly. Full invoices for all your orders can be viewed in Your Account. 1-855-732-3843");
            }
            return true;

        } catch (e) {
            console.log("reguestCreateQuote " + e.message);
        }
        return false;
    },

    async checkValidateEmail(email, allInputs, context) {
        try {
               let json = UserShioppingRepositories.createQuoteBody(allInputs, context);
               this.reguestCreateQuote(json, context);
            return true;

        } catch (e) {
            console.log("checkValidateEmail " + e.message);
        }
        return false;
    },
}

export default CartRequestQuoteRequests;