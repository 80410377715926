import React, { Component } from 'react';
import './css/ContactUs.css';
import { Header } from './Header';
import Footer from './Footer';
import helperCommon from './HelperCommon';
import { MassageContactForm } from './models/MassageContactForm';

import ContactUsreRequests from './requests/ContactUsRequests';


export class ContactUs extends Component {
    static displayName = ContactUs.name;

    static idCountryBilling;
    static nameCountryBilling;
    static idStateBilling;
    static nameStateBilling;

    constructor(props) {
        super(props);
        this.handleSubmit = this.handleSubmit.bind(this);

        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);

        this.state = {
            listgetCountriesAndStates: [],
            listStatesBilling: [],
            valitationsBefore: true
        }
    }

    sendMessage(message, phone) {
        this.props.history.push({
            pathname: '/cart/thank-you',
            state: {
                message: message,
                phone: phone,
            }
        });
    }


    handleSubmit(e) {
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            this.createMessage(this);
        } else {
            console.log("handleSubmit error:");
        }
        e.preventDefault();
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    componentDidMount() {
        ContactUsreRequests.getCountriesAndStates(this);
        helperCommon.hideSectionErrorMessage();
        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();

        document.querySelectorAll("[required]").forEach(item => {
            item.addEventListener('change', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
            item.addEventListener('keyup', event => {
                this.checValitationsBeforeActionOnClickButton();
            })
        })
    }


    checValitationsBeforeActionOnClickButton() {
        let checked = document.querySelectorAll("[required]");
        let arrSelected = [...checked].map(option => option.value);

        let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
        let checkMail = regex.test(arrSelected[2]);

        let selected = arrSelected.filter(e => e !== '');
        this.setState({
            valitationsBefore: selected.length === checked.length && checkMail ? false : true
        });
    }

    createMessage(currentInput) {
        try {
            let massageContactForm = new MassageContactForm(0,
                currentInput.name.value,
                currentInput.email.value,
                currentInput.phone.value,
                currentInput.message.value,
                this.idCountryBilling,
                this.nameCountryBilling,
                this.idStateBilling,
                this.nameStateBilling
            );

            let json = JSON.stringify(massageContactForm);
            ContactUsreRequests.sendMessageEmail(json, this);
        } catch (e) {
            console.log("createUser " + e.message);
        }
    }


    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.nameCountryBilling = currentCountry.name;
            this.listStatesBilling = currentCountry.stateViews;
            this.setState({
                listStatesBilling: currentCountry.stateViews
            });
        }
    }
    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesBilling[currentStateIdPossion];
            this.idStateBilling = currentState.id;
            this.nameStateBilling = currentState.name;
        }
    }


    render() {
        const listgetCountriesAndStates = this.state.listgetCountriesAndStates;
        const listStatesBilling = this.state.listStatesBilling;

        return (
            <div id="container">
                <Header></Header>

                <main>
                    <section className="contact-banner medium-padding bg-black">
                        <div className="wrapper">
                            <h2 className="small-padding-bottom center-align">Get in Touch</h2>

                            <div className="contact-columns">
                                <div className="contact-column contact-options">
                                    <div className="contact-option contact-address">
                                        <svg className="contact-icon icon-phone" x="0px" y="0px" viewBox="0 0 50 50" width="50" height="50" preserveAspectRatio="xMidYMid meet">
                                            <path d="M43.2,15.1C41.3,6.7,35.3,0,25,0S8.7,6.7,6.8,15.1C4.6,25,13.9,37.2,25,50C36.1,37.2,45.4,25,43.2,15.1z M25,27.8 c-5.7,0-10.2-4.6-10.2-10.2S19.3,7.3,25,7.3s10.2,4.6,10.2,10.2S30.7,27.8,25,27.8z" />
                                        </svg>
                                        <div className="contact-option-text">
                                            <strong>Office/Mailing Address</strong><br />
                                            <span>23023 US 71<br />Long Prairie, MN 56347</span>
                                        </div>
                                    </div>

                                    <div className="contact-option mfg-address">
                                        <svg className="contact-icon icon-phone" x="0px" y="0px" viewBox="0 0 50 50" width="50" height="50" preserveAspectRatio="xMidYMid meet">
                                            <path d="M43.2,15.1C41.3,6.7,35.3,0,25,0S8.7,6.7,6.8,15.1C4.6,25,13.9,37.2,25,50C36.1,37.2,45.4,25,43.2,15.1z M25,27.8 c-5.7,0-10.2-4.6-10.2-10.2S19.3,7.3,25,7.3s10.2,4.6,10.2,10.2S30.7,27.8,25,27.8z" />
                                        </svg>
                                        <div className="contact-option-text">
                                            <strong>Manufacturing Plant</strong><br />
                                            <em>inbound and outbound trucking</em><br />
                                            <span>22401 County 36<br />Long Prairie, MN 56347</span>
                                        </div>
                                    </div>

                                    <div className="contact-option contact-phone">
                                        <svg className="contact-icon icon-phone" x="0px" y="0px" viewBox="0 0 50 50" width="50" height="50" preserveAspectRatio="xMidYMid meet">
                                            <path d="M0,12C0.1,8.3,1.5,4.8,4.1,2c1.1-1,2.5-1.6,4-1.8 C8.8,0,9.5,0,10.2,0c0.7-0.1,1.5,0.2,1.8,0.9c0.8,1.5,1.6,2.9,2.5,4.4c0.9,1.6,1.9,3.2,2.8,4.7c0.2,0.3,0.4,0.6,0.6,1 c0.4,0.7,0.3,1.5-0.2,2.1c-0.8,0.9-1.8,1.7-2.7,2.6c-0.9,0.8-1.8,1.6-2.7,2.4c-0.7,0.5-0.8,1.5-0.4,2.2c1.4,2.3,2.7,4.7,4.3,6.9 c2.9,3.9,6.5,7.1,10.7,9.3c0.9,0.5,1.8,1,2.8,1.5c0.6,0.4,1.5,0.3,2-0.3c0.8-0.8,1.6-1.7,2.3-2.6c0.7-0.8,1.4-1.6,2.1-2.4 c1.2-1.2,2-1.2,3.4-0.4c2.2,1.3,4.4,2.6,6.7,3.9c0.8,0.5,1.6,0.9,2.4,1.3c1,0.4,1.6,1.5,1.2,2.6c-0.2,1.2-0.5,2.5-0.9,3.7 c-0.4,1.1-1.1,2-2.1,2.6c-2.6,2.1-5.9,3.3-9.2,3.4c-1.6,0-3.1-0.3-4.6-0.9c-4.2-1.5-8.4-2.9-12.1-5.5c-3.3-2.3-6.3-5-9.1-7.9 c-2.8-2.8-5.1-5.9-7.1-9.2c-2-3.8-3.6-7.9-4.6-12.1C0,13.8,0,13.4-0.1,13C0,12.8,0,12.5,0,12z"/>
                                        </svg>
                                        <div className="contact-option-text">
                                            <a href="tel:18557323843" className="phone-link">1.855.732.3843</a>
                                        </div>
                                    </div>

                                    <div className="contact-option contact-hours">
                                        <svg className="contact-icon icon-clock" x="0px" y="0px" viewBox="0 0 50 50" width="50" height="50" preserveAspectRatio="xMidYMid meet">
                                            <path d="M23.5,0h3.1c0.1,0,0.3,0.1,0.4,0.1c1.6,0.3,3.2,0.4,4.7,0.8C45,4.6,52.8,18.3,49.1,31.6	c-2.9,10.7-12.5,18.1-23.6,18.3c-4.7,0.1-9.3-1.1-13.3-3.5C5.9,42.7,1.6,36.5,0.4,29.3c-0.2-0.9-0.3-1.9-0.4-2.8v-3.1 c0-0.1,0.1-0.3,0.1-0.4c0.2-3.8,1.4-7.5,3.4-10.8c3.7-6.3,10-10.7,17.2-11.9C21.6,0.2,22.6,0.1,23.5,0z M25,3.8 c-11.7,0-21.2,9.5-21.2,21.1c0,11.7,9.4,21.2,21.1,21.2c11.7,0,21.2-9.4,21.2-21.1C46.2,13.3,36.8,3.8,25,3.8 C25.1,3.8,25.1,3.8,25,3.8z"/>
                                            <path d="M26.9,23h0.6c3.6,0,7.2,0,10.8,0c1-0.1,1.9,0.7,2,1.8c0,0.1,0,0.1,0,0.2c0,1-0.8,1.8-1.8,1.8 c-4.5,0-9.1,0-13.6,0c-1,0-1.9-0.8-1.8-1.9l0,0c0-4.5,0-9,0-13.6c0-1,0.9-1.9,1.9-1.9l0,0c1,0,1.9,0.8,1.9,1.8c0,0.2,0,0.3,0,0.5 L26.9,23L26.9,23z"/>
                                        </svg>
                                        <div className="contact-option-text">
                                            <span>Monday–Friday</span><br />
                                            <span>8am–5pm</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="contact-column contact-form">
                                    <form onSubmit={this.handleSubmit} autoComplete="none" className="needs-validation" noValidate >
                                        <div className="form-field">
                                            <input required placeholder="Name" ref={(name) => this.name = name} type="text" autoComplete="none"/>
                                            <div className="invalid-feedback">
                                                this field is required
                                            </div>
                                        </div>
                                        <div className="form-field-container form-half-fields">
                                            <div className="form-field">
                                                <input required placeholder="Phone" type="text" ref={(phone) => this.phone = phone} autoComplete="none"/>
                                                <div className="invalid-feedback">
                                                    this field is required
                                                </div>
                                            </div>
                                            <div className="form-field">
                                                <input required placeholder="Email" type="email" ref={(email) => this.email = email} autoComplete="none"/>
                                                <div className="invalid-feedback">
                                                    this field is required
                                                </div>
                                            </div>
                                        </div>
                                        <div className="form-field-container form-half-fields">
                                            <div className="form-field select-field">
                                                <select id="billing_adress_country" required className="billing-adress" onChange={this.onChangeCoutryBilling} autoComplete="none" >
                                                    <option value=""  >Country*</option>
                                                    {listgetCountriesAndStates.map((item, index) => (
                                                        <option key={index} value={index} >{item.name}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">
                                                    this field is required
                                        </div>
                                            </div>
                                            <div className="form-field select-field">
                                                <select id="billing_adress_province" required className="billing-adress" onChange={this.onChangeProvinceBilling} autoComplete="none">
                                                    <option value=""  >Province/State*</option>
                                                    {listStatesBilling.map((item, index) => (
                                                        <option key={index} value={index} >{item.name}</option>
                                                    ))}
                                                </select>
                                                <div className="invalid-feedback">
                                                    this field is required
                                        </div>
                                            </div>

                                        </div>
                                        <div className="form-field">
                                            <textarea required placeholder="Send us a message..." type="text" ref={(message) => this.message = message}></textarea>
                                            <div className="invalid-feedback">
                                                this field is required
                                            </div>
                                        </div>
                                        <div id="info-message"></div>
                                        <div className="form-field form-submit form-submit-right">
                                            <input type="submit" className={this.state.valitationsBefore === true ? "" : "contact-us-send-gtm"}  value="Send Away" />
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section className="snazzy-map-banner">
                        <iframe className="group-65-iMnd66 about-us-map-gtm" src="https://snazzymaps.com/embed/342191" title="map"></iframe>
                    </section>
                </main>
                <Footer></Footer>
            </div>
        );
    }
}