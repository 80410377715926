import React, { Component } from 'react';
import './css/CartRequestQuote.css';
import Footer from './Footer';
import { Header } from './Header';
import './css/Account.css';
import helperCommon from './HelperCommon';
import { Table } from "reactstrap";
import AddressList from './AddressList';
import AddAdressItem from './AddAdressItem';
import myAccountRequests from './requests/MyAccountRequests';
import MyAccountUpdate from './MyAccountUpdate';
import MyAccountUpdatePassword from './MyAccountUpdatePassword';
import userAuthRepositories from './repositories/UserAuthRepositories';
import userContactInfoRepositories from './repositories/UserContactInfoRepositories';
import RegisterRequests from './requests/RegisterRequests';

export class MyAccount extends Component {
    static displayName = MyAccount.name;

    static listgetCountriesAndStates = [];
    static listStatesBilling = [];
    static listStatesShipping = [];
    static listClientAddresses = [];

    static idCountryShipping;
    static idCountryBilling;
    static idStateShipping;
    static idStateBilling;

    constructor(props) {
        super(props);

        document.title = "My Account";

        this.onChangeCoutryBilling = this.onChangeCoutryBilling.bind(this);
        this.onChangeCoutryShipping = this.onChangeCoutryShipping.bind(this);
        this.onChangeProvinceShipping = this.onChangeProvinceShipping.bind(this);
        this.onChangeProvinceBilling = this.onChangeProvinceBilling.bind(this);
        this.copyBillingAddress = this.copyBillingAddress.bind(this);
        this.onValidateEmail = this.onValidateEmail.bind(this);

        this.getPositionCoutry = this.getPositionCoutry.bind(this);
        this.getPositionStateShipping = this.getPositionStateShipping.bind(this);
        this.viewOrder = this.viewOrder.bind(this);
        this.showMoreOrders = this.showMoreOrders.bind(this);
        this.searchTable = this.searchTable.bind(this);
        this.closeMoreOrders = this.closeMoreOrders.bind(this);

        this.handleOpenAddAddress = this.handleOpenAddAddress.bind(this);
        this.handleSubmitAddAddress = this.handleSubmitAddAddress.bind(this);
        this.handleCloseAddAddress = this.handleCloseAddAddress.bind(this);
        this.handleOpenModalSelectAddresses = this.handleOpenModalSelectAddresses.bind(this);
        this.handleCloseModalSelectAddresses = this.handleCloseModalSelectAddresses.bind(this);


        this.handleOpenModalSelectAddressesBilling = this.handleOpenModalSelectAddressesBilling.bind(this);
        this.handleOpenModalSelectAddressesShipping = this.handleOpenModalSelectAddressesShipping.bind(this);

        this.handleCloseModalSelectAddressesShipping = this.handleCloseModalSelectAddressesShipping.bind(this);
        this.handleCloseModalSelectAddressesShipping = this.handleCloseModalSelectAddressesShipping.bind(this);


        this.handleOpenAddAddressBilling = this.handleOpenAddAddressBilling.bind(this);
        this.handleCloseAddAddressBilling = this.handleCloseAddAddressBilling.bind(this);


        this.handleOpenAddAddressShipping = this.handleOpenAddAddressShipping.bind(this);
        this.handleCloseAddAddressShipping = this.handleCloseAddAddressShipping.bind(this);

        this.onUseThisAddress = this.onUseThisAddress.bind(this);
        this.handleOpenModalMyAccountUpdate = this.handleOpenModalMyAccountUpdate.bind(this);
        this.closeModalMyAccountUpdate = this.closeModalMyAccountUpdate.bind(this);
         
        this.handleOpenModalMyAccountUpdatePassword = this.handleOpenModalMyAccountUpdatePassword.bind(this);
        this.closeModalMyAccountUpdatePassword = this.closeModalMyAccountUpdatePassword.bind(this);

        this.handleSubmitUpdatePassword = this.handleSubmitUpdatePassword.bind(this);

        
        this.state = {
            listgetCountriesAndStates: [], listStatesBilling: [], listStatesShipping: [], apiOrders: [],
            responseDataUser: [],
            responseDataOrders: [],
            listClientAddresses: [],
            userContactInfo: [],
        }
    }

    componentDidMount() {
        helperCommon.hideSectionErrorMessage();
        helperCommon.hideLoadingSection();

        myAccountRequests.getCountriesAndStates(this);
        myAccountRequests.getAddresses(this);
        myAccountRequests.getClient(this);
        myAccountRequests.getContact(this);
        myAccountRequests.getHystoryOrders(this);

        helperCommon.togglePasswordToText();
        helperCommon.toggleRePasswordToText();
    }

    onUseThisAddress(index, type) {
        if (type === 0) {
            let selectAddress = this.state.listClientAddressesBilling[index];
            myAccountRequests.setBillingAddress(userContactInfoRepositories.createByDefaultAdderssBody(selectAddress.id), this);
            this.setState({
                currentBillingAddress: selectAddress,
            });
            this.handleCloseModalSelectAddresses();
        } else if (type === 1) {
            let selectAddress = this.state.listClientAddressesBilling[index];
            myAccountRequests.setShippingAddress(userContactInfoRepositories.createByDefaultAdderssBody(selectAddress.id), this);
            this.setState({
                currentShippingAddress: selectAddress,
            });
            this.handleCloseModalSelectAddressesShipping();
        }
    }

    viewOrder(id, orderId) {
        console.log(orderId);
        window.open('/Report/Order?id=' + id + '&orderId=' + orderId, "_blank");
    }

    showMoreOrders() {
        let selectorsAll = document.querySelectorAll(".hide-order");
        var i;
        for (i = 0; i < selectorsAll.length; i++) {
            var element = selectorsAll[i];
            element.classList.remove("hide-order");
            element.classList.add("active-order");
        }
        let elementSeemore = document.getElementById("see-more");
        if (elementSeemore) {
            elementSeemore.style.display = "none";
        }

    }

    closeMoreOrders() {
        let selectorsAll = document.querySelectorAll(".active-order");
        var i;
        for (i = 0; i < selectorsAll.length; i++) {
            var element = selectorsAll[i];
            element.classList.remove("active-order");
            element.classList.add("hide-order");
        }
        let elementSeemore = document.getElementById("see-more");
        if (elementSeemore) {
            elementSeemore.style.display = "block";
        }
    }

   
    getPositionCoutry(countryId) {
        var position = -1;
        for (var i = 0; i < this.listgetCountriesAndStates.length; i++) {
            var item = this.listgetCountriesAndStates[i];
            if (item.id === countryId) {
                position = i;
                break;
            }
        }
        return position;
    }

    getPositionStateShipping(stateId, currentCountryIdPossion) {

        let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
        this.idCountryShipping = currentCountry.id;
        this.listStatesShipping = currentCountry.stateViews;
        this.setState({
            listStatesShipping: currentCountry.stateViews
        });

        var position = -1;
        for (var i = 0; i < this.listStatesShipping.length; i++) {
            var item = this.listStatesShipping[i];
            if (item.id === stateId) {
                position = i;
                break;
            }
        }
        return position;
    }

    getPositionStateBilling(stateId, currentCountryIdPossion) {

        let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
        this.idCountryBilling = currentCountry.id;
        this.listStatesBilling = currentCountry.stateViews;
        this.setState({
            listStatesBilling: currentCountry.stateViews
        });

        var position = -1;
        for (var i = 0; i < this.listStatesBilling.length; i++) {
            var item = this.listStatesBilling[i];
            if (item.id === stateId) {
                position = i;
                break;
            }
        }
        return position;
    }
    
    onChangeCoutryBilling(event) {
        var currentCountryIdPossion = document.getElementById("billing_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryBilling = currentCountry.id;
            this.listStates = currentCountry.stateViews;
            this.setState({
                listStates: currentCountry.stateViews
            });
        }
    }

    onChangeCoutryShipping(event) {
        var currentCountryIdPossion = document.getElementById("shipping_adress_country").value;
        if (currentCountryIdPossion) {
            let currentCountry = this.listgetCountriesAndStates[currentCountryIdPossion];
            this.idCountryShipping = currentCountry.id;
            this.listStatesShipping = currentCountry.stateViews;
            this.setState({
                listStatesShipping: currentCountry.stateViews
            });
        }
    }

    onChangeProvinceShipping() {
        var currentStateIdPossion = document.getElementById("shipping_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStatesShipping[currentStateIdPossion];
            this.idStateShipping = currentState.id;
        }
    }

    onChangeProvinceBilling() {
        var currentStateIdPossion = document.getElementById("billing_adress_province").value;
        if (currentStateIdPossion) {
            let currentState = this.listStates[currentStateIdPossion];
            this.idStateBilling = currentState.id;
        }
    }

    onClickOpenLogin(event) {
        window.location.replace("/login");
    }

    checkValidation(e) {
        var validation = true;
        var form = e.target;
        form.classList.add("was-validated");
        if (form.checkValidity() === false) {
            validation = false;
        }
        e.preventDefault();
        return validation;
    }

    handleSubmitAddAddress(e, numberTypeAddress) {
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            let json = userContactInfoRepositories.createAddressBody(allInputs, numberTypeAddress, this.idCountryBilling, this.idStateBilling);
            myAccountRequests.createAddress(json, this);
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }

    handleSubmitUpdatePassword(e) {
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            let json = userAuthRepositories.createPasswordBody(allInputs);
            myAccountRequests.changePassword(json, this);
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }

    handleSubmitMyAccountUpdate(e) {
        let allInputs = e.target;
        helperCommon.hideSectionErrorMessage();

        if (this.checkValidation(e)) {
            let jsonAccount = userContactInfoRepositories.createAccountBody(allInputs);
            myAccountRequests.updateAccount(jsonAccount, this);

            let jsonContact = userContactInfoRepositories.createContactBody(this.state.idPhone, this.state.phoneContactTypeId, allInputs);
            myAccountRequests.updateContact(jsonContact, this);

            
        } else {
            console.log("checkValidation error:");
            helperCommon.displaySectionMessage('', true);
        }
        e.preventDefault();
    }

    copyBillingAddress(e) {
        if (this.listStatesBilling) {
            var billingAdress = document.querySelectorAll(".billing-adress");
            var shippingAdress = document.querySelectorAll(".shipping-adress");

            this.setState({
                listStatesShipping: this.listStatesBilling
            }, () => {
                try {
                    for (var i = 0; i < billingAdress.length; i++) {
                        var currentInput = billingAdress[i];
                        shippingAdress[i].value = currentInput.value;
                    }
                } catch (e) {
                    console.log("copyBillingAddress " + e.message + 'currentInput.id ' + currentInput.id);
                }
            });

            this.idCountryShipping = this.idCountryBilling;
            this.idStateShipping = this.idStateBilling;
            this.listStatesShipping = this.listStatesBilling;
        }

    }

    onValidateEmail(e) {
        if (e.target.value && e.target.id) {
            RegisterRequests.checkValidateEmail(e.target.value, e.target.id);
        }
    }

    searchTable() {

        var input, filter, table, tr, td, i, td1, td2;
        input = document.getElementById("searchOrder");
        filter = input.value.toUpperCase();
        table = document.getElementById("myTable");
        tr = table.getElementsByTagName("tr");
        for (i = 0; i < tr.length; i++) {
            td = tr[i].getElementsByTagName("td")[0];
            td1 = tr[i].getElementsByTagName("td")[1];
            td2 = tr[i].getElementsByTagName("td")[2];

            if (td || td1 || td2) {
                if ((td.innerHTML.toUpperCase().indexOf(filter) > -1)
                    || (td1.innerHTML.toUpperCase().indexOf(filter) > -1)
                    || (td2.innerHTML.toUpperCase().indexOf(filter) > -1)) { 
                    tr[i].style.display = "";
                } else {
                    tr[i].style.display = "none";
                }
            }
        }
    }

    handleOpenModalMyAccountUpdatePassword(e) {
        this.setState({ showModalMyAccountUpdatePassword: true });
        e.stopPropagation();
    }

    closeModalMyAccountUpdatePassword(e) {
        this.setState({ showModalMyAccountUpdatePassword: false });
        e.stopPropagation();

    }

    handleOpenModalMyAccountUpdate(e) {
        this.setState({ showModalMyAccountUpdate: true });
        e.stopPropagation();
    }

    closeModalMyAccountUpdate(e) {
        this.setState({ showModalMyAccountUpdate: false });
        e.stopPropagation();
    }


    handleOpenModalSelectAddressesBilling() {
        this.setState({ showModalSelectAddresses: true });
    }

    handleOpenModalSelectAddressesShipping() {
        this.setState({ showModalSelectAddressesShipping: true });
    }


    handleCloseModalSelectAddressesShipping() {
        this.setState({ showModalSelectAddressesShipping: false });
    }



    handleOpenAddAddressBilling() {
        this.setState({ showModalhandleOpenAddAddressBilling: true });
    }

    handleCloseAddAddressBilling() {
        this.setState({ showModalhandleOpenAddAddressBilling: false });
    }


    handleOpenAddAddressShipping() {
        this.setState({ showModalhandleOpenAddAddressShipping: true });
    }

    handleCloseAddAddressShipping() {
        this.setState({ showModalhandleOpenAddAddressShipping: false });
    }


    handleOpenAddAddress() {
        this.setState({ showModalhandleOpenAddAddress: true });
    }

    handleCloseAddAddress() {
        this.setState({ showModalhandleOpenAddAddress: false });
    }

    handleOpenModalSelectAddresses() {
        this.setState({ showModalSelectAddresses: true });
    }

    handleCloseModalSelectAddresses() {
        this.setState({ showModalSelectAddresses: false });
    }

    handleChange(evt) {
        const value = evt.target.value;
        this.setState({
            [evt.target.name]: value
        });
    }

    render() {
        const listClientAddressesBilling = this.state.listClientAddressesBilling;
        const listClientAddressesShipping = this.state.listClientAddressesShipping;

        const customStyles = {
            content: {
                top: '50%',
                left: '50%',
                right: 'auto',
                bottom: 'auto',
                marginRight: '-50%',
                width: '50%',
                height: '50%',
                transform: 'translate(-50%, -50%)',
            },
        }

        return (
            <div id="container">
                <Header></Header>
                <link rel="stylesheet" type="text/css" charSet="UTF-8" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css" />
                <link rel="stylesheet" type="text/css" href="https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css" />

                <main className="bg-light">
                    <div className="wrapper xthin-wrapper">


                        <section className="account-overview medium-padding center-align">
                            <h1 className="no-margin-bottom">Account: <span className="text-red lowercase">{this.state.responseDataUser.businessName}</span></h1>
                            
                            <div className="form-alt-options">
                                <span>{this.state.userEmail}</span>
                                &nbsp; | &nbsp;
                                <span className="link" onClick={this.handleOpenModalMyAccountUpdatePassword} >
                                    Update Password
                                    <MyAccountUpdatePassword context={this} customStyles={customStyles} ></MyAccountUpdatePassword>
                                </span>
                                &nbsp; | &nbsp;
                                <span className="link" onClick={this.handleOpenModalMyAccountUpdate} >
                                    Update Account
                                    <MyAccountUpdate context={this} customStyles={customStyles} ></MyAccountUpdate>
                                </span>  
                            </div>
                        </section>



                        <section className='account-section account-addresses info-client small-padding-bottom'>
                            <h3 className="subheading">Addresses</h3>

                            <div className="address-blocks">
                                <div className="address-block">
                                    <h4> Billing Address</h4>
                                    <div className="account-address">
                                        <p>
                                            {this.state.currentBillingAddress && this.state.currentBillingAddress.street}<br />
                                            {this.state.currentBillingAddress && this.state.currentBillingAddress.city + ", " + this.state.currentBillingAddress.stateName}<br />
                                            {this.state.currentBillingAddress && this.state.currentBillingAddress.countryName + ", " + this.state.currentBillingAddress.postCode}
                                        </p>
                                    </div>

                                    <div className="address-button">
                                        <div onClick={this.handleOpenModalSelectAddressesBilling}>Change Address</div>
                                        <AddressList context={this} listClientAddresses={listClientAddressesBilling} customStyles={customStyles} nameTypeAddress='billing addresses' numberTypeAddress={0} ></AddressList>
                                        <AddAdressItem nameTypeAddress='billing address' listStates={this.state.listStates} context={this} customStyles={customStyles} listgetCountriesAndStates={this.state.listgetCountriesAndStates} numberTypeAddress={0} ></AddAdressItem>
                                    </div>
                                </div>

                                <div className='address-block'>
                                    <h4>Shipping Address</h4>
                                    <div className="account-address">
                                        <p>
                                            {this.state.currentShippingAddress && this.state.currentShippingAddress.street}<br />
                                            {this.state.currentShippingAddress && this.state.currentShippingAddress.city + ", " + this.state.currentShippingAddress.stateName}<br />
                                            {this.state.currentShippingAddress && this.state.currentShippingAddress.countryName + ", " + this.state.currentShippingAddress.postCode}
                                        </p>
                                    </div>


                                    <div className="address-button">
                                        <div onClick={this.handleOpenModalSelectAddressesShipping}>Change Address</div>
                                        <AddressList context={this} listClientAddresses={listClientAddressesShipping} customStyles={customStyles} nameTypeAddress='shipping addresses' numberTypeAddress={1}></AddressList>
                                        <AddAdressItem nameTypeAddress='shipping address' listStates={this.state.listStates} context={this} customStyles={customStyles} listgetCountriesAndStates={this.state.listgetCountriesAndStates} numberTypeAddress={1} ></AddAdressItem>
                                    </div>
                                </div>
                                  
                            </div>
                        </section>




                        {this.state.responseDataOrders.length > 0 &&
                            <section className="account-section history-invoices">
                                <div className="heading-with-button xs-padding-bottom">
                                    <h3 className="subheading no-margin">Orders</h3>
                                    <div className='search-order'>
                                        <input type="text" id="searchOrder" onChange={this.searchTable} placeholder="Search..." title="Type in a name" />
                                    </div>
                                </div>

                                <Table id="myTable" hover>
                                    <thead>
                                        <tr>
                                            <th className="order-table-order">Order</th>
                                            <th className="order-table-date">Date</th>
                                            <th className="order-table-total">Total</th>
                                            <th className="order-table-view">View</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {this.state.responseDataOrders.map((item, index) => (
                                            <tr key={index} className={index > 5 ? "hide-order" : ''}>
                                                <td value={index} className='order-table-order' >{'#' + item.id}</td>
                                                <td value={index} className='order-table-date' > { item.creationDate }</td>
                                                <td value={index} className='order-table-total' >{"$" + item.total}</td>
                                                <td value={index} className='order-table-view viewOrder' onClick={e => this.viewOrder(item['id'], item['orderId'])}>VIEW ORDER</td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>

                                <div className="buttons-wrapper center-buttons small-padding-bottom">
                                    <span id='see-more'>
                                        <span onClick={this.showMoreOrders} className='see-more'>See More</span>
                                    </span>
                                    <span className='hide-order'>
                                        <span onClick={this.closeMoreOrders} className='see-more'>Less</span>
                                    </span>
                                </div>

                            </section>
                        }

                    </div>

                </main>
                <Footer></Footer>
            </div>
        );
    }

}

